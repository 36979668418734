<template>
  <v-container fluid>
    <ValidationObserver ref="observer" v-slot="{ }">
      <!-- Email -->
      <v-row>
        <v-col>
          <mb-input-text
            v-model="credentials.login"
            name="login_mb_email"
            label="Email"
            rules="required|email"
            @input="updateRememberMeEmail"
          />
        </v-col>
      </v-row>
      <!-- Senha -->
      <v-row no-gutters>
        <v-col>
          <mb-input-password
            v-model="credentials.password"
            name="login_mb_password"
            label="Senha"
            rules="required|min:6"
          />
        </v-col>
      </v-row>
      <!-- Botões - Cadastrar e Acessar -->
      <v-row no-gutters justify="end">
        <v-col
          align="right" xl="4" lg="4"
          md="6" sm="5" cols="12"
          class="text-right"
        >
          <mb-text-button
            name="login_mb_access" label="Acessar" :loading="loginBtnLoading"
            @click="login"
          />
        </v-col>
      </v-row>
      <!-- Lembre-me / Esqueceu a senha -->
      <v-row align="center" class="px-2 pl-4">
        <v-col>
          <v-switch v-model="rememberMe" hide-details label="Lembre-me" />
        </v-col>
        <v-col align="right">
          <v-btn
            name="login_mb_recover" text small
            @click="recoverPassword"
          >
            Esqueceu a senha?
          </v-btn>
        </v-col>
      </v-row>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import bus from '@/plugins/eventBus';
import MbInputText from '@/components/general/inputs/MbInputText';
import MbInputPassword from '@/components/general/inputs/MbInputPassword';
import MbTextButton from '@/components/general/buttons/MbTextButton';
import { routesMedibook } from '../../router/routes';

export default {
  components: {
    ValidationObserver,
    MbInputText,
    MbInputPassword,
    MbTextButton,
  },
  data: () => ({
    credentials: {
      login: null,
      password: null,
    },
    loginBtnLoading: false,
    rememberMe: false,
  }),
  watch: {
    rememberMe() {
      this.updateRememberMeEmail();
    },
  },
  created() {
    const rememberMeEmail = this.getRememberMe();
    if (rememberMeEmail !== null) {
      this.credentials.login = rememberMeEmail;
      this.rememberMe = true;
    }
  },
  methods: {
    async login() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.loginBtnLoading = true;
        this.$store
          .dispatch('user/login', { credentials: this.credentials, type: 'medibook' })
          .then(() => {
            this.$router.addRoutes(routesMedibook, { replace: true });
            // this.$router.routes = routesMedibook;
            this.$router.push({ name: 'home' });
          })
          .catch((err) => {
            console.error(err);
            if (err.response && err.response.data && err.response.data.message.includes('ativado')) {
              bus.$emit('message', {
                message: 'Seu cadastro não foi ativado. Verifique email de ativação.',
                type: 'warn',
              });
            } else {
              bus.$emit('message', {
                message: err.response.data.message,
                type: 'error',
              });
            }
          })
          .finally(() => {
            this.loginBtnLoading = false;
          });
      }
    },
    recoverPassword() {
      this.$router.push({ path: '/recover-password', query: { from: this.$router.currentRoute.name } });
    },
    getRememberMe() {
      const rememberMeEmail = localStorage.getItem('mbermemb');
      if (rememberMeEmail !== null) {
        return atob(rememberMeEmail);
      }
      return rememberMeEmail;
    },
    updateRememberMeEmail() {
      if (this.rememberMe === true && this.credentials.login !== null) {
        localStorage.setItem('mbermemb', btoa(this.credentials.login));
      } else {
        localStorage.removeItem('mbermemb');
      }
    },
  },
};
</script>
