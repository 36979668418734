<template>
  <v-container>
    <v-card>
      <v-row class="px-3 pt-2">
        <v-col
          xl="4" lg="4" md="4" sm="4"
          cols="12">
          <mb-select
            v-model="estipulanteId" :items="estipulantes"
            itemText="slug" itemValue="id" name="cm_fltr_estipulante" label="Estipulantes"
            :loading="estipulanteLoading"
          />
        </v-col>
        <v-col
          xl="4" lg="4" md="4" sm="4"
          cols="12">
          <v-switch
            v-model="active"
            label="Ativos?"
            @changes="getContractors"
          ></v-switch>
        </v-col>
      </v-row>

      <v-row class="mt-2 px-2">
        <v-col cols="12">
          <v-tabs
            v-model="tab"
            fixed-tabs
            background-color="primary"
            dark
          >
            <v-tab>Estipulante</v-tab>
            <v-tab>Subestipulante</v-tab>
            <v-tab>Corretora</v-tab>
            <v-tab>APSS</v-tab>
            <v-tab>Proplast</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" grow>
            <v-tab-item>
              <commissions type='estipulante' :estipulanteId="estipulanteId" :active="active"/>
            </v-tab-item>
            <v-tab-item >
              <commissions type='subestipulante' :estipulanteId="estipulanteId" :active="active"/>
            </v-tab-item>
            <v-tab-item >
              <commissions type='broker' :estipulanteId="estipulanteId" :active="active"/>
            </v-tab-item>
            <v-tab-item >
              <commissions type='apss' :estipulanteId="estipulanteId" :active="active"/>
            </v-tab-item>
            <v-tab-item >
              <commissions type='proplast' :estipulanteId="estipulanteId" :active="active"/>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>

    </v-card>
  </v-container>
</template>

<script>
import bus from '@/plugins/eventBus';
import MbSelect from '@/components/general/inputs/MbSelect';
import Commissions from '@/components/commissions/Commissions';

export default {
  components: {
    MbSelect,
    Commissions,
  },
  data: () => ({
    tab: null,
    active: 1,
    estipulantes: [],
    estipulanteId: null,
    estipulanteLoading: null,
  }),
  created() {
    this.getContractors();
  },
  methods: {
    getContractors() {
      this.estipulanteLoading = true;

      this.$store.dispatch('estipulante/all', {
        pagination: { page: 1, size: -1 },
      }).then((resp) => {
        this.estipulantes = resp.data;
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.estipulanteLoading = false;
      });
    },
  },
};
</script>
