<template>
  <v-container>
    <v-row class="mb-5">
      <v-col cols="12" class="pa-0 ma-0">
        <upload-contract :contratanteId="contratanteId" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UploadContract from '@/components/contratante/UploadContract';

const Hashids = require('hashids');

export default {
  components: {
    UploadContract,
  },
  data: () => ({
    hashids: null,
    contratanteId: null,
  }),
  created() {
    // eslint-disable-next-line new-cap
    this.hashids = new Hashids.default(
      process.env.VUE_APP_HASHID_SALT, Number(process.env.VUE_APP_HASHID_PAD),
    );

    // eslint-disable-next-line prefer-destructuring
    this.contratanteId = this.hashids.decode(this.$route.query.id)[0];
  },
  methods: {

  },
};
</script>
