<template>
  <v-container fluid pa-0 ma-0>
    <v-row no-gutters>
      <v-col
        xs="12" sm="12" md="6" lg="6"
        xl="7" class="pt-6 px-6" align-self="center"
      >
        <v-container
          class="pa-0 ma-0"
        >
          <!-- Image -->
          <v-row dense no-gutters class="pb-12">
            <v-col
              xs="0" sm="1" md="1" lg="2"
              xl="3"
            ></v-col>
            <v-col
              xs="12" sm="10" md="10" lg="8"
              xl="6"
            >
              <v-img src="@/assets/mb-econocard.png" />
            </v-col>
            <v-col
              xs="0" sm="1" md="1" lg="2"
              xl="3"
            ></v-col>
          </v-row>
          <!-- Form -->
          <v-row dense no-gutters class="pa-0 ma-0">
            <v-col
              v-if="$vuetify.breakpoint.smAndUp"
              xs="0" sm="1" md="0" lg="1"
              xl="3" class="pa-0 ma-0"
            ></v-col>
            <v-col
              xs="12" sm="10" md="12" lg="10"
              xl="6" class="pa-0 ma-0"
            >
              <login-form-estipulante class="pa-0 ma-0"/>
            </v-col>
            <v-col
              v-if="$vuetify.breakpoint.smAndUp"
              xs="0" sm="1" md="0" lg="1"
              xl="3" class="pa-0 ma-0"
            ></v-col>
          </v-row>
        </v-container>
      </v-col>
      <!-- Image -->
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        xs="12" sm="12" md="6" lg="6"
        xl="5"
        class="pa-0 d-flex align-start">
        <v-img
          src="@/assets/login-img.jpg"
          :aspect-ratio="aspectRatio"
          max-height="900"
          contain
        >
        <!-- gradient="rgba(0,0,0,.0), rgba(0,0,0,.5)" -->
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginFormEstipulante from '@/components/login/LoginFormEstipulante';

export default {
  components: {
    LoginFormEstipulante,
  },
  data: () => ({
    showVideo: false,
  }),
  computed: {
    aspectRatio() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 1;
      }
      if (this.$vuetify.breakpoint.mdAndDown) {
        return 1.1;
      }
      if (this.$vuetify.breakpoint.lgAndDown) {
        return 1.2;
      }
      return 1;
    },
  },
  methods: {
  },
};
</script>
