<template>
  <v-container>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <!-- Email -->
      <v-row v-if="!sucessMessage" justify="center">
        <v-col
          xl="8" lg="8" md="8" sm="8"
          cols="12"
        >
          <mb-input-text
            v-model="phoneOrEmailOrDoc"
            name="phone"
            label="Telefone ou Email ou CNPJ"
            hint="email@email.com ou 41912345678"
            rules="required"
          />
        </v-col>

        <v-col
          xl="3" lg="3" md="3" sm="3"
          cols="12"
        >
          <mb-text-button
            name="recpass_access" label="Acessar"
            :disabled="invalid" :loading="recoverBtnLoading" @click="recover"
          />
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="12">
          <v-row justify="center">
            <v-col align="center">
              {{ sucessMessage }}
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col align="center" cols="12">
              <change-password-form :phone-or-email-or-doc="phoneOrEmailOrDoc" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import bus from '@/plugins/eventBus';
import MbInputText from '@/components/general/inputs/MbInputText';
import MbTextButton from '@/components/general/buttons/MbTextButton';
import ChangePasswordForm from '@/components/recoverPassword/ChangePasswordForm';

export default {
  components: {
    ValidationObserver,
    MbInputText,
    MbTextButton,
    ChangePasswordForm,
  },
  data: () => ({
    phoneOrEmailOrDoc: null,
    recoverBtnLoading: false,
    sucessMessage: null,
  }),
  methods: {
    async recover() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.recoverBtnLoading = true;
        this.$store
          .dispatch('user/recoverPassword', this.phoneOrEmailOrDoc)
          .then((res) => {
            // console.log(res);
            this.sucessMessage = res.message;
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(() => {
            this.recoverBtnLoading = false;
          });
      }
    },
  },
};
</script>
