import Vue from 'vue';
import moment from 'moment/src/moment';
import 'moment/locale/pt-br';

Vue.filter('capitalize', (value) => {
  if (!value) return '';
  const vl = value.toString();
  return vl.charAt(0).toUpperCase() + vl.slice(1);
});

Vue.filter('uppercase', (value) => {
  if (!value) return '';
  return value.toString().toUpperCase();
});

Vue.filter('lowercase', (value) => {
  if (!value) return '';
  return value.toString().toLowerCase();
});

Vue.filter('ISOToDate', (value) => {
  if (!value) return '';
  return moment.utc(value).format('DD/MM/YYYY');
});

Vue.filter('ISOToDateTime', (value) => {
  if (!value) return '';
  return moment.utc(value).format('DD/MM/YYYY HH:mm');
});

Vue.filter('dateTime', (value) => {
  if (!value) return '';
  return moment(value).format('DD/MM/YYYY HH:mm');
});

Vue.filter('date', (value) => {
  if (!value) return '';
  return moment.utc(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
});

Vue.filter('dateToLongDate', (value) => {
  if (!value) return '';
  return moment.utc(value, 'YYYY-MM-DD', 'pt-BR').format('dddd, DD [de] MMMM');
});

Vue.filter('timeToHM', (value) => {
  if (!value) return '';
  return moment(value, 'HH:mm:SS').format('HH:mm');
});

Vue.filter('realToCents', (value) => {
  if (!value) return '';
  const vl = `${value}`.replace('R', '').replace('r', '').replace('$', '');
  const parts = `${vl}`.split(',');
  const reais = parts[0];
  const cents = parts[1] || 0;
  return (Number(reais) * 100) + Number(cents);
});

Vue.filter('centsToReal', (value) => {
  if (value === null || value === undefined) return '';
  if (Number(value) === 0) {
    return (Number(value)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
  return (Number(value) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
});

Vue.filter('valueToDecimal', (value) => {
  if (!value) return '';
  return (Number(value) / 100).toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 });
});

Vue.filter('real', (value) => {
  if (!value) return '';
  return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
});
// ===========================
// Active
Vue.filter('active', (value) => {
  if (value) return 'Ativo';
  return 'Não ativo';
});
Vue.filter('activeIcon', (value) => {
  if (value) return 'mdi-check-circle-outline';
  return 'mdi-cancel';
});
// withAdhesion
Vue.filter('withAdhesion', (value) => {
  if (value) return 'Com adesão';
  return 'Sem adesão';
});
Vue.filter('withAdhesionIcon', (value) => {
  if (value) return 'mdi-check-circle-outline';
  return 'mdi-cancel';
});
// Special
Vue.filter('special', (value) => {
  if (value) return 'Especial';
  return 'Especial';
});
Vue.filter('specialIcon', (value) => {
  if (value) return 'mdi-check-circle-outline';
  return 'mdi-cancel';
});
// Verified
Vue.filter('verified', (value) => {
  if (value) return 'Verificado';
  return 'Não verificado';
});
Vue.filter('verifiedIcon', (value) => {
  if (value) return 'mdi-check-bold';
  return 'mdi-cancel';
});
// Contract
Vue.filter('contract', (value) => {
  if (value != null) return 'Enviado';
  return 'Não enviado';
});
Vue.filter('contractIcon', (value) => {
  if (value != null) return 'mdi-check-bold';
  return 'mdi-cancel';
});
// syndicate (vinculo)
Vue.filter('syndicate', (value) => {
  if (!value) return '';
  switch (value) {
    case 'with':
      return 'Vinculado';
    case 'without':
      return 'Ñ Vinculado';
    case 'both':
      return 'Ambos';
    default:
      return '';
  }
});
// person_types
Vue.filter('personType', (value) => {
  if (!value) return '';
  switch (value) {
    case 'pj':
      return 'PJ';
    case 'pf':
      return 'PF';
    case 'both':
      return 'Ambos';
    default:
      return '';
  }
});
//
// Invoices
Vue.filter('invoiceStatus', (value) => {
  if (!value) return '';
  switch (value) {
    case 'pending':
      return 'Pendente';
    case 'paid':
      return 'Pago';
    case 'canceled':
      return 'Cancelado';
    case 'partially_paid':
      return 'Pago Parcialmente';
    case 'refunded':
      return 'Devolvido';
    case 'expired':
      return 'Expirado';
    case 'authorized':
      return 'Autorizado';
    case 'in_protest':
      return 'Em Protesto';
    case 'chargeback':
      return 'Estornado';
    case 'released':
      return 'Liberado - Pago';
    case 'payment_failed':
      return 'Falha no pagamento - Pendente';
    default:
      return '';
  }
});
Vue.filter('invoiceStatusIcon', (value) => {
  if (!value) return '';
  switch (value) {
    case 'pending':
      return 'mdi-credit-card-clock-outline';
    case 'paid':
      return 'mdi-check';
    case 'canceled':
      return 'mdi-cancel';
    case 'partially_paid':
      return 'mdi-progress-check';
    case 'refunded':
      return 'mdi-cash-refund';
    case 'expired':
      return 'mdi-clock-alert-outline';
    case 'authorized':
      return 'mdi-thumb-up-outline';
    case 'in_protest':
      return 'mdi-alert';
    case 'chargeback':
      return 'mdi-undo-variant';
    case 'released':
      return 'mdi-currency-usd';
    case 'payment_failed':
      return 'mdi-alert-outline';
    default:
      return '';
  }
});

// Invoice duplicate
Vue.filter('invoiceDuplicate', (value) => {
  if (value) return '2ª Via';
  return '1ª Via';
});
Vue.filter('invoiceDuplicateIcon', (value) => {
  if (value) return 'mdi-check-circle-outline';
  return 'mdi-minus';
});

// BankSlipStatus
Vue.filter('bankSlipStatus', (value) => {
  if (!value) return '';
  switch (value) {
    case 'none':
      return 'Nenhum';
    case 'processing':
      return 'Processando';
    case 'registered':
      return 'Registrado';
    case 'canceled':
      return 'Cancelado';
    case 'error':
      return 'Erro';
    default:
      return '';
  }
});
Vue.filter('bankSlipStatusIcon', (value) => {
  if (!value) return '';
  switch (value) {
    case 'none':
      return 'mdi-minus';
    case 'processing':
      return 'mdi-cog';
    case 'registered':
      return 'mdi-text-box-check';
    case 'canceled':
      return 'mdi-cancel';
    case 'error':
      return 'mdi-close';
    default:
      return '';
  }
});

Vue.filter('paymentStatusIcon', (value) => {
  if (!value) return '';
  switch (value) {
    case 'ok':
      return 'mdi-check-bold';
    case 'pending':
      return 'mdi-credit-card-clock-outline';
    case 'late':
      return 'mdi-clock-alert-outline';
    default:
      return '';
  }
});
Vue.filter('paymentStatus', (value) => {
  if (!value) return '';
  switch (value) {
    case 'ok':
      return 'OK';
    case 'pending':
      return 'Pendente';
    case 'late':
      return 'Atrasado';
    default:
      return '';
  }
});

// Confirm Status
Vue.filter('confirmStatus', (value) => {
  if (!value) return '';
  switch (value) {
    case 'pending':
      return 'Pendente';
    case 'retry':
      return 'Reenviar';
    case 'fail':
      return 'Falhou';
    case 'rejected':
      return 'Rejeitado';
    case 'sent':
      return 'Enviado';
    case 'delivered':
      return 'Entregue';
    case 'not_delivered':
      return 'Não entregue';
    case 'read':
      return 'Lido';
    default:
      return '';
  }
});
Vue.filter('confirmStatusIcon', (value) => {
  if (!value) return '';
  switch (value) {
    case 'pending':
      return 'mdi-message-text-clock';
    case 'retry':
      return 'mdi-cog-refresh';
    case 'fail':
      return 'mdi-close-circle';
    case 'rejected':
      return 'mdi-message-alert-outline';
    case 'sent':
      return 'mdi-message-arrow-right-outline';
    case 'delivered':
      return 'mdi-cellphone-message';
    case 'not_delivered':
      return 'mdi-cellphone-message-off';
    case 'read':
      return 'mdi-eye';
    default:
      return '';
  }
});

Vue.filter('accountStatus', (value) => {
  if (!value) return '';
  switch (value) {
    case 'verifying':
      return 'Verificando';
    case 'verified':
      return 'Verificado';
    case 'error_on_verifying':
      return 'Erro na verificação';
    case 'not_registered':
      return 'Não Cadastrado';
    default:
      return '';
  }
});
Vue.filter('accountStatusIcon', (value) => {
  if (!value) return '';
  switch (value) {
    case 'verifying':
      return 'mdi-cog-outline';
    case 'verified':
      return 'mdi-check';
    case 'error_on_verifying':
      return 'mdi-close-circle';
    case 'not_registered':
      return 'mdi-alert-circle-outline';
    default:
      return '';
  }
});

// Withdraw Status
Vue.filter('withdrawStatus', (value) => {
  if (!value) return '';
  switch (value) {
    case 'pending':
      return 'Pendente';
    case 'processing':
      return 'Processando';
    case 'accepted':
      return 'Aceito';
    case 'rejected':
      return 'Rejeitado';
    default:
      return '';
  }
});
Vue.filter('withdrawStatusIcon', (value) => {
  if (!value) return '';
  switch (value) {
    case 'pending':
      return 'mdi-update';
    case 'processing':
      return 'mdi-cog-outline';
    case 'accepted':
      return 'mdi-check';
    case 'rejected':
      return 'mdi-thumb-down';
    default:
      return '';
  }
});

Vue.filter('phone', (value) => {
  if (value == null || value.length === 0) return '';

  const val = `${value}`;

  let vl = [val.slice(0, -4), '-', val.slice(-4)].join('');
  vl = [vl.slice(0, 2), ') ', vl.slice(2)].join('');
  vl = `(${vl}`;
  return vl;
});

Vue.filter('document', (value) => {
  if (!value) return '';
  let vl = value;
  // cnpj
  if (vl.length === 14) {
    vl = [vl.slice(0, 2), '.', vl.slice(2)].join('');
    vl = [vl.slice(0, 6), '.', vl.slice(6)].join('');
    vl = [vl.slice(0, 10), '/', vl.slice(10)].join('');
    vl = [vl.slice(0, 15), '-', vl.slice(15)].join('');
  } else {
    vl = [vl.slice(0, 3), '.', vl.slice(3)].join('');
    vl = [vl.slice(0, 7), '.', vl.slice(7)].join('');
    vl = [vl.slice(0, 11), '-', vl.slice(11)].join('');
  }

  return vl;
});
