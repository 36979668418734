<template>
  <v-container>
    <v-progress-linear indeterminate :active="loading" />
    <v-data-table
      :headers="headers"
      :items="subestipulantes"
      :options.sync="options"
      :server-items-length="total"
      item-key="id"
      sort-by="id"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-container fluid class="pa-0 ma-0 py-4 pb-6">
          <v-row class="pa-0 ma-0">
            <v-col
              xl="2" lg="2" md="2" sm="2"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-select
                v-model="estipulante" :items="estipulantes"
                item-text="slug" name="sbcntte_fltr_estipulante" label="Estipulantes"
                @input="changeFilter" :loading="selectEstipulanteLoading"
              />
            </v-col>
            <v-col
              xl="2" lg="2" md="2" sm="2"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <v-btn
                color="primary" dark class="mb-2"
                @click="handleNew"
                :disabled="estipulante == null"
              >
                Novo
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:[`item.document`]="{ item }">
        {{ item.document | document }}
      </template>

      <template v-slot:[`item.mobile`]="{ item }">
        {{ item.mobile | phone }}
      </template>

      <template v-slot:[`item.phone`]="{ item }">
        {{ item.phone | phone }}
      </template>

      <template v-slot:[`item.paymentAccountStatus`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>
                {{ item.paymentAccountStatus | accountStatusIcon }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $options.filters.accountStatus(item.paymentAccountStatus) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon @click="handleEdit(item)">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon @click="handleBank(item)">mdi-account-cash</v-icon>
            </v-btn>
          </template>
          <span>Dados Bancários</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import bus from '@/plugins/eventBus';
import MbSelect from '@/components/general/inputs/MbSelect';

const Hashids = require('hashids');

export default {
  components: {
    MbSelect,
  },
  data: () => ({
    Hashids: null,
    total: 0,
    edit: false,
    headers: [
      {
        text: 'Nome', align: 'start', sortable: false, value: 'name',
      },
      { text: 'Nome de Divulgação', value: 'slug' },
      { text: 'CNPJ', value: 'document' },
      { text: 'Contato', value: 'contact' },
      { text: 'Email', value: 'email' },
      { text: 'Celular', value: 'mobile' },
      { text: 'Telefone', value: 'phone' },
      { text: 'Status Conta Bancária', align: 'center', value: 'paymentAccountStatus' },
      { text: 'Ações', value: 'actions' },
    ],
    options: {
      itemsPerPage: 10,
    },
    subestipulantes: [],
    estipulantes: [],
    estipulante: null,
    confirmRemove: false,
    loading: false,
    selectEstipulanteLoading: false,
  }),
  computed: {
  },
  watch: {
    options: {
      handler() {
        if (this.options.page != null && this.options.itemsPerPage != null) {
          this.getAll();
        }
      },
      deep: true,
    },
    estipulante(vl) {
      if (vl) {
        this.getAll();
      }
    },
  },
  beforeMount() {
    this.getEstipulantes();
  },
  created() {
    // eslint-disable-next-line new-cap
    this.Hashids = new Hashids.default(
      process.env.VUE_APP_HASHID_SALT, Number(process.env.VUE_APP_HASHID_PAD),
    );
  },
  methods: {
    getAll() {
      if (this.estipulante !== null) {
        this.loading = true;
        this.$store.dispatch('subestipulante/all', {
          id: this.estipulante.id,
          pagination: { page: this.options.page, size: this.options.itemsPerPage },
        }).then((resp) => {
          this.total = resp.meta.total;
          this.subestipulantes = resp.data;
        }).catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    created() {
      setTimeout(() => {
        this.estipulante = null;
      }, 1000);
      this.getAll();
    },
    updated() {
      setTimeout(() => {
        this.estipulante = null;
      }, 1000);
      this.getAll();
    },
    handleNew() {
      this.$router.push({ name: 'subestipulante-cad', query: { cId: this.Hashids.encode(this.estipulante.id) } });
    },
    handleEdit(item) {
      this.$router.push({
        name: 'subestipulante-cad',
        params: {
          id: this.Hashids.encode(item.id),
          estipulante: item,
        },
        query: { cId: this.Hashids.encode(this.estipulante.id) },
      });
    },
    changeFilter() {
      this.getAll();
    },
    getEstipulantes() {
      this.selectEstipulanteLoading = true;
      this.$store.dispatch('estipulante/all', {
        pagination: { page: 1, size: -1 },
      }).then((resp) => {
        this.estipulantes = resp.data;
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.selectEstipulanteLoading = false;
      });
    },
    handleBank(item) {
      this.$router.push({
        name: 'subestipulante-banck-account',
        params: { id: this.Hashids.encode(item.id), company: item },
      });
    },
    cancel() {
      this.estipulante = null;
    },
  },
};
</script>
