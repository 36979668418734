import BeneficiaryService from '@/services/beneficiary.service';

const beneficiarys = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    allByContratante(_, parameters) {
      return BeneficiaryService.allByContratante(parameters);
    },
    allByEstipulante(_, parameters) {
      return BeneficiaryService.allByEstipulante(parameters);
    },
    allTitularWithContratante(_, parameters) {
      return BeneficiaryService.allTitularWithContratante(parameters);
    },
    one(_, id) {
      return BeneficiaryService.one(id);
    },
    create(_, p) {
      return BeneficiaryService.create(p);
    },
    import(_, p) {
      return BeneficiaryService.import(p);
    },
    update(_, beneficiary) {
      return BeneficiaryService.update(beneficiary);
    },
    toggleActive(_, id) {
      return BeneficiaryService.toggleActive(id);
    },
    toggleDimissed(_, id) {
      return BeneficiaryService.toggleDimissed(id);
    },
  },

  getters: {},
};

export default beneficiarys;
