<template>
  <v-container fluid class="pa-0 ma-0">
    <v-btn
      :id="name" rounded
      :text="text"
      :color="color"
      :dark="dark && ! disabled"
      :disabled="disabled"
      :outlined="outlined"
      :loading="loading"
      :block="block || $vuetify.breakpoint.xsOnly"
      @click="handleClick"
    >
      <!-- :dark="primary" -->
      {{ label }}
      <v-icon v-if="icon" right :dark="dark">
        {{ icon }}
      </v-icon>
    </v-btn>
  </v-container>
</template>

<script>

export default {
  components: {
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
    },
    icon: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'primary',
    },
    dark: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
  }),
  watch: {
  },
  created() {
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>
