import Vue from 'vue';
import Vuex from 'vuex';

import user from './user';
import estipulante from './estipulante';
import estipulanteConfiguration from './estipulanteConfiguration';
import subestipulante from './subestipulante';
import contratante from './contratante';
import broker from './broker';
import brokers from './brokers';
import beneficiary from './beneficiary';
import dependent from './dependent';
import covenant from './covenant';
import kinship from './kinship';
import bankAccount from './bankAccount';
import companyEconocard from './companyEconocard';
import contratanteConfiguration from './contratanteConfiguration';
import commission from './commission';
import invoice from './invoice';
import withdraw from './withdraw';
import configurations from './configurations';
import plan from './plan';
import paymentMethod from './paymentMethod';
import subscription from './subscription';
import lead from './lead';
import report from './report';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    estipulante,
    estipulanteConfiguration,
    subestipulante,
    contratante,
    broker,
    brokers,
    beneficiary,
    dependent,
    covenant,
    kinship,
    bankAccount,
    companyEconocard,
    contratanteConfiguration,
    commission,
    invoice,
    withdraw,
    configurations,
    plan,
    paymentMethod,
    subscription,
    lead,
    report,
  },
});
