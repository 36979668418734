import SubestipulanteService from '@/services/subestipulante.service';

const subestipulantes = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    all(_, parameters) {
      return SubestipulanteService.all(parameters);
    },
    allSite(_, id) {
      return SubestipulanteService.allSite(id);
    },
    one(_, id) {
      return SubestipulanteService.one(id);
    },
    create(_, p) {
      return SubestipulanteService.create(p);
    },
    update(_, subestipulante) {
      return SubestipulanteService.update(subestipulante);
    },
  },

  getters: {},
};

export default subestipulantes;
