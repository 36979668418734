<template>
  <v-container>
    <v-row class="mb-5">
      <v-col cols="12" class="pa-0 ma-0">
        <subestipulante-form v-model="broker"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SubestipulanteForm from '@/components/subestipulante/SubestipulanteForm';

export default {
  components: {
    SubestipulanteForm,
  },
  data: () => ({
    hashids: null,
    broker: null,
  }),
  created() {
    this.get();
  },
  methods: {
    async get() {
      const user = await this.$store.getters['user/getUser'];
      // console.log(`user: ${JSON.stringify(user)}`);

      this.$store
        .dispatch('subestipulante/one', user.company.id)
        .then((res) => {
          this.broker = res;
        });
    },
  },
};
</script>
