<template>
  <v-card class="elevation-1">
    <v-card-title>
      <span v-if="subestipulante && subestipulante.id" class="headline mb-0">
        {{ subestipulante.name }}
      </span>
      <span v-else class="headline mb-0">Novo Subestipulante</span>
    </v-card-title>

    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-card-text>
        <form autocomplete="off">
          <!-- Document -->
          <v-row dense>
            <v-col
              xl="5" lg="5" md="5" sm="5"
              cols="12"
            >
              <mb-input-cnpj
                v-model="subestipulante.document"
                name="scrte_cnpj" label="CNPJ"
                rules="required"
                :disabled="subestipulante.id != null"
              />
            </v-col>
          </v-row>
          <!-- Name/Slug -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4"
            >
              <mb-input-text
                v-model="subestipulante.name"
                name="scrte_name" label="Nome"
                rules="required|max:150"
                :disabled="subestipulante.id != null"
              />
            </v-col>
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4"
            >
              <mb-input-text
                v-model="subestipulante.slug"
                name="scrte_slug" label="Nome Divulgação"
                rules="required|max:50"
              />
            </v-col>
          </v-row>
          <!-- Contact/Email -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4">
              <mb-input-text
                v-model="subestipulante.contact"
                name="scrte_contact" label="Contato"
                rules="required|max:50"
              />
            </v-col>
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0">
              <mb-input-text
                v-model.trim="subestipulante.email"
                name="scrte_email" label="Email" rules="required"
              />
            </v-col>
          </v-row>
          <!-- Phones -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4">
              <mb-input-phone
                v-model="subestipulante.mobile"
                name="scrte_mobile" label="Celular" rules="required|phone"
              />
            </v-col>
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4">
              <mb-input-phone
                v-model="subestipulante.phone"
                name="scrte_phone" label="Telefone" rules="required|phone"
              />
            </v-col>
          </v-row>
          <!-- ============== Address ============== -->
          <v-divider />

          <v-row class="pa-0 ma-0">
            <v-col cols="12" class="pa-0 ma-0">
              <address-form v-model="subestipulante"/>
            </v-col>
          </v-row>

        </form>
      </v-card-text>

      <v-card-actions>
        <mb-text-button
          name="prd_btn_save" label="Salvar"
          :disabled="subestipulante && invalid"
          :loading="saveBtnLoading"
          class="ml-2 mb-2"
          @click="save"
        />
        <v-spacer />
        <mb-text-button
          name="prd_btn_cancel" label="Cancelar" color="accent"
          class="mb-2" @click="cancel"
        />
      </v-card-actions>
    </ValidationObserver>
  </v-card>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import bus from '@/plugins/eventBus';
import MbInputText from '@/components/general/inputs/MbInputText';
import MbInputPhone from '@/components/general/inputs/MbInputPhone';
import MbInputCnpj from '@/components/general/inputs/MbInputCnpj';
import MbTextButton from '@/components/general/buttons/MbTextButton';
import AddressForm from '@/components/general/AddressForm';

export default {
  components: {
    ValidationObserver,
    MbInputText,
    MbInputPhone,
    MbInputCnpj,
    MbTextButton,
    AddressForm,
  },
  props: {
    estipulanteId: {
      type: Number,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      saveBtnLoading: false,
      subestipulante: null,
      defaultSubestipulante: {
        document: null,
        name: null,
        slug: null,
        contact: null,
        email: null,
        phone: null,
        mobile: null,

        zip: null,
        street: null,
        number: null,
        complement: null,
        district: null,
        city: null,
        state: null,
        latitude: null,
        longitude: null,
      },
    };
  },
  watch: {
    value(vl) {
      this.subestipulante = vl || JSON.parse(JSON.stringify(this.defaultSubestipulante));
      this.$refs.observer.reset();
    },
  },
  created() {
    console.log('created');
    this.subestipulante = this.value || JSON.parse(JSON.stringify(this.defaultSubestipulante));
  },
  destroyed() {
    console.log('destroyed');
    this.subestipulante = JSON.parse(JSON.stringify(this.defaultSubestipulante));
  },
  methods: {
    save() {
      if (this.subestipulante.id) {
        this.update();
      } else {
        this.create();
      }
    },
    async create() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.saveBtnLoading = true;
        this.$store
          .dispatch('subestipulante/create', {
            estipulanteId: this.estipulanteId,
            subestipulante: this.subestipulante,
          })
          .then(() => {
            this.subestipulante = this.defaultSubestipulante;
            bus.$emit('message', {
              message: 'Subestipulante criado com sucesso!',
              type: 'success',
            });
            this.$emit('input', this.subestipulante);
            this.$refs.observer.reset();
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
    async update() {
      const isValid = await this.$refs.observer.validate();

      // console.log(`isValid: ${isValid}`);
      if (isValid) {
        this.saveBtnLoading = true;
        this.$store
          .dispatch('subestipulante/update', this.subestipulante)
          .then((subestipulanteSaved) => {
            bus.$emit('message', {
              message: 'Subestipulante atualizado com sucesso!',
              type: 'success',
            });
            this.$emit('updated');
            this.$emit('input', subestipulanteSaved);
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
    cancel() {
      this.$emit('cancel');
      this.subestipulante = JSON.parse(JSON.stringify(this.defaultSubestipulante));
      this.$refs.observer.reset();
      this.$router.push({ name: 'subestipulante-list' });
    },
  },
};
</script>
