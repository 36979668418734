<template>
  <v-container>
    <v-progress-linear indeterminate :active="loading" />
    <v-row class="mb-5">
      <v-col cols="12" class="pa-0 ma-0">
        <bank-account-form
          v-model="banckAccount" :company="company"
          @updated="handleUpdated" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BankAccountForm from '@/components/companyEco/BankAccountForm';

const Hashids = require('hashids');

export default {
  components: {
    BankAccountForm,
  },
  data: () => ({
    hashids: null,
    companyId: null,
    company: null,
    banckAccount: null,
    loading: false,
  }),
  created() {
    // eslint-disable-next-line new-cap
    this.hashids = new Hashids.default(
      process.env.VUE_APP_HASHID_SALT, Number(process.env.VUE_APP_HASHID_PAD),
    );

    // eslint-disable-next-line prefer-destructuring
    this.companyId = this.hashids.decode(this.$route.params.id)[0];

    const { company } = this.$route.params;
    if (company && Object.entries(company).length > 0) {
      this.company = company;
    } else if (this.$route.params.id) {
      this.getCompany(this.companyId);
    }

    this.get(this.companyId);
  },
  methods: {
    get(id) {
      this.loading = true;
      this.$store
        .dispatch('bankAccount/one', id)
        .then((res) => {
          this.banckAccount = res || null;
        }).finally(() => {
          this.loading = false;
        });
    },
    getCompany(id) {
      this.$store
        .dispatch('companyEconocard/one', id)
        .then((res) => {
          this.company = res;
        });
    },
    handleUpdated() {
      this.getCompany(this.companyId);
    },
  },
};
</script>
