<template>
  <v-container>
    <v-progress-linear indeterminate :active="loading" />
    <v-data-table
      :headers="headers"
      :items="contratantes"
      :options.sync="options"
      :server-items-length="total"
      item-key="id"
      sort-by="id"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-container fluid class="pa-0 ma-0 py-4 pb-6">
          <!-- Filters -->
          <v-row class="pa-0 ma-0">
            <v-col
              xl="2" lg="2" md="2" sm="2"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-select
                v-model="filter.verified" :items="verifieds"
                item-text="txt" item-value="vl" name="ctrnt_fltr_verified" label="Verificados"
                @input="changeFilter"
              />
            </v-col>
            <v-col
              xl="2" lg="2" md="2" sm="2"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-select
                v-model="filter.contract" :items="contracts"
                item-text="txt" item-value="vl" name="ctrnt_fltr_contract" label="Com Contrato"
                @input="changeFilter"
              />
            </v-col>
            <v-col
              xl="2" lg="2" md="2" sm="2"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-select
                v-model="filter.paymentStatus" :items="paymentsStatus"
                item-text="txt" item-value="vl" name="ctrnt_fltr_payment_status" label="Status Fin."
                @input="changeFilter"
              />
            </v-col>

          </v-row>
        </v-container>
      </template>

      <template v-slot:[`item.document`]="{ item }">
        {{ item.document | document }}
      </template>

      <template v-slot:[`item.mobile`]="{ item }">
        {{ item.mobile | phone }}
      </template>

      <template v-slot:[`item.phone`]="{ item }">
        {{ item.phone | phone }}
      </template>

      <template v-slot:[`item.verified`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>{{ $options.filters.verifiedIcon(item.verified) }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $options.filters.verified(item.verified) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.contractFile`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">{{ $options.filters.contractIcon(item.contractFile) }}</v-icon>
          </template>
          <span>{{ $options.filters.contract(item.contractFile) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.paymentStatus`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>{{ $options.filters.paymentStatusIcon(item.paymentStatus) }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $options.filters.paymentStatus(item.paymentStatus) }}</span>
        </v-tooltip>
      </template>

    </v-data-table>
  </v-container>
</template>

<script>
import bus from '@/plugins/eventBus';
import MbSelect from '@/components/general/inputs/MbSelect';

const Hashids = require('hashids');

export default {
  components: {
    MbSelect,
  },
  data: () => ({
    Hashids: null,
    total: 0,
    edit: false,
    headers: [
      {
        text: 'Nome', align: 'start', sortable: false, value: 'name',
      },
      // { text: 'Nome de Divulgação', value: 'slug' },
      { text: 'Documento', value: 'document' },
      { text: 'Contato', value: 'contact' },
      { text: 'Email', value: 'email' },
      { text: 'Celular', value: 'mobile' },
      { text: 'Telefone', value: 'phone' },
      // { text: 'Status', value: 'paymentAccountStatus' },
      { text: 'Verificado', value: 'verified' },
      { text: 'Contrato', value: 'contractFile' },
      { text: 'Status Finan.', value: 'paymentStatus' },
    ],
    options: {
      itemsPerPage: 10,
    },
    filter: {
      verified: 'all',
      contract: 'all',
      paymentStatus: 'all',
    },
    broker: null,
    brokers: [],
    verifieds: [
      { txt: 'Todos', vl: 'all' },
      { txt: 'Verificados', vl: '1' },
      { txt: 'Não verificados', vl: '0' },
    ],
    contracts: [
      { txt: 'Todos', vl: 'all' },
      { txt: 'Com contratos', vl: '1' },
      { txt: 'Sem contratos', vl: '0' },
    ],
    paymentsStatus: [
      { txt: 'Todos', vl: 'all' },
      { txt: 'Pagamentos Ok', vl: 'ok' },
      { txt: 'Com pendência', vl: 'pending' },
    ],
    contratantes: [],
    loading: false,
  }),
  watch: {
    options: {
      handler() {
        if (this.options.page != null && this.options.itemsPerPage != null) {
          this.getAll();
        }
      },
      deep: true,
    },
  },
  created() {
    this.user = this.$store.getters['user/getUser'];

    // eslint-disable-next-line new-cap
    this.Hashids = new Hashids.default(
      process.env.VUE_APP_HASHID_SALT, Number(process.env.VUE_APP_HASHID_PAD),
    );
  },
  methods: {
    getAll() {
      this.contratantes = [];

      this.$store.dispatch('contratante/allBySubestipulante', {
        subestipulanteId: this.user.company.id,
        pagination: { page: this.options.page, size: this.options.itemsPerPage },
        filter: this.filter,
      }).then((resp) => {
        this.total = resp.meta.total;
        this.contratantes = resp.data;
      }).catch((err) => {
        console.log(err);
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    changeFilter() {
      this.getAll();
    },
  },
};
</script>
