import KinshipService from '@/services/kinship.service';

const kinships = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    all() {
      return KinshipService.all();
    },
  },

  getters: {},
};

export default kinships;
