import CovenantService from '@/services/covenant.service';

const covenants = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    all() {
      return CovenantService.all();
    },
  },

  getters: {},
};

export default covenants;
