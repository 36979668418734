<template>
  <v-card class="elevation-1">
    <v-card-title>
      <span v-if="beneficiary && beneficiary.id" class="headline mb-0">
        {{ beneficiary.name }}
      </span>
      <span v-else class="headline mb-0">Novo Beneficiário</span>
    </v-card-title>

    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-card-text>
        <form autocomplete="off">
          <!-- Document -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="2" lg="2" md="2" sm="2"
              cols="12" >
              <mb-input-cpf
                v-model="beneficiary.countryRegister"
                name="bnfc_countryRegister" label="Documento"
                rules="required"
                :disabled="beneficiary.id != null"
                :loading="userLoading"
                @change="getExistent"
              />
            </v-col>
          </v-row>
          <!-- Name/Genre -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="5" lg="5" md="5" sm="5"
              cols="12" class="pa-0 ma-0 mr-4"
            >
              <mb-input-text
                v-model="beneficiary.name"
                name="bnfc_name" label="Nome" rules="required"
              />
            </v-col>
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 mr-4"
            >
              <mb-select
                v-model="beneficiary.genre"
                :items="sexItems"
                itemText="text"
                itemValue="vl"
                name="bnfc_sex"
                label="Sexo"
                rules="required"
              />
            </v-col>
          </v-row>
          <!-- Email/Birthdate -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4">
              <mb-input-text
                v-model.trim="beneficiary.email"
                name="bnfc_email" label="Email" rules="required"
              />
            </v-col>
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 mr-4"
            >
              <mb-date
                  v-model="beneficiary.birthdate"
                  name="birthdate"
                  label="Data de Nascimento"
                  rules="required"
                  :allowedDates="(vl) => { return $moment(vl).isBefore($moment()) }"
                />
            </v-col>
          </v-row>
          <!-- Mobile/Phone -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="4" lg="4" md="4" sm="4"
              cols="12" class="pa-0 mr-4">
              <mb-input-phone
                v-model="beneficiary.mobile"
                name="bnfc_mobile" label="Celular" rules="required|phone"
              />
            </v-col>
            <v-col
              xl="4" lg="4" md="4" sm="4"
              cols="12" class="pa-0 ma-0">
              <mb-input-phone
                v-model="beneficiary.work"
                name="bnfc_work" label="Telefone"
              />
            </v-col>
          </v-row>
        </form>
      </v-card-text>

      <v-card-actions>
        <mb-text-button
          name="prd_btn_save" label="Salvar"
          :disabled="invalid || existsAsCompany"
          :loading="saveBtnLoading"
          class="ml-2 mb-2"
          @click="save"
        />
        <v-spacer />
        <mb-text-button
          name="prd_btn_cancel" label="Cancelar" :primary="false"
          class="mb-2" @click="cancel"
        />
      </v-card-actions>
    </ValidationObserver>

     <v-dialog v-model="dialogExistent" persistent max-width="600">
      <v-card v-if="userExistent">
        <v-card-title class="headline">
          Beneficiário já cadastrado
        </v-card-title>

        <v-card-text>
          <v-row v-if="!existsAsCompany" no-gutters>
            <v-row no-gutters>
              <v-col >
                O CPF já encontra-se cadastrado em nossa base de dados.
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col >
                Revise os dados antes de salvar.
              </v-col>
            </v-row>
          </v-row>

          <v-row v-else no-gutters class="mt-5">
            <v-col>
              <v-alert dense text type="warning" >
                Esse CPF está associado á uma empresa. Entre em contato com o suporte!
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text @click="dialogExistent = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import bus from '@/plugins/eventBus';
import MbInputText from '@/components/general/inputs/MbInputText';
import MbInputPhone from '@/components/general/inputs/MbInputPhone';
import MbInputCpf from '@/components/general/inputs/MbInputCpf';
import MbSelect from '@/components/general/inputs/MbSelect';
import MbDate from '@/components/general/inputs/MbDate';
import MbTextButton from '@/components/general/buttons/MbTextButton';

export default {
  components: {
    ValidationObserver,
    MbInputText,
    MbInputPhone,
    MbInputCpf,
    MbSelect,
    MbDate,
    MbTextButton,
  },
  props: {
    contratanteId: {
      type: Number,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
    pre: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      saveBtnLoading: false,
      beneficiary: null,
      defaultBeneficiary: {
        countryRegister: null,
        name: null,
        genre: null,
        email: null,
        birthdate: null,
        mobile: null,
        work: null,
      },
      sexItems: [
        { vl: 'female', text: 'Feminino' },
        { vl: 'male', text: 'Masculino' },
      ],
      userLoading: false,
      userExistent: null,
      dialogExistent: false,
      existsAsCompany: false,
    };
  },
  computed: {
  },
  watch: {
    value(vl) {
      this.beneficiary = vl || JSON.parse(JSON.stringify(this.defaultBeneficiary));
      this.$refs.observer.reset();
    },
  },
  created() {
    this.beneficiary = this.value || JSON.parse(JSON.stringify(this.defaultBeneficiary));
  },
  destroyed() {
    this.beneficiary = JSON.parse(JSON.stringify(this.defaultBeneficiary));
  },
  methods: {
    save() {
      if (this.beneficiary.id != null && this.userExistent == null) {
        this.update();
      } else {
        this.create();
      }
    },
    async create() {
      const isValid = await this.$refs.observer.validate();

      if (isValid && !this.existsAsCompany) {
        this.saveBtnLoading = true;
        this.$store
          .dispatch('beneficiary/create', {
            contratanteId: this.contratanteId,
            beneficiary: this.beneficiary,
          })
          .then(() => {
            this.beneficiary = this.defaultBeneficiary;
            this.$refs.observer.reset();
            bus.$emit('message', {
              message: 'Beneficiário criado com sucesso!',
              type: 'success',
            });
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
    async update() {
      const isValid = await this.$refs.observer.validate();

      if (isValid && !this.pre) {
        this.saveBtnLoading = true;
        this.$store
          .dispatch('beneficiary/update', this.beneficiary)
          .then(() => {
            // console.log(`update beneficiarySaved: ${JSON.stringify(beneficiarySaved)}`);
            bus.$emit('message', {
              message: 'Beneficiário atualizado com sucesso!',
              type: 'success',
            });
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
    getExistent() {
      this.userLoading = true;
      if (this.beneficiary.countryRegister) {
        this.$store
          .dispatch('user/oneByCountryRegister', this.beneficiary.countryRegister)
          .then((u) => {
            this.userExistent = null;
            this.dialogExistent = false;
            this.existsAsCompany = false;

            this.userExistent = u;

            if (this.userExistent) {
              this.dialogExistent = true;
              this.existsAsCompany = (this.userExistent.type !== 'patient');
              this.beneficiary = { ...this.userExistent };
              if (this.beneficiary.phones) {
                try {
                  const phonesObj = JSON.parse(this.beneficiary.phones);
                  this.beneficiary.mobile = phonesObj.mobile;
                  this.beneficiary.work = phonesObj.work;
                } catch (error) {
                  console.error(error);
                }
              }
            }
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.userLoading = false;
          });
      }
    },
    cancel() {
      this.$emit('cancel');
      this.beneficiary = JSON.parse(JSON.stringify(this.defaultBeneficiary));
      this.$refs.observer.reset();
      this.$router.push({ name: 'beneficiary-list' });
    },
  },
};
</script>
