<template>
  <v-container>
    <v-row class="mb-5">
      <v-col cols="12" class="pa-0 ma-0">
        <subestipulante-form
          v-model="subestipulante" :estipulanteId="estipulanteId"
          @cancel="edit = false" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SubestipulanteForm from '@/components/subestipulante/SubestipulanteForm';

const Hashids = require('hashids');

export default {
  components: {
    SubestipulanteForm,
  },
  data: () => ({
    hashids: null,
    estipulanteId: null,
    subestipulante: null,
  }),
  created() {
    // eslint-disable-next-line new-cap
    this.hashids = new Hashids.default(
      process.env.VUE_APP_HASHID_SALT, Number(process.env.VUE_APP_HASHID_PAD),
    );

    // eslint-disable-next-line prefer-destructuring
    this.estipulanteId = this.hashids.decode(this.$route.query.cId)[0];

    const { subestipulante } = this.$route.params;
    if (subestipulante && Object.entries(subestipulante).length > 0) {
      this.subestipulante = subestipulante;
    } else if (this.$route.params.id) {
      this.get(this.hashids.decode(this.$route.params.id)[0]);
    }
  },
  methods: {
    get(id) {
      this.$store
        .dispatch('subestipulante/one', id)
        .then((subestipulante) => {
          this.subestipulante = subestipulante;
        });
    },
  },
};
</script>
