import PlanService from '@/services/plan.service';

const plans = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    all(_, parameters) {
      return PlanService.all(parameters);
    },
    actives(_, params) {
      return PlanService.actives(params);
    },
    activesByContratantePf(_, subestipulanteId) {
      return PlanService.activesByContratantePf(subestipulanteId);
    },
    activesByContratantePfLink(_, subestipulanteId) {
      return PlanService.activesByContratantePfLink(subestipulanteId);
    },
    one(_, id) {
      return PlanService.one(id);
    },
    create(_, plan) {
      return PlanService.create(plan);
    },
    update(_, plan) {
      return PlanService.update(plan);
    },
    // remove(_, plan) {
    //   return PlanService.remove(plan);
    // },
  },

  getters: {},
};

export default plans;
