import ptBR from '@/i18n/pt-BR';
import Vue from 'vue';
// import Vuetify from 'vuetify/lib';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { ptBR },
    current: 'ptBR',
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      light: {
        primary: '#607D8B',
        secondary: '#afc8d3',
        accent: '#A1887F',
        error: '#B71C1C',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#F57F17',
      },
    },
  },
});
