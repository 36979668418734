import WithdrawService from '@/services/withdraw.service';

const withdraw = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    allByContratante(_, parameters) {
      return WithdrawService.allByContratante(parameters);
    },
    create(_, id) {
      return WithdrawService.create(id);
    },
  },

  getters: {},
};

export default withdraw;
