import ReportService from '@/services/report.service';

const reports = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    // commissions(_, p) {
    //   return ReportService.commissions(p);
    // },
    totals(_, p) {
      return ReportService.totals(p);
    },
    brokers(_, p) {
      return ReportService.brokers(p);
    },
    subestipulantes(_, p) {
      return ReportService.subestipulantes(p);
    },
    exportExcel(_, p) {
      return ReportService.exportExcel(p);
    },

    cards(_, p) {
      return ReportService.cards(p);
    },
    exportCardsExcel(_, p) {
      return ReportService.exportCardsExcel(p);
    },

    adhesions(_, p) {
      return ReportService.adhesions(p);
    },
    exportAdhesionsExcel(_, p) {
      return ReportService.exportAdhesionsExcel(p);
    },
  },

  getters: {},
};

export default reports;
