import HTTP from './axios';

const ContratanteConfigurationService = {
  one: (contratanteId) => HTTP.get(`/contratanteConfiguration/${contratanteId}`).then((response) => response.data),
  downloadContract: (p) => HTTP.get(`/contratanteConfiguration/${p.contratanteId}/downloadContract`)
    .then((response) => response.data),
  uploadContract: (p) => HTTP.post(`/contratanteConfiguration/${p.contratanteId}/uploadContract`, p.file)
    .then((response) => response.data),
  update: (contratanteConf) => HTTP.put(`/contratanteConfiguration/${contratanteConf.id}`, contratanteConf)
    .then((response) => response.data),
};

export default ContratanteConfigurationService;
