<template>
  <v-container>
    <v-progress-linear indeterminate :active="loading" />
    <v-data-table
      :headers="headers"
      :items="dependents"
      item-key="id"
      sort-by="id"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-container fluid class="pa-0 ma-0 py-4 pb-6">
          <!-- Buttons -->
          <v-row class="pa-0 ma-0 pt-3">
            <v-col
              xl="1" lg="1" md="1" sm="1"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <v-btn
                color="primary" dark class="mb-2"
                @click="handleNew"
                :disabled="titularId == null"
              >
                Novo
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:[`item.birthdate`]="{ item }">
        {{ item.birthdate | date }}
      </template>

      <template v-slot:[`item.countryRegister`]="{ item }">
        {{ item.countryRegister | document }}
      </template>

      <template v-slot:[`item.mobile`]="{ item }">
        {{ item.mobile | phone }}
      </template>

      <template v-slot:[`item.work`]="{ item }">
        {{ item.work | phone }}
      </template>

      <template v-slot:[`item.beneficiaryActive`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>{{ $options.filters.activeIcon(item.beneficiaryActive) }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $options.filters.active(item.beneficiaryActive) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="handleEdit(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="toChangeActive = item; confirmChangeActive = true;">
              <v-icon>{{ (item.beneficiaryActive) ? 'mdi-cancel' : 'mdi-check-bold' }}</v-icon>
            </v-btn>
          </template>
          <span>{{ (item.beneficiaryActive) ? 'Desativar' : 'Ativar' }}</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <confirmation-dialog
      v-model="confirmChangeActive" title="Confirmação"
      @confirm="toggleActive" v-if="toChangeActive">
      <div>
        {{ `Deseja ${(toChangeActive.beneficiaryActive) ? 'desativar' : 'ativar'} o dependente` }}
        <b>{{ toChangeActive.name }}</b>
      </div>
    </confirmation-dialog>

  </v-container>
</template>

<script>
import bus from '@/plugins/eventBus';
import ConfirmationDialog from '@/components/general/ConfirmationDialog';

const Hashids = require('hashids');

export default {
  components: {
    ConfirmationDialog,
  },
  props: {
    fromContratante: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    hashids: null,
    edit: false,
    headers: [
      {
        text: 'Nome', align: 'start', sortable: false, value: 'name',
      },
      { text: 'Dt. Nascimento', value: 'birthdate' },
      { text: 'Email', value: 'email' },
      { text: 'Documento', value: 'countryRegister' },
      { text: 'Celular', value: 'mobile' },
      { text: 'Telefone', value: 'work' },
      { text: 'Parentesco', value: 'kinship' },
      { text: 'Ativo?', value: 'beneficiaryActive' },

      { text: 'Ações', value: 'actions' },
    ],
    titularId: null,
    dependents: [],
    formDialog: false,
    toChangeActive: null,
    confirmChangeActive: false,
    loading: false,
    removeLoading: false,
  }),
  computed: {
  },
  watch: {
  },
  beforeMount() {
    this.getAll();
  },
  created() {
    // eslint-disable-next-line new-cap
    this.hashids = new Hashids.default(
      process.env.VUE_APP_HASHID_SALT, Number(process.env.VUE_APP_HASHID_PAD),
    );

    // console.log('fromContratante: ', this.fromContratante);

    const user = this.$store.getters['user/getUser'];
    this.titularId = user.company.userId;
  },
  methods: {
    getAll() {
      if (this.titularId != null) {
        this.loading = true;
        this.$store.dispatch('dependent/all', this.titularId).then((resp) => {
          // console.log(resp);
          this.dependents = resp;
        }).catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    created() {
      setTimeout(() => {
        this.formDialog = false;
        this.beneficiary = null;
      }, 1000);
      this.getAll();
    },
    updated() {
      setTimeout(() => {
        this.formDialog = false;
        this.beneficiary = null;
      }, 1000);

      this.getAll();
    },
    toggleActive() {
      this.removeLoading = true;
      this.$store
        .dispatch('dependent/toggleActive', this.toChangeActive.id)
        .then(() => {
          bus.$emit('message', {
            message: `Dependente ${(this.toChangeActive.beneficiaryActive) ? 'desativado' : 'ativado'} com sucesso!`,
            type: 'success',
          });
          this.getAll();
        })
        .catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        })
        .finally(() => {
          this.removeLoading = true;
          this.toChangeActive = true;
        });
    },
    handleNew() {
      this.$router.push({ name: 'dependent-cad', query: { tId: this.hashids.encode(this.titularId) } });
    },
    handleEdit(item) {
      this.$router.push({
        name: 'dependent-cad',
        params: { id: this.hashids.encode(item.id), dependent: item },
        query: { tId: this.hashids.encode(this.titularId) },
      });
    },
    cancel() {
      this.formDialog = false;
      this.dependent = null;
    },
  },
};
</script>
