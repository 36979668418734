<template>
  <v-card>
   <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-card-text>
        <v-progress-linear indeterminate :active="loading" />
        <v-row class="pa-0 ma-0" v-if="estipulante">
          <form autocomplete="off">
          <v-row >
            <!-- Membership Fee / Max Members -->
            <v-col
              xl="8" lg="8" md="8" sm="8"
              cols="12" >
              <mb-input-money
                v-model="estipulante.membershipFee"
                name="cnf_membership_fee"
                label="Taxa de adesão"
                hideDetails="auto"
                rules="required"
              />
            </v-col>
            <!-- <v-col
              xl="6" lg="6" md="6" sm="6"
              cols="12" >
              <mb-input-number
                v-model="estipulante.maxMembers"
                name="cnf_max_members"
                label="N. Maximo de Membros"
                hideDetails="auto"
                rules="required"
              />
            </v-col> -->
          </v-row>

          </form>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <mb-text-button
          name="cnf_btn_save" label="Salvar"
          :disabled="invalid"
          :loading="saveBtnLoading"
          class="ml-2 mb-2"
          @click="save"
        />
        <v-spacer />
      </v-card-actions>
    </ValidationObserver>
  </v-card>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import bus from '@/plugins/eventBus';
import MbInputMoney from '@/components/general/inputs/MbInputMoney';
import MbTextButton from '@/components/general/buttons/MbTextButton';
// import MbInputNumber from '@/components/general/inputs/MbInputNumber';

export default {
  components: {
    ValidationObserver,
    MbInputMoney,
    // MbInputNumber,
    MbTextButton,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      estipulante: null,
      loading: false,
      saveBtnLoading: false,
    };
  },
  computed: {
  },
  watch: {
    value(vl) {
      this.estipulante = vl;
    },
  },
  created() {
    this.estipulante = this.value;
  },
  methods: {
    async save() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.saveBtnLoading = true;
        this.$store
          .dispatch('estipulante/updateValues', this.estipulante)
          .then(() => {
            bus.$emit('message', {
              message: 'Estipulante atualizado com sucesso!',
              type: 'success',
            });
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
  },
};
</script>
