<template>
  <v-container>
    <v-progress-linear indeterminate :active="loading" />
    <!-- {{beneficiaries}} -->
    <v-data-table
      :headers="headers"
      :items="beneficiaries"
      :options.sync="options"
      :server-items-length="totalRecords"
      item-key="id"
      sort-by="id"
      class="elevation-1"
      :loading="loadingGrid"
    >
      <template v-slot:top>
        <v-container fluid class="pa-0 ma-0 py-4 pb-6">
          <!-- Fields -->
          <v-row class="pa-0 ma-0">
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-input-text
                v-model="filter.searchstr" name="bnfc_fltr_searchstr" label="Nome/Documento"
                @input="changeFilterSearchstr"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <v-icon v-if="(item.type == 'titular')">
          {{ $options.filters.activeIcon(item.beneficiaryActive) }}
        </v-icon>
        <span v-else></span>
      </template>

      <template v-slot:[`item.birthdate`]="{ item }">
        {{ item.birthdate | date }}
      </template>

      <template v-slot:[`item.countryRegister`]="{ item }">
        {{ item.countryRegister | document }}
      </template>

      <template v-slot:[`item.mobile`]="{ item }">
        {{ item.mobile | phone }}
      </template>

      <template v-slot:[`item.work`]="{ item }">
        {{ item.work | phone }}
      </template>

      <template v-slot:[`item.contratanteType`]="{ item }">
        {{$options.filters.personType(item.contratanteType) }}
      </template>

      <template v-slot:[`item.beneficiaryActive`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>{{ $options.filters.activeIcon(item.beneficiaryActive) }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $options.filters.active(item.beneficiaryActive) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.contratantePaymentStatus`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>
                {{ $options.filters.paymentStatusIcon(item.contratantePaymentStatus) }}
                </v-icon>
            </v-btn>
          </template>
          <span>{{ $options.filters.paymentStatus(item.contratantePaymentStatus) }}</span>
        </v-tooltip>
      </template>

    </v-data-table>

  </v-container>
</template>

<script>
import bus from '@/plugins/eventBus';
import _ from 'lodash';
import MbInputText from '@/components/general/inputs/MbInputText';

export default {
  components: {
    MbInputText,
  },
  props: {
    handleDimissed: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    headers: [
      {
        text: 'Nome', align: 'start', sortable: false, value: 'name',
      },
      { text: 'Titular', value: 'type' },
      { text: 'Dt. Nascimento', value: 'birthdate' },
      // { text: 'Email', value: 'email' },
      { text: 'Documento', value: 'countryRegister' },
      { text: 'Celular', value: 'mobile' },
      { text: 'Telefone', value: 'work' },
      { text: 'Contratante', value: 'contratanteName' },
      { text: 'Tipo Contratante', align: 'center', value: 'contratanteType' },
      { text: 'Status Pgto', align: 'center', value: 'contratantePaymentStatus' },
    ],
    totalRecords: 0,
    options: {},
    filter: {
      searchstr: null,
    },
    beneficiaries: [],
    loadingGrid: false,
    loading: false,
    user: null,
  }),
  computed: {
  },
  watch: {
    options: {
      handler() {
        this.getBeneficiaries();
      },
      deep: true,
    },
  },
  created() {
  },
  methods: {
    getBeneficiaries() {
      // console.log(`this.filter: ${JSON.stringify(this.filter)}`);
      this.loading = true;
      this.$store.dispatch('beneficiary/allByEstipulante', {
        pagination: { page: this.options.page, size: this.options.itemsPerPage },
        filter: this.filter,
      }).then((resp) => {
        this.totalRecords = resp.meta.total;
        this.beneficiaries = resp.data;
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    cancel() {
      this.formDialog = false;
      this.beneficiary = null;
    },
    changeFilterSearchstr: _.debounce(function () {
      // if ((vl && vl.length > 2) || (!vl || vl.length === 0)) {
      this.getBeneficiaries();
      // }
    }, 700),
  },
};
</script>
