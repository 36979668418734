<template>
  <v-container>
    <v-progress-linear indeterminate :active="loading" />
    <v-row class="mb-5">
      <v-col cols="12" class="pa-0 ma-0">
        <beneficiary-form
        v-model="beneficiary"
        :contratanteId="contratanteId"
        @cancel="edit = false" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BeneficiaryForm from '@/components/beneficiary/BeneficiaryForm';

const Hashids = require('hashids');

export default {
  components: {
    BeneficiaryForm,
  },
  data: () => ({
    hashids: null,
    beneficiary: null,
    contratanteId: null,
    contratante: null,
    loading: false,
  }),
  created() {
    // eslint-disable-next-line new-cap
    this.hashids = new Hashids.default(
      process.env.VUE_APP_HASHID_SALT, Number(process.env.VUE_APP_HASHID_PAD),
    );

    // eslint-disable-next-line prefer-destructuring
    this.contratanteId = this.hashids.decode(this.$route.query.cId)[0];
    this.getContratante();

    const { beneficiary } = this.$route.params;
    if (beneficiary && Object.entries(beneficiary).length > 0) {
      this.beneficiary = beneficiary;
    } else if (this.$route.params.id) {
      this.get(this.hashids.decode(this.$route.params.id)[0]);
    }
  },
  methods: {
    get(id) {
      this.loading = true;
      this.$store
        .dispatch('beneficiary/one', id)
        .then((res) => {
          this.beneficiary = res;
        }).finally(() => {
          this.loading = false;
        });
    },
    getContratante() {
      this.loading = true;
      this.$store
        .dispatch('contratante/one', this.contratanteId)
        .then((contratante) => {
          this.contratante = contratante;
        }).finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
