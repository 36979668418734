<template>
  <v-container>
    <v-row dense>
      <v-col
        xl="5" lg="5" md="5" sm="5"
        cols="12" >
        <mb-text-button
          name="lgin_medibook" label="MEDIBOOK"
          @click="$router.push('/medibook/login')" />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col
        xl="5" lg="5" md="5" sm="5"
        cols="12" >
        <mb-text-button
          name="lgin_estipulante" label="ESTIPULANTE"
          @click="$router.push('/estipulante/login')" />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col
        xl="5" lg="5" md="5" sm="5"
        cols="12" >
        <mb-text-button
          name="lgin_estipulante" label="SUBESTIPULANTE"
          @click="$router.push('/subestipulante/login')" />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col
        xl="5" lg="5" md="5" sm="5"
        cols="12" >
        <mb-text-button
          name="lgin_broker" label="CORRETORA"
          @click="$router.push('/broker/login')" />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col
        xl="5" lg="5" md="5" sm="5"
        cols="12" >
        <mb-text-button
          name="lgin_brokers" label="CORRETOR"
          @click="$router.push('/brokers/login')" />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col
        xl="5" lg="5" md="5" sm="5"
        cols="12" >
        <mb-text-button
          name="lgin_contratantepj" label="CONTRATANTE PJ"
          @click="$router.push('/contratantepj/login')" />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col
        xl="5" lg="5" md="5" sm="5"
        cols="12" >
        <mb-text-button
          name="lgin_contratantepf" label="CONTRATANTE PF"
          @click="$router.push('/contratantepf/login')" />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col
        xl="5" lg="5" md="5" sm="5"
        cols="12" >
        <mb-text-button
          name="lgin_clinic" label="Empresa"
          @click="$router.push('/company/login')" />
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import MbTextButton from '@/components/general/buttons/MbTextButton';

export default {
  name: 'Home',
  components: {
    MbTextButton,
  },
  data: () => ({

  }),
};
</script>
