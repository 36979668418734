import InvoiceService from '@/services/invoice.service';

const invoice = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    // allByCompany(_, p) {
    //   return InvoiceService.allByCompany(p);
    // },
    all(_, p) {
      return InvoiceService.all(p);
    },
    allByCompanyType(_, p) {
      return InvoiceService.allByCompanyType(p);
    },
    duplicate(_, id) {
      return InvoiceService.duplicate(id);
    },
    cancel(_, id) {
      return InvoiceService.cancel(id);
    },
  },

  getters: {},
};

export default invoice;
