import LeadService from '@/services/lead.service';

const leads = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    all(_, parameters) {
      return LeadService.all(parameters);
    },
    create(_, lead) {
      return LeadService.create(lead);
    },
    exportExcel(_, p) {
      return LeadService.exportExcel(p);
    },
  },

  getters: {},
};

export default leads;
