<template>
  <v-container>
    <v-card>

      <v-card-title>
          <span class="headline mb-0">Links - Contratante</span>
      </v-card-title>

      <v-card-text>

        <!-- <div> -->
          <v-row class="pa-0 ma-0">
            <v-col
              v-if="subestipulantes && subestipulantes.length > 0"
              xl="2" lg="2" md="2" sm="2"
              cols="12" class="pt-6">
              <mb-select
                v-model="subestipulante" :items="subestipulantes"
                item-text="slug" name="lnks_fltr_subestipulante" label="Subestipulantes"
                clearable @click-clear="clearSubestipulante"
                :loading="loading"
              />
            </v-col>
          </v-row>

          <!-- <v-divider></v-divider> -->
        <!-- </div> -->

        <v-row class="pa-0 ma-0" v-if="estipulante">

          <!-- v-if="estipulante.naturalPerson" -->
          <v-col class="pa-0 ma-0" cols="12" v-if="estipulante.naturalPerson">

            <v-row class="pa-0 ma-0 mt-2 mb-5" v-if="estipulante || subestipulante">
              <v-col cols="12">
                Pessoa Física - Todos os Planos com adesão
              </v-col>
              <v-col cols="12" v-if="countWithAdhesion > 0">
                  <v-icon left>mdi-link-variant</v-icon>{{ getPFUrlWithAdhesion() }}
              </v-col>
              <v-col cols="12" v-else>
                Não há planos com adesão cadastrados
              </v-col>
            </v-row >

            <v-divider></v-divider>

            <v-row class="pa-0 ma-0 my-5" v-if="estipulante || subestipulante">
              <v-col cols="12">
                Pessoa Física - Todos os Planos sem adesão
              </v-col>
              <v-col cols="12" v-if="countWithoutAdhesion > 0">
                <v-icon left>mdi-link-variant</v-icon>{{ getPFUrlWithoutAdhesion() }}
              </v-col>
              <v-col cols="12" v-else>
                Não há planos sem adesão cadastrados
              </v-col>
            </v-row >

            <v-divider></v-divider>

            <v-row class="pa-0 ma-0 my-5" v-if="estipulante || subestipulante">
              <v-col cols="12">
                Pessoa Física - Por Plano
              </v-col>
              <v-col
                xl="3" lg="3" md="3" sm="3"
                cols="12" class="pa-0 ma-0 mt-2">
                <mb-select
                  v-model="plan" :items="plans"
                  item-text="description" name="lnks_fltr_plan" label="Planos"
                  :loading="loadingPlans"
                />
              </v-col>
              <v-col cols="12" class="mt-4" v-if="plan">
                <v-icon left>mdi-link-variant</v-icon>{{ getPFUrlByPlan() }}
              </v-col>
            </v-row >

          </v-col>

          <v-col class="pa-0 ma-0" v-if="estipulante.legalPerson">
            <v-divider></v-divider>
            <v-col cols="12">
                Pessoa Jurídica
              </v-col>
            <v-col cols="12" class="pa-0 ma-0 mt-2" v-if="estipulante || subestipulante">
              <v-icon left>mdi-link-variant</v-icon>{{ getPJUrl() }}
            </v-col>
          </v-col>

        </v-row>

        <!-- <div v-if="!estipulante.naturalPerson || (estipulante.naturalPerson && plan)">

          <v-row class="pa-0 ma-0 mt-2 mb-5" v-if="estipulante || subestipulante">
            <v-col cols="12">
              Todos os Planos com adesão
            </v-col>
            <v-col cols="12" v-if="countWithAdhesion > 0">
                <v-icon left>mdi-link-variant</v-icon>{{ getUrlWithAdhesion() }}
            </v-col>
            <v-col cols="12" v-else>
              Não há planos com adesão cadastrados
            </v-col>
          </v-row >

          <v-divider></v-divider>

          <v-row class="pa-0 ma-0 my-5" v-if="estipulante || subestipulante">
            <v-col cols="12">
              Todos os Planos sem adesão
            </v-col>
            <v-col cols="12" v-if="countWithoutAdhesion > 0">
              <v-icon left>mdi-link-variant</v-icon>{{ getUrlWithoutAdhesion() }}
            </v-col>
            <v-col cols="12" v-else>
              Não há planos sem adesão cadastrados
            </v-col>
          </v-row >

          <v-divider></v-divider>

          <v-row class="pa-0 ma-0 my-5" v-if="estipulante || subestipulante">
              <v-col cols="12">
                Por Plano
              </v-col>
              <v-col
                xl="2" lg="2" md="2" sm="2"
                cols="12" class="pa-0 ma-0 mt-2">
                <mb-select
                  v-model="plan" :items="plans"
                  item-text="description" name="lnks_fltr_plan" label="Planos"
                  :loading="loadingPlans"
                />
              </v-col>
              <v-col cols="12" class="mt-4" v-if="plan">
                <v-icon left>mdi-link-variant</v-icon>{{ getUrlByPlan() }}
              </v-col>
            </v-row >
        </div> -->

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import bus from '@/plugins/eventBus';
import MbSelect from '@/components/general/inputs/MbSelect';

export default {
  components: {
    MbSelect,
  },
  props: {
  },
  computed: {
    basePath() {
      return `${process.env.VUE_APP_PATH}`;
    },
  },
  data() {
    return {
      loading: false,
      loadingPlans: false,
      estipulante: null,
      subestipulante: null,
      subestipulantes: [],
      plan: null,
      plans: [],
      options: {
        itemsPerPage: 10,
      },
      countWithAdhesion: 0,
      countWithoutAdhesion: 0,
    };
  },
  created() {
    this.user = this.$store.getters['user/getUser'];
    this.estipulante = this.user.company;

    if (this.estipulante.naturalPerson) {
      this.getPlans();
    }

    this.getSubestipulantes();
  },
  methods: {
    getSubestipulantes() {
      if (this.estipulante !== null) {
        this.loading = true;
        this.$store.dispatch('subestipulante/all', {
          id: this.estipulante.id,
          pagination: { page: 1, size: -1 },
        }).then((resp) => {
          this.subestipulantes = resp.data;
        }).catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    clearSubestipulante() {
      this.subestipulante = null;
      this.getPlans();
    },
    getPlans() {
      this.loadingPlans = true;
      this.$store.dispatch('plan/actives', {
        estipulanteId: this.estipulante.id,
        subestipulanteId: (this.subestipulante) ? this.subestipulante.id : null,
      }).then((plans) => {
        console.log('plans');
        console.log(plans);
        this.plans = plans.filter((i) => ((i.type === 'pf') ? i : null));
        const withAdhesion = plans.filter((plan) => (plan.withAdhesion));
        const withoutAdhesion = plans.filter((plan) => (!plan.withAdhesion));
        this.countWithAdhesion = withAdhesion.length;
        this.countWithoutAdhesion = withoutAdhesion.length;
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.loadingPlans = false;
      });
    },
    // getPlans() {
    //   this.loadingPlans = true;
    //   this.$store.dispatch('plan/actives', {
    //     estipulanteId: this.estipulante.id,
    //   }).then((plans) => {
    //     console.log('plans');
    //     console.log(plans);
    //     this.plans = plans;
    //     const withAdhesion = plans.filter((plan) => (plan.withAdhesion));
    //     const withoutAdhesion = plans.filter((plan) => (!plan.withAdhesion));
    //     this.countWithAdhesion = withAdhesion.length;
    //     this.countWithoutAdhesion = withoutAdhesion.length;
    //   }).catch((err) => {
    //     bus.$emit('message', {
    //       message: err.response.data.message,
    //       type: 'error',
    //     });
    //   }).finally(() => {
    //     this.loadingPlans = false;
    //   });
    // },
    getPJUrl() {
      if (this.estipulante) {
        const params = {
          u: this.user.id, t: 'pj',
        };

        params.e = this.estipulante.id;

        if (this.subestipulante) {
          params.se = this.subestipulante.id;
        }

        // console.log(params);

        const paramsEncoded = window.btoa(JSON.stringify(params));
        return encodeURI(`${this.basePath}/link/contratante/${paramsEncoded}`);
      }
      return null;
    },
    getPFUrlWithAdhesion() {
      if (this.estipulante) {
        const params = {
          u: this.user.id, withoutAdhesion: false, t: 'pf',
        };

        params.e = this.estipulante.id;

        if (this.subestipulante) {
          params.se = this.subestipulante.id;
        }

        // console.log(params);

        const paramsEncoded = window.btoa(JSON.stringify(params));
        return encodeURI(`${this.basePath}/link/contratante/${paramsEncoded}`);
      }

      return null;
    },
    getPFUrlWithoutAdhesion() {
      if (this.estipulante) {
        const params = {
          u: this.user.id, withoutAdhesion: true, t: 'pf',
        };

        params.e = this.estipulante.id;

        if (this.subestipulante) {
          params.se = this.subestipulante.id;
        }

        // console.log(params);

        const paramsEncoded = window.btoa(JSON.stringify(params));
        return encodeURI(`${this.basePath}/link/contratante/${paramsEncoded}`);
      }
      return null;
    },
    getPFUrlByPlan() {
      if (this.estipulante) {
        const params = {
          u: this.user.id, t: 'pf',
        };

        params.e = this.estipulante.id;

        if (this.subestipulante) {
          params.se = this.subestipulante.id;
        }

        if (this.estipulante.naturalPerson && this.plan) {
          params.p = this.plan.id;
        }

        // console.log(params);

        const paramsEncoded = window.btoa(JSON.stringify(params));
        return encodeURI(`${this.basePath}/link/contratante/${paramsEncoded}`);
      }
      return null;
    },
    // getUrl(sub) {
    //   const params = { e: this.estipulante.id, u: this.user.id };

    //   if (sub) {
    //     params.se = this.subestipulante.id;
    //   }

    //   if (this.estipulante.naturalPerson) {
    //     params.p = this.plan.id;
    //   }

    //   const paramsEncoded = window.btoa(JSON.stringify(params));
    //   return encodeURI(`${this.basePath}/link/contratante/${paramsEncoded}`);
    // },
    // getUrlWithAdhesion() {
    //   if (this.estipulante) {
    //     const params = {
    //       u: this.user.id, withoutAdhesion: false,
    //     };

    //     params.e = this.estipulante.id;

    //     if (this.subestipulante) {
    //       params.se = this.subestipulante.id;
    //     }

    //     console.log(params);

    //     const paramsEncoded = window.btoa(JSON.stringify(params));
    //     return encodeURI(`${this.basePath}/link/contratante/${paramsEncoded}`);
    //   }

    //   return null;
    // },
    // getUrlWithoutAdhesion() {
    //   if (this.estipulante) {
    //     const params = {
    //       u: this.user.id, withoutAdhesion: true,
    //     };

    //     params.e = this.estipulante.id;

    //     if (this.subestipulante) {
    //       params.se = this.subestipulante.id;
    //     }

    //     console.log(params);

    //     const paramsEncoded = window.btoa(JSON.stringify(params));
    //     return encodeURI(`${this.basePath}/link/contratante/${paramsEncoded}`);
    //   }
    //   return null;
    // },
    // getUrlByPlan() {
    //   if (this.estipulante) {
    //     const params = { u: this.user.id };

    //     params.e = this.estipulante.id;

    //     if (this.subestipulante) {
    //       params.se = this.subestipulante.id;
    //     }

    //     if (this.estipulante.naturalPerson && this.plan) {
    //       params.p = this.plan.id;
    //     }

    //     console.log(params);

    //     const paramsEncoded = window.btoa(JSON.stringify(params));
    //     return encodeURI(`${this.basePath}/link/contratante/${paramsEncoded}`);
    //   }
    //   return null;
    // },
  },
};
</script>
