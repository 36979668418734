<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="name"
    :name="label"
    :mode="validationMode"
    :rules="rules"
  >
    <v-text-field
      :id="name"
      v-model="internalValue"
      autocomplete="off"
      :label="label"
      :hint="hint"
      :persistent-hint="persistentHint"
      :placeholder="placeholder"
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      :prefix="prefix"
      :suffix="suffix"
      :type="showPassword ? 'text' : 'password'"
      :loading="loading"
      :disabled="disabled"
      outlined dense
      :error="errors.length > 0"
      :error-messages="errors[0]"
      @input="input"
      @click:append="showPassword = !showPassword"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    validationMode: {
      type: String,
      default: 'lazy',
    },
    hint: {
      type: String,
      default: 'Mínimo de 6 caracters.',
    },
    label: {
      type: String,
      default: null,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: null,
    },
  },
  data: () => ({
    internalValue: '',
    showPassword: false,
  }),
  watch: {
    value(vl) {
      this.internalValue = vl;
    },
  },
  created() {
    this.internalValue = this.value || '';
  },
  methods: {
    input() {
      this.$emit('input', this.internalValue);
    },
  },
};
</script>
