<template>
  <v-container>
    <v-progress-linear indeterminate :active="loading" />
    <v-card class="elevation-1">

      <v-card-title v-if="contratante">
        <div v-if="contratante.type == 'pj'" class="headline mb-0">
          Beneficiário -> Contratante PF
        </div>
        <div v-if="contratante.type == 'pf'" class="headline mb-0">
          Contratante PF -> Beneficiário PJ
        </div>
      </v-card-title>

      <v-card-subtitle>
        <div
          v-if="beneficiary && contratante && contratante.type == 'pj'"
          class="subtitle-2 headline mb-0"
        >
          {{ beneficiary.name }}
        </div>
        <div
          v-if="contratante && contratante && contratante.type == 'pf'"
          class="subtitle-2 headline mb-0"
        >
          {{ contratante.name }}
        </div>
      </v-card-subtitle>

      <change-beneficiary-pj-to-pf
        v-if="beneficiary != null && contratante && contratante.type == 'pj'"
        :beneficiary="beneficiary"
      />

      <change-beneficiary-pf-to-pj
        v-if="beneficiary != null && contratante && contratante.type == 'pf'"
        :beneficiary="beneficiary"
      />

    </v-card>
  </v-container>
</template>

<script>
import ChangeBeneficiaryPjToPf from '@/components/contratante/ChangeBeneficiaryPjToPf';
import ChangeBeneficiaryPfToPj from '@/components/contratante/ChangeBeneficiaryPfToPj';

const Hashids = require('hashids');

export default {
  components: {
    ChangeBeneficiaryPjToPf,
    ChangeBeneficiaryPfToPj,
  },
  data: () => ({
    hashids: null,
    loading: false,
    beneficiaryId: null,
    contratanteId: null,
    brokerId: null,
    beneficiary: null,
    contratante: null,
  }),
  created() {
    // eslint-disable-next-line new-cap
    this.hashids = new Hashids.default(
      process.env.VUE_APP_HASHID_SALT, Number(process.env.VUE_APP_HASHID_PAD),
    );

    // eslint-disable-next-line prefer-destructuring
    this.beneficiaryId = this.hashids.decode(this.$route.query.bId)[0];

    if (this.beneficiaryId != null) {
      this.getBeneficiary();
    }
  },
  methods: {
    getBeneficiary() {
      this.$store.dispatch(
        'beneficiary/one', this.beneficiaryId,
      ).then((beneficiary) => {
        this.beneficiary = beneficiary;
        this.contratanteId = beneficiary.contratanteId;
        this.getContratante();
      }).finally(() => {
        this.loading = false;
      });
    },
    getContratante() {
      this.$store.dispatch(
        'contratante/one', this.contratanteId,
      ).then((contratante) => {
        this.contratante = contratante;
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
