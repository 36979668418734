<template>
  <v-container fluid>
    <ValidationObserver ref="observer" v-slot="{ }">
      <!-- Email -->
      <v-row>
        <v-col>
          <mb-input-cnpj
            v-model="credentials.login"
            name="login_pj_cnpj"
            label="CNPJ"
            rules="required"
            @input="updateRememberMe"
          />
        </v-col>
      </v-row>
      <!-- Senha -->
      <v-row no-gutters>
        <v-col>
          <mb-input-password
            v-model="credentials.password"
            name="login_pj_password"
            label="Senha"
            rules="required|min:6"
          />
        </v-col>
      </v-row>
      <!-- Botões - Cadastrar e Acessar -->
      <v-row no-gutters justify="end">
        <v-col
          align="right" xl="4" lg="4"
          md="6" sm="5" cols="12"
          class="text-right"
        >
          <mb-text-button
            name="login_pj_access" label="Acessar" :loading="loginBtnLoading"
            @click="login"
          />
        </v-col>
      </v-row>
      <!-- Lembre-me / Esqueceu a senha -->
      <v-row align="center" class="px-2 pl-4">
        <v-col>
          <v-switch v-model="rememberMe" hide-details label="Lembre-me" />
        </v-col>
        <v-col align="right">
          <v-btn
            name="login_pj_recover" text small
            @click="recoverPassword"
          >
            Esqueceu a senha?
          </v-btn>
        </v-col>
      </v-row>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import bus from '@/plugins/eventBus';
import MbInputCnpj from '@/components/general/inputs/MbInputCnpj';
import MbInputPassword from '@/components/general/inputs/MbInputPassword';
import MbTextButton from '@/components/general/buttons/MbTextButton';
import { routesBroker } from '../../router/routes';

export default {
  components: {
    ValidationObserver,
    MbInputCnpj,
    MbInputPassword,
    MbTextButton,
  },
  data: () => ({
    credentials: {
      login: null,
      password: null,
    },
    loginBtnLoading: false,
    rememberMe: false,
  }),
  watch: {
    rememberMe() {
      this.updateRememberMe();
    },
  },
  created() {
    const rememberMeCNPJ = this.getRememberMe();
    if (rememberMeCNPJ !== null) {
      this.credentials.login = rememberMeCNPJ;
      this.rememberMe = true;
    }
  },
  methods: {
    async login() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.loginBtnLoading = true;
        this.$store
          .dispatch('user/login', { credentials: this.credentials, type: 'broker' })
          .then(() => {
            this.$router.addRoutes(routesBroker, { replace: true });
            this.$router.push({ name: 'home' });
          })
          .catch((err) => {
            if (err.response && err.response.data && err.response.data.message.includes('ativado')) {
              bus.$emit('message', {
                message: 'Seu cadastro não foi ativado. Verifique email de ativação.',
                type: 'warn',
              });
            } else {
              bus.$emit('message', {
                message: err.response.data.message,
                type: 'error',
              });
            }
          })
          .finally(() => {
            this.loginBtnLoading = false;
          });
      }
    },
    register() {
      this.$router.push({ path: '/register' });
    },
    recoverPassword() {
      this.$router.push({ path: '/recover-password', query: { from: this.$router.currentRoute.name } });
    },
    getRememberMe() {
      const rememberMe = localStorage.getItem('mbermebrk');
      if (rememberMe !== null) {
        return atob(rememberMe);
      }
      return rememberMe;
    },
    updateRememberMe() {
      if (this.rememberMe === true && this.credentials.login !== null) {
        localStorage.setItem('mbermebrk', btoa(this.credentials.login));
      } else {
        localStorage.removeItem('mbermebrk');
      }
    },
  },
};
</script>
