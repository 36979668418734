import HTTP from './axios';

const CommissionService = {
  allByEstipulante: (p) => HTTP.get(`/estipulante/${p.estipulanteId}/commission/${p.type}?active=${p.active}`)
    .then((response) => response.data),
  create: (commission) => HTTP.post(`/estipulante/${commission.estipulanteId}/commission`, commission)
    .then((response) => response.data),
  update: (commission) => HTTP.put(`/commission/${commission.id}`, commission)
    .then((response) => response.data),
};

export default CommissionService;
