<template>
  <v-card>
   <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-card-text>
        <v-progress-linear indeterminate :active="loading" />
        <v-row class="pa-0 ma-0" v-if="estipulante">
          <form autocomplete="off">
            <v-row dense>
              <v-col cols="12" class="mr-4">
                <v-switch v-model="legalPerson" label="Contratante Pessoa Jurídica?"/>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" class="mr-4">
                <v-switch v-model="naturalPerson" label="Contratante Pessoa Física?"/>
              </v-col>
            </v-row>
          </form>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <mb-text-button
          name="cnf_btn_save" label="Salvar"
          :disabled="invalid"
          :loading="saveBtnLoading"
          class="ml-2 mb-2"
          @click="save"
        />
        <v-spacer />
      </v-card-actions>
    </ValidationObserver>
  </v-card>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import bus from '@/plugins/eventBus';
import MbTextButton from '@/components/general/buttons/MbTextButton';

export default {
  components: {
    ValidationObserver,
    MbTextButton,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      estipulante: null,
      loading: false,
      legalPerson: false,
      naturalPerson: false,
      saveBtnLoading: false,
    };
  },
  computed: {
  },
  watch: {
    value(vl) {
      this.estipulante = vl;
      if (this.estipulante) {
        this.legalPerson = this.estipulante.legalPerson;
        this.naturalPerson = this.estipulante.naturalPerson;
      }
    },
  },
  created() {
    this.estipulante = this.value;
    if (this.estipulante) {
      this.legalPerson = this.estipulante.legalPerson;
      this.naturalPerson = this.estipulante.naturalPerson;
    }
  },
  methods: {
    async save() {
      const isValid = await this.$refs.observer.validate();
      const updEstipulante = JSON.parse(JSON.stringify(this.estipulante));
      updEstipulante.legalPerson = this.legalPerson;
      updEstipulante.naturalPerson = this.naturalPerson;

      if (isValid) {
        this.saveBtnLoading = true;
        this.$store
          .dispatch('estipulante/updateTypes', updEstipulante)
          .then((updated) => {
            this.estipulante = updated;
            this.$emit('input', this.estipulante);
            bus.$emit('message', {
              message: 'Estipulante atualizado com sucesso!',
              type: 'success',
            });
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
  },
};
</script>
