import PaymentMethod from '@/services/paymentMethod.service';

const PaymentMethods = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    all() {
      return PaymentMethod.all();
    },
    create(_, parameters) {
      return PaymentMethod.create(parameters);
    },
    remove(_, paymentMethodId) {
      return PaymentMethod.remove(paymentMethodId);
    },
  },

  getters: {},
};

export default PaymentMethods;
