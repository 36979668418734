<template>
  <v-card>
    <v-progress-linear indeterminate :active="loading" />
    <v-data-table
      :headers="headers"
      :items="plans"
      :options.sync="options"
      :server-items-length="totalRecords"
      item-key="id"
      sort-by="id"
      class="elevation-0 mt-1"
      :loading="loadingGrid"
    >
      <template v-slot:top>
        <v-container fluid class="pa-0 ma-0 py-4 pb-6">
          <!-- <v-row class="pa-0 ma-0 ml-1">
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="mr-4">
              <mb-select
                v-model="filter.active" :items="types"
                item-text="txt" item-value="vl" name="plnfltr_fltr_verified" label="Ativos"
                @input="changeFilter"
              />
            </v-col>
          </v-row> -->
          <v-row class="pa-0 ma-0">
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-input-text
                v-model="filter.description" name="plnfltr_fltr_description" label="Descrição"
                @input="changeFilterName"
              />
            </v-col>
            <v-col
              xl="2" lg="2" md="2" sm="2"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-select
                v-model="filter.active" :items="actives"
                item-text="txt" item-value="vl" name="plnfltr_fltr_verified" label="Ativos"
                @input="changeFilter"
              />
            </v-col>
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-date
                v-model="filter.dateStart"
                name="plnfltr_fltr_date_start"
                label="Data Inicial"
                :max="filter.dateEnd"
                @input="changeFilter"
              />
            </v-col>
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-date
                v-model="filter.dateEnd"
                name="plnfltr_fltr_date_end"
                label="Data Final"
                rules="dateAfter:@plnfltr_fltr_date_end"
                :min="filter.dateStart"
                @input="changeFilter"
              />
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col
              xl="2" lg="2" md="2" sm="2"
              cols="12" class="pa-0 ma-0">
              <mb-text-button
                name="cnf_btn_save" label="Adicionar"
                :loading="addBtnLoading"
                class="ml-2 mb-2"
                @click="showPlanForms = true"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:[`item.value`]="{ item }">
        {{ item.value | centsToReal }}
      </template>

      <template v-slot:[`item.withoutBound`]="{ item }">
        <v-icon v-if="item.withoutBound">
          {{ $options.filters.activeIcon(item.withoutBound) }}
        </v-icon>
        <span v-else></span>
      </template>

      <template v-slot:[`item.withAdhesion`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>{{ $options.filters.withAdhesionIcon(item.withAdhesion) }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $options.filters.withAdhesion(item.withAdhesion) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.special`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>{{ $options.filters.specialIcon(item.special) }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $options.filters.special(item.special) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>{{ $options.filters.activeIcon(item.active) }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $options.filters.active(item.active) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="handleEdit(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="showPlanForms" max-width="850" @click:outside="closed">
        <plan-form
          v-model="toEdit"
          :estipulante="estipulante"
          :type="personType"
          @closed="closed"
          @updated="getAll()"
        />
    </v-dialog>

  </v-card>
</template>

<script>
import bus from '@/plugins/eventBus';
import _ from 'lodash';
import MbTextButton from '@/components/general/buttons/MbTextButton';
import MbDate from '@/components/general/inputs/MbDate';
import MbSelect from '@/components/general/inputs/MbSelect';
import MbInputText from '@/components/general/inputs/MbInputText';
import PlanForm from '@/components/estipulante/PlanForm';

export default {
  components: {
    MbTextButton,
    MbDate,
    MbSelect,
    MbInputText,
    PlanForm,
  },
  props: {
    estipulante: {
      type: Object,
      default: null,
    },
    personType: {
      type: String,
      default: null,
      required: true,
    },
  },
  data: () => ({
    user: null,
    showPlanForms: false,
    addBtnLoading: false,
    totalRecords: 0,
    options: {},
    loadingGrid: false,
    loading: false,
    headers: [
      { text: 'Descrição', value: 'description' },
      { text: 'N. de Vidas', value: 'maxLifes' },
      { text: 'Valor', value: 'value' },
      { text: 'Sem Vínculo', align: 'center', value: 'withoutBound' },
      { text: 'Com Adesão', align: 'center', value: 'withAdhesion' },
      { text: 'Especial', align: 'center', value: 'special' },
      { text: 'Ativo?', align: 'center', value: 'active' },
      { text: 'Ações', align: 'center', value: 'actions' },
    ],
    plans: [],
    types: [
      { txt: 'Todos', vl: 'all' },
      { txt: 'Com vínculo', vl: '1' },
      { txt: 'Sem vínculo', vl: '0' },
    ],
    actives: [
      { txt: 'Todos', vl: 'all' },
      { txt: 'Ativos', vl: '1' },
      { txt: 'Não ativos', vl: '0' },
    ],
    filter: {
      dateStart: null,
      dateEnd: null,
      active: 'all',
      name: null,
      personType: 'pj',
    },
    toEdit: null,
    confirmChangeActive: false,
  }),
  watch: {
    options: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
    estipulante: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
  },
  created() {
    this.filter.personType = this.personType;
    this.user = this.$store.getters['user/getUser'];
  },
  methods: {
    getAll() {
      if (this.estipulante) {
        this.loading = true;
        this.$store.dispatch('plan/all', {
          estipulanteId: this.estipulante.id,
          pagination: { page: this.options.page, size: this.options.itemsPerPage },
          filter: this.filter,
        }).then((resp) => {
          this.totalRecords = resp.meta.total;
          this.plans = resp.data;
        }).catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    handleEdit(item) {
      this.showPlanForms = true;
      this.toEdit = JSON.parse(JSON.stringify(item));
    },
    closed() {
      // console.log('mensalidade close');
      this.showPlanForms = false;
      this.toEdit = null;
    },
    changeFilter() {
      this.getAll();
    },
    changeFilterName: _.debounce(function () {
      // if ((vl && vl.length > 2) || (!vl || vl.length === 0)) {
      this.getAll();
      // }
    }, 700),
  },
};
</script>
