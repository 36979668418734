<template>
  <v-container>
    <v-progress-linear indeterminate :active="loading" />
    <v-row class="mb-5">
      <v-col cols="12" class="pa-0 ma-0">
        <bank-account-form
          v-model="banckAccount" :company="company"
          @cancel="edit = false" @updated="handleUpdated" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BankAccountForm from '@/components/companyEco/BankAccountForm';

export default {
  components: {
    BankAccountForm,
  },
  data: () => ({
    company: null,
    banckAccount: null,
    loading: false,
  }),
  created() {
    this.get();
  },
  methods: {
    async get() {
      const user = await this.$store.getters['user/getUser'];
      this.getCompany(user.company.id);
      this.getBankAccount(user.company.id);
    },
    getCompany(companyId) {
      this.$store
        .dispatch('companyEconocard/one', companyId)
        .then((res) => {
          this.company = res;
        });
    },
    getBankAccount(companyId) {
      this.loading = true;
      this.$store
        .dispatch('bankAccount/one', companyId)
        .then((res) => {
          this.banckAccount = res || null;
        }).finally(() => {
          this.loading = false;
        });
    },
    handleUpdated() {
      // console.log(`handleUpdated company: ${JSON.stringify(this.company)}`);
      this.getCompany(this.company.id);
    },
  },
};
</script>
