<template>
  <ValidationProvider
    v-slot="{ errors }" :name="label" :mode="validationMode"
    :rules="rules"
  >
    <v-select
      :id="name"
      v-model="internalValue"
      autocomplete="off"
      :items="items"
      :data-vv-as="name"

      :multiple="multiple"
      :label="label"
      :hint="hint"
      :persistent-hint="persistentHint"
      :placeholder="placeholder"
      :item-text="itemText"
      :item-value="itemValue"
      :prepend-icon="prependIcon"
      :append-outer-icon="appendOuterIcon"
      :chips="chips || multiple"
      :disabled="disabled"
      :return-object="returnObject"
      :no-data-text="noDataText"
      :hide-details="hideDetails"
      :dense="dense"
      :clearable="clearable"
      outlined
      :error="errors.length > 0"
      :error-messages="errors[0]"
      @input="input"
      @change="change"
      @click:clear="$emit('click-clear')"
    >
      <!-- <slot name="selection"></slot> -->
    </v-select>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: [String, Number, Array, Object],
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    validationMode: {
      type: String,
      default: 'lazy',
    },
    name: {
      type: String,
      required: true,
    },
    hint: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    persistentHint: {
      type: Boolean,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
    },
    itemText: {
      type: [String, Array, Function],
      default: null,
    },
    itemValue: {
      type: [String, Array, Function],
      default: null,
    },
    loading: {
      type: Boolean,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    appendOuterIcon: {
      type: String,
      default: null,
    },
    chips: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: 'Nenhum registro encontrado',
    },
    rules: {
      type: [String, Object],
      default: null,
    },
  },
  data: () => ({
    internalValue: null,
  }),
  watch: {
    value(vl) {
      this.internalValue = vl;
    },
  },
  created() {
    if (this.multiple && typeof this.value === 'string') {
      this.internalValue = this.value.split(',');
    } else {
      this.internalValue = this.value;
    }
  },
  methods: {
    input(vl) {
      this.$emit('input', vl);
    },
    change(vl) {
      this.$emit('change', vl);
    },
  },
};
</script>
