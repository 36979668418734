import ContratanteConfigurationService from '@/services/contratanteConfiguration.service';

const contratanteConfigurations = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    one(_, companyId) {
      return ContratanteConfigurationService.one(companyId);
    },
    downloadContract(_, parameters) {
      return ContratanteConfigurationService.downloadContract(parameters);
    },
    uploadContract(_, parameters) {
      return ContratanteConfigurationService.uploadContract(parameters);
    },
    update(_, contratanteConfiguration) {
      return ContratanteConfigurationService.update(contratanteConfiguration);
    },
  },

  getters: {},
};

export default contratanteConfigurations;
