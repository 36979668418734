<template>
  <div class="home" />
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
  data: () => ({

  }),
};
</script>
