/* eslint-disable camelcase */
import 'moment/locale/pt-br';
import moment from 'moment/src/moment';
import { extend, localize, ValidationProvider } from 'vee-validate';
import ptBR from 'vee-validate/dist/locale/pt_BR.json';
// import {
//   email, length, max, max_value, min, min_value, numeric, regex,
//   // eslint-disable-next-line camelcase
//   required,
// } from 'vee-validate/dist/rules';
import * as rules from 'vee-validate/dist/rules';
import Vue from 'vue';
import CnpjValidate from '../utils/cnpjValidate';
import CpfValidate from '../utils/cpfValidate';

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

moment.locale('pt_BR');

// Add rules
// extend('required', required);
// extend('email', email);
// extend('regex', regex);
// extend('length', length);
// extend('min', min);
// extend('max', max);
// extend('max_value', max_value);
// extend('min_value', min_value);
// extend('numeric', numeric);

extend('password', {
  params: [{ name: 'target', isTarget: true }],
  validate(value, { target }) {
    return value === target;
  },
  message: 'A senha e a confirmação da senha não são iguais',
});

extend('cep', {
  computesRequired: true,
  validate(value) {
    if (!value) return true;
    return value.replace('-', '').length === 8;
  },
  message: () => 'CEP inválido!',
});

extend('cpf', {
  computesRequired: true,
  validate(value) {
    if (!value) return true;
    return CpfValidate(value);
  },
  message: () => 'CPF inválido!',
});

extend('cnpj', {
  computesRequired: true,
  validate(value) {
    if (!value) return true;
    return CnpjValidate(value);
  },
  message: () => 'CNPJ inválido!',
});

extend('document', {
  computesRequired: true,
  validate(value) {
    if (!value) return true;
    return CnpjValidate(value) || CpfValidate(value);
  },
  message: () => 'Documento inválido!',
});

extend('minNow', {
  computesRequired: true,
  params: ['field'],
  validate(value) {
    if (!value) return true;
    const now = moment.utc().startOf('day'); // console.log(`now: ${now}`);
    const date = moment.utc(value, 'DD/MM/YYYY', 'pt-BR'); // console.log(`date: ${date}`);
    // console.log(`isSameOrAfter: ${date.isSameOrAfter(now)}`);
    return date.isSameOrAfter(now);
  },
  message: () => 'A data deve ser superior a data atual',
});

extend('min_value_str', {
  computesRequired: true,
  params: ['min'],
  validate(value, { minVal }) {
    if (!value) return true;
    return Number(value.replace(',', '')) > Number(minVal);
  },
  message: (filed, p) => `${filed} deve ser maior que ${p.min}`,
});

extend('dateAfter', {
  computesRequired: true,
  params: ['target', 'field'],
  validate(value, { target }) {
    if (!value || !target) return true;
    const targetVal = moment.utc(target, 'YYYY-MM-DD', 'pt-BR');
    return moment.utc(value, 'YYYY-MM-DD', 'pt-BR').isAfter(targetVal);
  },
  message: () => 'A data deve ser superior a data inicial',
});

extend('token', {
  validate(value) {
    if (!value) return false;
    // eslint-disable-next-line no-undef
    return value.length === 1 && /^\d+$/.test(value);
  },
  message: 'Token inválido',
});

extend('phone', {
  computesRequired: true,
  validate(value) {
    if (!value) return false;
    // console.log(`validate value: ${value.replace(/\D+/g, '')}`);
    const isValid = (/^[1-9]{2}(?:[2-8]|9[1-9])[0-9]{3}[0-9]{4}$/.test(value.replace(/\D+/g, '')));
    // console.log(`validate isValid: ${isValid}`);
    return isValid;
  },
  message: () => 'Número inválido',
});

localize('pt_BR', ptBR);

// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
