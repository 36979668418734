<template>
  <v-container>
    <v-progress-linear indeterminate :active="loading" />
    <v-card class="elevation-1">
      <v-card-title>
        <span v-if="contratante && contratante.id" class="headline mb-0">
          {{ contratante.name }}
        </span>
        <span v-else class="headline mb-0">Novo Contratante</span>
      </v-card-title>

      <contratante-form
        v-if="contratante"
        v-model="contratante"
        :estipulanteId="contratante.estipulanteId"
        :subestipulanteId="contratante.subestipulanteId"
        @cancel="edit = false" />

      <!-- <contratante-form-pf
        v-if="contratante && estipulante && estipulante.naturalPerson"
        v-model="contratante"
        :estipulanteId="contratante.estipulanteId"
        :subestipulanteId="contratante.subestipulanteId"
        @cancel="edit = false" />

      <contratante-form-pj
        v-else-if="contratante && estipulante && !estipulante.naturalPerson"
        v-model="contratante"
        :estipulanteId="contratante.estipulanteId"
        :subestipulanteId="contratante.subestipulanteId"
        @cancel="edit = false" /> -->

    </v-card>
  </v-container>
</template>

<script>
import ContratanteForm from '@/components/contratante/ContratanteForm';
// import ContratanteFormPj from '@/components/contratante/ContratanteFormPj';
// import ContratanteFormPf from '@/components/contratante/ContratanteFormPf';

export default {
  components: {
    ContratanteForm,
    // ContratanteFormPj,
    // ContratanteFormPf,
  },
  data: () => ({
    hashids: null,
    loading: false,
    contratante: null,
    estipulante: null,
  }),
  created() {
    this.get();
  },
  methods: {
    async get() {
      const user = await this.$store.getters['user/getUser'];
      // console.log(`user: ${JSON.stringify(user)}`);

      this.$store
        .dispatch('contratante/one', user.company.id)
        .then((contratante) => {
          this.contratante = contratante;
          this.getEstipulante();
        });
    },
    getEstipulante() {
      // console.log(`this.contratante: ${JSON.stringify(this.contratante)}`);
      this.$store
        .dispatch('estipulante/one', this.contratante.estipulanteId)
        .then((estipulante) => {
          this.estipulante = estipulante;
        }).finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
