<template>
  <v-container>
    <v-progress-linear indeterminate :active="loading" />
    <v-data-table
      :headers="headers"
      :items="invoices"
      :options.sync="options"
      :server-items-length="totalRecords"
      item-key="id"
      sort-by="id"
      class="elevation-1"
      :loading="loadingGrid"
    >
      <template v-slot:top>
        <v-container fluid class="pa-0 ma-0 py-4 pb-6">
          <v-row class="pa-0 ma-0">
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-input-text
                v-model="filter.name" name="invfltr_fltr_name" label="Contratante"
                @input="changeFilterName"
              />
            </v-col>
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-date
                v-model="filter.dateStart"
                name="invfltr_fltr_date_start"
                label="Data Inicial"
                :max="filter.dateEnd"
                @input="changeFilter"
              />
            </v-col>
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-date
                v-model="filter.dateEnd"
                name="invfltr_fltr_date_end"
                label="Data Final"
                rules="dateAfter:@invfltr_fltr_date_end"
                :min="filter.dateStart"
                @input="changeFilter"
              />
            </v-col>
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-select
                v-model="filter.status" :items="statusItens"
                item-text="txt" item-value="vl" name="invfltr_fltr_status" label="Status"
                rules="required" @input="changeFilter"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:[`item.document`]="{ item }">
        {{ item.document | document }}
      </template>

      <template v-slot:[`item.value`]="{ item }">
        {{ item.value | centsToReal }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>
                {{ item.status | invoiceStatusIcon }}
              </v-icon>
            </v-btn>
          </template>
          <span>
            {{ $options.filters.invoiceStatus(item.status) }}
            {{ (item.status == 'payment_failed') ? `- ${item.paymentError}` : '' }}
          </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.bankSlipStatus`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>
                {{ item.bankSlipStatus | bankSlipStatusIcon }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $options.filters.bankSlipStatus(item.bankSlipStatus) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.duplicate`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>{{ $options.filters.invoiceDuplicateIcon(item.duplicate) }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $options.filters.invoiceDuplicate(item.duplicate) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.dueDate`]="{ item }">
        {{ item.dueDate | date }}
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAt | dateTime }}
      </template>

      <template v-slot:[`item.paidIn`]="{ item }">
        {{ item.paidIn | dateTime }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon @click="openInvoice(item)">mdi-receipt</v-icon>
            </v-btn>
          </template>
          <span>Abrir Fatura</span>
        </v-tooltip>

        <v-tooltip left v-if="item.status == 'pending' || item.status == 'expired'">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" :loading="loadingGrid">
              <v-icon @click="confirmGenDuplicate = true; toDuplicate = item">
                mdi-file-refresh-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Gerar Segunda Via</span>
        </v-tooltip>

        <v-tooltip left v-if="item.status == 'pending' || item.status == 'expired'">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" :loading="loadingGrid">
              <v-icon @click="confirmCancel = true; toCancel = item">
                mdi-cancel
              </v-icon>
            </v-btn>
          </template>
          <span>Cancelar fatura</span>
        </v-tooltip>
      </template>

    </v-data-table>

    <confirmation-dialog
      v-model="confirmGenDuplicate" title="Confirmação"
      @confirm="generateDuplicate" @cancel="toDuplicate = null">
      <div>
        Confirma a geração da 2ª via?
      </div>
    </confirmation-dialog>

    <confirmation-dialog
      v-model="confirmCancel" title="Confirmação"
      @confirm="cancelInvoice" @cancel="toCancel = null">
      <div>
        Confirma o cancelamento da fatura?
      </div>
    </confirmation-dialog>

  </v-container>
</template>

<script>
import bus from '@/plugins/eventBus';
import _ from 'lodash';
import MbDate from '@/components/general/inputs/MbDate';
import MbSelect from '@/components/general/inputs/MbSelect';
import MbInputText from '@/components/general/inputs/MbInputText';
import ConfirmationDialog from '@/components/general/ConfirmationDialog';

export default {
  components: {
    MbDate,
    MbSelect,
    MbInputText,
    ConfirmationDialog,
  },
  data: () => ({
    user: null,
    companyId: null,
    totalRecords: 0,
    headers: [
      // {
      //   text: 'ID', align: 'center', sortable: false, value: 'id',
      // },
      { text: 'Contratante', value: 'name' },
      { text: 'Documento', value: 'document' },
      {
        text: 'N. Vidas', align: 'center', sortable: false, value: 'beneficiaries',
      },
      { text: 'Valor', value: 'value' },
      { text: 'Status', value: 'status' },
      { text: 'Status Boleto', value: 'bankSlipStatus' },
      { text: 'É 2ª Via', value: 'duplicate' },
      { text: 'Dt. Vencimento', value: 'dueDate' },
      { text: 'Dt. Criação', value: 'createdAt' },
      { text: 'Dt. Pagamento', value: 'paidIn' },
      { text: 'Ações', value: 'actions' },
    ],
    options: {},
    invoices: [],
    statusItens: [],
    filter: {
      dateStart: null,
      dateEnd: null,
      status: 'all',
      name: null,
    },
    loading: false,
    loadingGrid: false,
    confirmGenDuplicate: false,
    toDuplicate: null,
    confirmCancel: false,
    toCancel: null,
  }),
  watch: {
    options: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
  },
  created() {
    this.user = this.$store.getters['user/getUser'];
    if (this.user && this.user.company) {
      this.companyId = this.user.company.id;
    }

    this.statusItens = [
      { txt: 'Todos', vl: 'all' },
      { txt: this.$options.filters.invoiceStatus('pending'), vl: 'pending' },
      { txt: this.$options.filters.invoiceStatus('paid'), vl: 'paid' },
      { txt: this.$options.filters.invoiceStatus('canceled'), vl: 'canceled' },
      { txt: this.$options.filters.invoiceStatus('partially_paid'), vl: 'partially_paid' },
      { txt: this.$options.filters.invoiceStatus('refunded'), vl: 'refunded' },
      { txt: this.$options.filters.invoiceStatus('expired'), vl: 'expired' },
      { txt: this.$options.filters.invoiceStatus('authorized'), vl: 'authorized' },
      { txt: this.$options.filters.invoiceStatus('in_protest'), vl: 'in_protest' },
      { txt: this.$options.filters.invoiceStatus('chargeback'), vl: 'chargeback' },
      { txt: this.$options.filters.invoiceStatus('released'), vl: 'released' },
      { txt: this.$options.filters.invoiceStatus('payment_failed'), vl: 'payment_failed' },
    ];
  },
  methods: {
    getAll() {
      this.loading = true;
      this.$store.dispatch('invoice/all', {
        companyId: this.companyId,
        pagination: { page: this.options.page, size: this.options.itemsPerPage },
        filter: this.filter,
      }).then((resp) => {
        this.totalRecords = resp.meta.total;
        this.invoices = resp.data;
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    changeFilter() {
      this.getAll();
    },
    openInvoice(item) {
      window.open(item.pdf || item.url);
    },
    generateDuplicate() {
      this.loadingGrid = true;
      this.$store.dispatch('invoice/duplicate', this.toDuplicate.id)
        .then(() => {
          bus.$emit('message', {
            message: 'Segunda via gerada com sucesso!',
            type: 'success',
          });
          this.getAll();
        }).catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.loadingGrid = false;
        });
    },
    cancelInvoice() {
      this.loadingGrid = true;
      this.$store.dispatch('invoice/cancel', this.toCancel.id)
        .then(() => {
          bus.$emit('message', {
            message: 'Fatura cancelada com sucesso!',
            type: 'success',
          });
          this.getAll();
        }).catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.loadingGrid = false;
        });
    },
    changeFilterName: _.debounce(function () {
      // if ((vl && vl.length > 2) || (!vl || vl.length === 0)) {
      this.getAll();
      // }
    }, 700),
  },
};
</script>
