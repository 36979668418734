<template>
  <v-container>
    <v-card class="elevation-1">
      <v-card-title>
        <span v-if="contratante" class="headline mb-0">
          Configurações da Contratante {{ contratante.name }}
        </span>
        <span v-else class="headline mb-0">
          Configurações do Contratante
        </span>
      </v-card-title>
      <v-card-text>
        <v-progress-linear indeterminate :active="loading" />
        <v-row v-if="contratante">
          <!-- && contratante.subestipulanteId == null -->
          <v-col cols="12">Vincular á sindicato?</v-col>
          <v-col
            xl="3" lg="3" md="3" sm="3"
            cols="12">
            <mb-select
              class="mt-4" clearable
              v-model="subestipulanteId"
              :items="subestipulantes" name="sbscrpt_subestipulante"
              itemText="name" itemValue="id" label="Sindicato"
            />
          </v-col>
          <v-col
            xl="2" lg="2" md="2" sm="2"
            cols="12">
            <mb-text-button
              name="cc_value_update" label="Alterar Sindicato"
              :loading="alterSubestipulanteLoading"
              @click="alterSubestipulante"
            />
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
        </v-row>
        <!-- Contract - Download/Upload -->
        <v-row class="pa-0 ma-0 my-6" v-if="configuration">
          <!-- Download -->
          <v-col
            xl="2" lg="2" md="2" sm="2"
            cols="12" class="pa-0 ma-0 pl-4 pt-2"
          >
            <mb-text-button
              name="cc_value_oepnContract" label="Abrir Contrato"
              icon="mdi-text-box-outline"
              :disabled="!configuration || configuration.contractFile == null"
              :loading="downloadContractLoading"
              @click="openContract"
            />
          </v-col>
          <!-- Upload -->
          <v-col
            xl="2" lg="2" md="2" sm="2"
            cols="12" class="pa-0 ma-0 pl-4 pt-2"
          >
            <mb-text-button
              name="cc_value_upload" label="Upload do Contrato"
              icon="mdi-upload"
              @click="uploadContract"
            />
          </v-col>
        </v-row>

        <v-divider />

        <v-row class="pa-0 ma-0" v-if="configuration">
          <v-col
            xl="5" lg="5" md="5" sm="5"
            cols="12" class="mr-4"
          >
            <v-switch
              v-model="configuration.buyPhysicalCard" label="Compra carteirinha física?"/>
          </v-col>
        </v-row>

        <v-row class="pa-0 ma-0" v-if="configuration">
          <v-col
            xl="5" lg="5" md="5" sm="5"
            cols="12" class="mr-4"
          >
            <v-switch
              v-model="configuration.allowsDependent" label="Permitido inclusão de dependente?"/>
          </v-col>
        </v-row>

        <v-divider />

        <!-- Verified -->
        <v-row class="pa-0 ma-0" v-if="configuration">
          <!-- Verified -->
          <v-col
            xl="1" lg="2" md="3" sm="3"
            cols="12"
          >
            <v-switch
              v-model="configuration.verified"
              label="Verificado"
              :disabled="!user || user.type != 'medibook'"
              ></v-switch>
          </v-col>
          <v-col
              xl="2" lg="3" md="4" sm="4"
              cols="12" class="mt-2"
            >
              <mb-select
                v-model="configuration.dueDay"
                :items="[5, 20]"
                name="cc_value_dueDay"
                label="Dia Vencimento"
                :hideDetails="false"
                hint="Atenção!! Não poderá ser alterado depois!"
                :persistentHint="true"
                :disabled="oldDueDay != null"
              />
            </v-col>
        </v-row>

        <!-- Buttons -->
        <v-row class="pa-0 ma-0">
          <v-col
            xl="2" lg="2" md="2" sm="2"
            cols="12" class="pa-0 ma-0 pl-4 pt-2"
          >
            <mb-text-button
              name="cc_value_update" label="Salvar"
              :loading="updateLoading"
              @click="update"
            />
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import bus from '@/plugins/eventBus';
import MbTextButton from '@/components/general/buttons/MbTextButton';
import MbSelect from '@/components/general/inputs/MbSelect';

const Hashids = require('hashids');

export default {
  components: {
    // MbInputMoney,
    MbTextButton,
    MbSelect,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    hashids: null,
    configuration: null,
    contratante: null,
    defaultConfiguration: {
      contratanteId: null,
      verified: 0,
      allowsDependent: 0,
      buyPhysicalCard: 0,
      dueDay: 0,
    },
    subestipulantes: [],
    subestipulanteId: null,
    downloadContractLoading: false,
    updateLoading: false,
    alterSubestipulanteLoading: false,
  }),
  watch: {
    value(vl) {
      this.configuration = vl || JSON.parse(JSON.stringify(this.defaultConfiguration));
      this.oldDueDay = this.configuration.dueDay;
      this.getContratante();
    },
  },
  created() {
    this.user = this.$store.getters['user/getUser'];

    // eslint-disable-next-line new-cap
    this.hashids = new Hashids.default(
      process.env.VUE_APP_HASHID_SALT, Number(process.env.VUE_APP_HASHID_PAD),
    );
  },
  methods: {
    getContratante() {
      if (this.configuration && this.configuration.id) {
        this.loading = true;
        this.$store.dispatch('contratante/one', this.configuration.id)
          .then((contratante) => {
            this.contratante = contratante;
            this.getSubestipulantes();
          }).catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          }).finally(() => {
            this.loading = false;
          });
      }
    },
    openContract() {
      this.downloadContractLoading = true;
      this.$store.dispatch('contratanteConfiguration/downloadContract', {
        contratanteId: this.contratante.id,
      }).then((resp) => {
        window.open(resp);
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.downloadContractLoading = false;
      });
    },
    uploadContract() {
      this.$router.push({
        name: 'contratante-contract-upload',
        query: {
          id: this.hashids.encode(this.contratante.id),
        },
      });
    },
    getSubestipulantes() {
      if (this.estipulante !== null) {
        this.loading = true;
        this.$store.dispatch('subestipulante/all', {
          id: this.contratante.estipulanteId,
          pagination: { page: 1, size: -1 },
        }).then((resp) => {
          this.subestipulantes = resp.data;
          this.subestipulanteId = this.contratante.subestipulanteId;
        }).catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    alterSubestipulante() {
      this.alterSubestipulanteLoading = true;
      // console.log(`contratante: ${JSON.stringify(this.contratante)}`);
      // console.log(`subestipulante: ${JSON.stringify(this.subestipulante)}`);
      this.$store.dispatch('contratante/alterSubestipulante', {
        id: this.contratante.id,
        subestipulanteId: this.subestipulanteId,
      }).then(() => {
        bus.$emit('message', {
          message: 'Sindicato alterado com sucesso!',
          type: 'success',
        });
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.alterSubestipulanteLoading = false;
      });
    },
    update() {
      this.updateLoading = true;
      this.$store.dispatch('contratanteConfiguration/update', this.configuration).then((c) => {
        this.configuration = c;
        this.oldDueDay = this.configuration.dueDay;
        bus.$emit('message', {
          message: 'Configuração salva com sucesso!',
          type: 'success',
        });
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.updateLoading = false;
      });
    },
  },
};
</script>
