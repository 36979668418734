<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="name"
    :name="label"
    :mode="validationMode"
    :rules="rules"
  >
    <v-text-field
      :id="name"
      :ref="name"
      v-model="percentOrValue"
      autocomplete="off"
      :label="label"
      :hint="hint"
      :persistent-hint="persistentHint"
      :placeholder="placeholder"
      :prefix="type"
      type="text"
      :loading="loading"
      :disabled="disabled"
      outlined dense

      :error="errors.length > 0"
      :error-messages="errors[0]"
      :append-icon="(type === '%') ? 'mdi-currency-usd' : 'mdi-percent'"

      @input="input"
      @click:append="changeType"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: [Object],
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    validationMode: {
      type: String,
      default: 'lazy',
    },
    hint: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    appendicon: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: null,
    },
  },
  data: () => ({
    type: '%',
    percentOrValue: null,
  }),
  watch: {
    value(vl) {
      if (vl !== null && typeof vl === 'object') {
        this.percentOrValue = (Number(vl.value) / 100).toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 });
        this.type = vl.type;
      }
    },
  },
  created() {
    if (this.value !== null && typeof this.value === 'object') {
      this.type = this.value.type;
      this.percentOrValue = (Number(this.value.value) / 100).toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 });
    }
  },
  methods: {
    input() {
      if (this.percentOrValue.replace(',', '') !== Number(this.percentOrValue.replace(',', ''))) {
        this.$emit('input', {
          type: this.type,
          value: (Number(this.percentOrValue.replace(',', '').replace('.', ''))),
        });
      }
    },
    changeType() {
      if (this.type === '%') {
        this.type = 'R$';
      } else {
        this.type = '%';
      }

      this.$emit('input', {
        type: this.type,
        value: (Number(this.percentOrValue.replace(',', '').replace('.', ''))),
      });
    },
  },
};
</script>
