import { pg } from '../utils/query';
import HTTP from './axios';

const BrokerService = {
  all: (parameters) => HTTP.get(`/broker?${pg(parameters)}`).then((response) => response.data),
  one: (id) => HTTP.get(`/broker/${id}`).then((response) => response.data),
  create: (broker) => HTTP.post('/broker', broker)
    .then((response) => response.data),
  update: (broker) => HTTP.put(`/broker/${broker.id}`, broker)
    .then((response) => response.data),
};

export default BrokerService;
