<template>
  <v-container>
    <div v-if="user">
      <iframe
        :src="url" title="description" class="mb-iframe"
        height="450"
      />
    </div>
  </v-container>
</template>

<script>
import bus from '@/plugins/eventBus';

export default {
  components: {
  },
  data: () => ({
    user: null,
    loading: false,
  }),
  computed: {
    url() {
      return `${process.env.VUE_APP_PATH}/payment.html?path=${process.env.VUE_APP_API_PATH}/paymentMethod/create`;
      // ?clientId=${this.user.client.id}
    },
  },
  created() {
    window.removeEventListener('message', this.handleEvent);
    window.addEventListener('message', this.handleEvent, false);
    this.user = this.$store.getters['user/getUser'];
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleEvent);
  },
  methods: {
    handleEvent(ev) {
      if (ev.origin === `${process.env.VUE_APP_PATH}`
        && typeof ev.data === 'object' && ev.data.ev === 'created') {
        this.create(ev.data.obj);
      }
    },
    create(obj) {
      this.loading = true;

      const paymentMethod = {
        description: obj.description,
        token: obj.id,
        default: obj.default,
      };

      this.$store
        .dispatch('paymentMethod/create', paymentMethod)
        .then((newPaymentMethod) => {
          bus.$emit('message', {
            message: 'Método de pagamento criado com sucesso!',
            type: 'success',
          });

          this.$emit('card-created', newPaymentMethod);
        })
        .catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        })
        .finally(async () => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
  .mb-iframe {
    border-width: 0px;
    padding: 0px;
    margin: 0px;
  }
</style>
