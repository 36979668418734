import Vue from 'vue';
import ViaCep from 'vue-viacep';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import './plugins/validate';
import './plugins/moment';
import './utils/filters';
import App from './App';

Vue.config.productionTip = false;

Vue.use(ViaCep);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
