import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';
import { getRoutes, routesCommon } from './routes';

Vue.use(Router);

const renew = async (router) => {
  const needRenew = store.getters['user/needRenew'];

  if (!needRenew) return;

  try {
    const resp = await store.dispatch('user/renew');

    if (resp) {
      router.addRoutes(getRoutes(resp.user.loginType), { replace: true });
    }
  } catch (err) {
    await store.dispatch('user/logout');
    // eslint-disable-next-line no-use-before-define
    resetRouter();
  }
};

const createRouter = () => {
  const rt = new Router({
    mode: 'history',
    routes: routesCommon,
  });

  renew(rt);

  rt.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters['user/isAuthenticated'];
    // console.log(`isAuthenticated: ${isAuthenticated}`);

    // // const user = store.getters['user/getUser'];

    // const needRenew = store.getters['user/needRenew'];
    // console.log(`needRenew: ${needRenew}`);

    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

    // if (!isAuthenticated && needRenew) {
    //   next({ path: '/home' });
    // } else

    if (isAuthenticated && (to.path.includes('login') || to.path.includes('start'))) {
      next({ path: '/home' });
    } else if (!isAuthenticated && requiresAuth) {
      next({ path: '/start' });
    } else {
      next();
    }
  });

  // router.afterEach((to, from, next) => {
  //   console.log('afterEach');
  //   console.log(to.params);
  // });

  return rt;
};

const router = createRouter();

const resetRouter = () => {
  router.matcher = createRouter(false).matcher;
  router.options.routes = routesCommon;
};

export default router;

export { createRouter, resetRouter };
