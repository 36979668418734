<template>
  <v-container>
    <v-progress-linear indeterminate :active="loading" />
    <v-data-table
      :headers="headers"
      :items="adhesions"
      :options.sync="options"
      :server-items-length="total"
      item-key="id"
      sort-by="id"
      class="elevation-1"
    >

      <template v-slot:top>
        <v-container fluid class="pa-0 ma-0 py-4 pb-6">
          <v-row class="pa-0 ma-0">
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-date
                v-model="filter.dateStart"
                name="invfltr_fltr_date_start"
                label="Data Inicial"
                :max="filter.dateEnd"
                @input="changeFilter"
              />
            </v-col>
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-date
                v-model="filter.dateEnd"
                name="invfltr_fltr_date_end"
                label="Data Final"
                rules="dateAfter:@invfltr_fltr_date_end"
                :min="filter.dateStart"
                @input="changeFilter"
              />
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0 ml-5">
            <v-col>
              <v-btn
                color="primary" dark class="mb-2" :loading="exportLoading"
                @click="exportToExcel"
              >
                Exportar
                <v-icon right dark>
                  mdi-microsoft-excel
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:[`item.Documento`]="{ item }">
        {{ item.Documento | document }}
      </template>

      <template v-slot:[`item.Tipo`]="{ item }">
        <div class="mb-upper">{{ item.Tipo }}</div>
      </template>

      <template v-slot:[`item.Valor`]="{ item }">
        {{ item.Valor | centsToReal }}
      </template>

    </v-data-table>
  </v-container>
</template>

<script>
import bus from '@/plugins/eventBus';
import MbDate from '@/components/general/inputs/MbDate';

export default {
  components: {
    MbDate,
  },
  data: () => ({
    Hashids: null,
    total: 0,
    edit: false,
    headers: [
      { text: 'Estipulante', value: 'Estipulante' },
      { text: 'Subestipulante', value: 'Subestipulante' },
      {
        text: 'Contratante', align: 'start', sortable: false, value: 'Contratante',
      },
      { text: 'Documento', value: 'Documento' },
      { text: 'Tipo', value: 'Tipo' },
      { text: 'Valor', value: 'Valor' },
    ],
    options: {
      itemsPerPage: 10,
    },
    filter: {
      dateStart: null,
      dateEnd: null,
    },
    adhesions: [],
    loading: false,
    exportLoading: false,
  }),
  created() {
  },
  watch: {
    options: {
      handler() {
        if (this.options.page != null && this.options.itemsPerPage != null) {
          this.getCards();
        }
      },
      deep: true,
    },
  },
  methods: {
    getCards() {
      this.loading = true;

      const params = {};
      params.pagination = { page: this.options.page, size: this.options.itemsPerPage };
      params.filter = this.filter;

      this.$store.dispatch('report/adhesions', params).then((resp) => {
        if (resp) {
          this.total = resp.meta.total;
          this.adhesions = resp.data;
        }
      }).catch((err) => {
        console.log(err);
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    changeFilter() {
      this.getPendents();
    },
    exportToExcel() {
      this.exportLoading = true;

      const params = {};
      params.filter = this.filter;

      this.$store.dispatch('report/exportAdhesionsExcel', params).then((resp) => {
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${resp}`;
        const downloadLink = document.createElement('a');
        const fileName = `adesoes-${this.$moment().format('DDMMYY_HH-mm-ss')}.xlsx`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.exportLoading = false;
      });
    },
  },
};
</script>

<style scoped>
  .mb-upper {
    text-transform: uppercase
  }
</style>
