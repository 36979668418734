<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="data"
      :items-per-page="5"
      item-key="corretora"
      sort-by="corretora"
      class="elevation-1"
    >

      <template v-slot:[`item.corretora`]="{ item }">
        {{ (item.corretora) ? item.corretora: '-' }}
      </template>

      <template v-slot:[`item.receivedVal`]="{ item }">
        ({{ item.receivedQtd }}) {{ item.receivedVal }}
      </template>

      <template v-slot:[`item.defaultingsVal`]="{ item }">
        ({{ item.defaultingsQtd }}) {{ item.defaultingsVal }}
      </template>

    </v-data-table>

  <!-- <v-card>
    <v-card-subtitle class="font-weight-bold">
      Corretora: {{ (data.corretora) ? data.corretora : 'Nenhuma'}}
    </v-card-subtitle>
    <v-card-text>
      <v-container class="pa-0 ma-0 text-center">
        <v-row dense>
          <v-col>Contratantes</v-col>
          <v-col>{{ data.allContratante }}</v-col>
        </v-row>
        <v-row dense>
          <v-col>Previstos</v-col>
          <v-col>{{ data.predictedVal }}</v-col>
        </v-row>
        <v-row dense>
          <v-col>Recebidos</v-col>
          <v-col>({{ data.receivedQtd }}) {{ data.receivedVal }}</v-col>
        </v-row>
        <v-row dense>
          <v-col>Inadimplentes</v-col>
          <v-col>({{ data.defaultingsQtd }}) {{ data.defaultingsVal }}</v-col>
        </v-row>
        <v-row dense>
          <v-col>Estipulante</v-col>
          <v-col>{{ data.estipulanteCom }}</v-col>
        </v-row>
        <v-row dense>
          <v-col>Subestipulante</v-col>
          <v-col>{{ data.subestipulanteCom }}</v-col>
        </v-row>
        <v-row dense>
          <v-col>Corretora</v-col>
          <v-col>{{ data.brokerCom }}</v-col>
        </v-row>
        <v-row dense>
          <v-col>APSS</v-col>
          <v-col>{{ data.apssCom }}</v-col>
        </v-row>
        <v-row dense>
          <v-col>Proplast</v-col>
          <v-col>{{ data.proplastCom }}</v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card> -->
  </v-container>
</template>

<script>

export default {
  components: {
  },
  props: {
    data: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    headers: [
      {
        text: 'Corretora', align: 'start', sortable: false, value: 'corretora',
      },
      { text: 'Contratantes', align: 'center', value: 'allContratante' },
      { text: 'Previsto', value: 'predictedVal' },
      { text: 'Recebido', value: 'receivedVal' },
      { text: 'Inadimplente', value: 'defaultingsVal' },
      // { text: 'Estipulante', value: 'estipulanteCom' },
      // { text: 'Subestipulante', value: 'subestipulanteCom' },
      { text: 'Comissão', value: 'brokerCom' },
      // { text: 'APSS', value: 'apssCom' },
      // { text: 'Proplast', value: 'proplastCom' },
    ],
  }),
  created() {
  },
};
</script>
