import CompanyEconocardService from '@/services/companyEconocard.service';

const companiesEco = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    one(_, id) {
      return CompanyEconocardService.one(id);
    },
    verifyByCNPJ(_, cnpj) {
      return CompanyEconocardService.verifyByCNPJ(cnpj);
    },
  },

  getters: {},
};

export default companiesEco;
