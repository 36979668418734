var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-progress-linear',{attrs:{"indeterminate":"","active":_vm.loading}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.brokers,"options":_vm.options,"server-items-length":_vm.total,"item-key":"id","sort-by":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{staticClass:"pa-0 ma-0 py-4 pb-6",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0 pl-4 pt-2",attrs:{"xl":"2","lg":"2","md":"2","sm":"2","cols":"12"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.handleNew}},[_vm._v(" Novo ")])],1)],1)],1)]},proxy:true},{key:"item.document",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("document")(item.document))+" ")]}},{key:"item.mobile",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("phone")(item.mobile))+" ")]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("phone")(item.phone))+" ")]}},{key:"item.paymentAccountStatus",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm._f("accountStatusIcon")(item.paymentAccountStatus))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$options.filters.accountStatus(item.paymentAccountStatus)))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{on:{"click":function($event){return _vm.handleEdit(item)}}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{on:{"click":function($event){return _vm.handleBank(item)}}},[_vm._v("mdi-account-cash")])],1)]}}],null,true)},[_c('span',[_vm._v("Dados Bancários")])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }