import { pg, filter } from '../utils/query';
import HTTP from './axios';

const ReportService = {
  // commissions: (estipulanteId) => HTTP.get(`/report/commissions?estipulanteId=${estipulanteId}`)
  //   .then((response) => response.data),
  totals: (p) => HTTP.get(
    `/report/commissions/totals?estipulanteId=${p.estipulanteId}&dateStart=${p.dateStart}&dateEnd=${p.dateEnd}`,
  ).then((response) => response.data),
  brokers: (p) => HTTP.get(
    `/report/commissions/brokers?estipulanteId=${p.estipulanteId}&dateStart=${p.dateStart}&dateEnd=${p.dateEnd}`,
  ).then((response) => response.data),
  subestipulantes: (p) => HTTP.get(
    `/report/commissions/subestipulantes?estipulanteId=${p.estipulanteId}&dateStart=${p.dateStart}&dateEnd=${p.dateEnd}`,
  ).then((response) => response.data),
  exportExcel: (p) => HTTP.get(`/report/commissions/excel?estipulanteId=${p.estipulanteId}&dateStart=${p.dateStart}&dateEnd=${p.dateEnd}`).then((response) => response.data),

  cards: (p) => HTTP.get(`/report/cards?${pg(p)}${filter(p)}`).then((response) => response.data),
  exportCardsExcel: (p) => HTTP.post(`/report/cards/excel?${filter(p)}`).then((response) => response.data),

  adhesions: (p) => HTTP.get(`/report/adhesions?${pg(p)}${filter(p)}`).then((response) => response.data),
  exportAdhesionsExcel: (p) => HTTP.post(`/report/adhesions/excel?${filter(p)}`).then((response) => response.data),
};

export default ReportService;
