var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-progress-linear',{attrs:{"indeterminate":"","active":_vm.loading}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.invoices,"options":_vm.options,"server-items-length":_vm.totalRecords,"item-key":"id","sort-by":"id","loading":_vm.loadingGrid},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{staticClass:"pa-0 ma-0 py-4 pb-6",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0 pl-4 pt-2",attrs:{"xl":"3","lg":"3","md":"3","sm":"3","cols":"12"}},[_c('mb-date',{attrs:{"name":"invfltr_fltr_date_start","label":"Data Inicial","max":_vm.filter.dateEnd},on:{"input":_vm.changeFilter},model:{value:(_vm.filter.dateStart),callback:function ($$v) {_vm.$set(_vm.filter, "dateStart", $$v)},expression:"filter.dateStart"}})],1),_c('v-col',{staticClass:"pa-0 ma-0 pl-4 pt-2",attrs:{"xl":"3","lg":"3","md":"3","sm":"3","cols":"12"}},[_c('mb-date',{attrs:{"name":"invfltr_fltr_date_end","label":"Data Final","rules":"dateAfter:@invfltr_fltr_date_end","min":_vm.filter.dateStart},on:{"input":_vm.changeFilter},model:{value:(_vm.filter.dateEnd),callback:function ($$v) {_vm.$set(_vm.filter, "dateEnd", $$v)},expression:"filter.dateEnd"}})],1),_c('v-col',{staticClass:"pa-0 ma-0 pl-4 pt-2",attrs:{"xl":"3","lg":"3","md":"3","sm":"3","cols":"12"}},[_c('mb-select',{attrs:{"items":_vm.statusItens,"item-text":"txt","item-value":"vl","name":"invfltr_fltr_status","label":"Status","rules":"required"},on:{"input":_vm.changeFilter},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}})],1)],1)],1)]},proxy:true},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("centsToReal")(item.value))+" ")]}},{key:"item.totalValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("centsToReal")(item.totalValue))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm._f("invoiceStatusIcon")(item.status))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$options.filters.invoiceStatus(item.status))+" "+_vm._s((item.status == 'payment_failed') ? ("- " + (item.paymentError)) : '')+" ")])])]}},{key:"item.bankSlipStatus",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm._f("bankSlipStatusIcon")(item.bankSlipStatus))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$options.filters.bankSlipStatus(item.bankSlipStatus)))])])]}},{key:"item.duplicate",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.$options.filters.invoiceDuplicateIcon(item.duplicate)))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$options.filters.invoiceDuplicate(item.duplicate)))])])]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.dueDate))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.createdAt))+" ")]}},{key:"item.paidIn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.paidIn))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{on:{"click":function($event){return _vm.openInvoice(item)}}},[_vm._v("mdi-receipt")])],1)]}}],null,true)},[_c('span',[_vm._v("Abrir Fatura")])]),(item.status == 'pending' || item.status == 'expired')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","loading":_vm.loadingGrid}},on),[_c('v-icon',{on:{"click":function($event){_vm.confirmGenDuplicate = true; _vm.toDuplicate = item}}},[_vm._v(" mdi-file-refresh-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Gerar Segunda Via")])]):_vm._e()]}}],null,true)}),_c('confirmation-dialog',{attrs:{"title":"Confirmação"},on:{"confirm":_vm.generateDuplicate,"cancel":function($event){_vm.toDuplicate = null}},model:{value:(_vm.confirmGenDuplicate),callback:function ($$v) {_vm.confirmGenDuplicate=$$v},expression:"confirmGenDuplicate"}},[_c('div',[_vm._v(" Confirma a geração da 2ª via? ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }