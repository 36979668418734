<template>
  <v-container>
    <v-row class="mb-5">
      <v-col cols="12" class="pa-0 ma-0">
        <beneficiary-import />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BeneficiaryImport from '@/components/beneficiary/BeneficiaryImport';

export default {
  components: {
    BeneficiaryImport,
  },
  data: () => ({
  }),
  created() {
  },
  methods: {

  },
};
</script>
