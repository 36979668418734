<template>
  <v-container>
    <v-progress-linear indeterminate :active="loading" />

    <v-data-table
      :headers="headers"
      :items="commissions"
      item-key="id"
      sort-by="id"
      class="elevation-0"
    >
      <template v-slot:top>
        <v-container fluid class="pa-0 ma-0 py-4 pb-6">
          <v-row class="pa-0 ma-0">
            <v-col
              xl="2" lg="2" md="2" sm="2"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <v-btn
                color="primary"
                :disabled="estipulanteId == null"
                @click="handleAdd"
              >
                Adicionar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:[`item.fixed`]="{ item }">
        {{ (item.fixed) ? 'Valor Fixo' : 'Faixa de Vidas' }}
      </template>

      <template v-slot:[`item.value`]="{ item }">
        {{ (item.valueType == '%') ? item.valueType : 'R$' }} {{ item.value | valueToDecimal }}
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>{{ $options.filters.activeIcon(item.active) }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $options.filters.active(item.active) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.personType`]="{ item }">
        {{$options.filters.personType(item.personType) }}
      </template>

      <template v-slot:[`item.syndicate`]="{ item }">
        {{$options.filters.syndicate(item.syndicate) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon @click="handleEdit(item)">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="500">
      <commission-cad
        v-model="commissionToEdit" :type="type" :estipulanteId="estipulanteId"
        @changed="handleChanged" @canceled="handleCanceled" />
        <!-- :hasFixedBound="countFixedBound > 0" :hasFixedWithoutBound="countFixedWithoutBound > 0"
        :hasDynamic="countDynamic > 0" -->
    </v-dialog>

  </v-container>
</template>

<script>
import bus from '@/plugins/eventBus';
import CommissionCad from '@/components/commissions/CommissionCad';

export default {
  components: {
    CommissionCad,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    estipulanteId: {
      type: [Number, String],
    },
    active: {
      type: [Number, Boolean],
      default: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      commissions: [],
      commission: null,
      commissionToEdit: null,
      addBtnLoading: false,
      headers: [
        {
          text: 'Tipo de Regra', sortable: false, value: 'fixed',
        },
        { text: 'N. Inicial de Vidas', value: 'startNumLifes' },
        { text: 'N. Final de Vidas', value: 'endNumLifes' },
        { text: 'Valor p/ Vida', value: 'value' },
        { text: 'PJ/PF', value: 'personType' },
        { text: 'Vínculo', value: 'syndicate' },
        { text: 'Ativo?', value: 'active' },
        { text: 'Ações', value: 'actions' },
      ],
    };
  },
  watch: {
    estipulanteId() {
      this.getRules();
    },
    active() {
      this.getRules();
    },
  },
  created() {
    this.getRules();
  },
  // computed: {
  //   countFixedBound() {
  //     const count = this.commissions.reduce(
  //       (n, val) => n + (val.active && val.fixed && !val.withoutBound), 0,
  //     );
  //     return count;
  //   },
  //   countFixedWithoutBound() {
  //     const count = this.commissions.reduce(
  //       (n, val) => n + (val.active && val.fixed && val.withoutBound), 0,
  //     );
  //     return count;
  //   },
  //   countDynamic() {
  //     const count = this.commissions.reduce((n, val) => n + (val.active && !val.fixed), 0);
  //     return count;
  //   },
  // },
  methods: {
    getRules() {
      if (this.estipulanteId && this.type) {
        this.loading = true;
        this.$store
          .dispatch('commission/allByEstipulante', {
            estipulanteId: this.estipulanteId,
            type: this.type,
            active: (this.active ? 1 : 0),
          })
          .then((res) => {
            this.commissions = res;
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.loading = false;
          });
      }
    },
    handleChanged() {
      this.getRules();
      this.dialog = false;
      this.commissionToEdit = null;
    },
    handleCanceled() {
      this.dialog = false;
      this.commissionToEdit = null;
    },
    handleAdd() {
      this.dialog = true;
    },
    handleEdit(item) {
      this.commissionToEdit = item;
      this.dialog = true;
    },
  },
};
</script>
