var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-progress-linear',{attrs:{"indeterminate":"","active":_vm.loading}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.cards,"options":_vm.options,"server-items-length":_vm.total,"item-key":"id","sort-by":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{staticClass:"pa-0 ma-0 py-4 pb-6",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0 pl-4 pt-2",attrs:{"xl":"3","lg":"3","md":"3","sm":"3","cols":"12"}},[_c('mb-date',{attrs:{"name":"invfltr_fltr_date_start","label":"Data Inicial","max":_vm.filter.dateEnd},on:{"input":_vm.changeFilter},model:{value:(_vm.filter.dateStart),callback:function ($$v) {_vm.$set(_vm.filter, "dateStart", $$v)},expression:"filter.dateStart"}})],1),_c('v-col',{staticClass:"pa-0 ma-0 pl-4 pt-2",attrs:{"xl":"3","lg":"3","md":"3","sm":"3","cols":"12"}},[_c('mb-date',{attrs:{"name":"invfltr_fltr_date_end","label":"Data Final","rules":"dateAfter:@invfltr_fltr_date_end","min":_vm.filter.dateStart},on:{"input":_vm.changeFilter},model:{value:(_vm.filter.dateEnd),callback:function ($$v) {_vm.$set(_vm.filter, "dateEnd", $$v)},expression:"filter.dateEnd"}})],1)],1),_c('v-row',{staticClass:"pa-0 ma-0 ml-5"},[_c('v-col',[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","loading":_vm.exportLoading},on:{"click":_vm.exportToExcel}},[_vm._v(" Exportar "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-microsoft-excel ")])],1)],1)],1)],1)]},proxy:true},{key:"item.CPFTitular",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("document")(item.CPFTitular))+" ")]}},{key:"item.NascimentoContratante",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("ISOToDate")(item.NascimentoContratante))+" ")]}},{key:"item.Documento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("document")(item.Documento))+" ")]}},{key:"item.Valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("centsToReal")(item.Valor))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }