<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="name"
    :name="label"
    :mode="validationMode"
    :rules="finalRules"
  >
    <v-text-field
      :id="name"
      :ref="name"
      v-model="internalValue"
      v-mask="'#'"
      autocomplete="off"
      :label="label"
      :hint="hint"
      :persistent-hint="persistentHint"
      :placeholder="placeholder"
      :append-icon="appendicon"
      :prefix="prefix"
      :suffix="suffix"
      type="text"
      :loading="loading"
      :disabled="disabled"
      outlined dense
      :error="errors.length > 0"
      @input="input"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mask } from 'vue-the-mask';

export default {
  directives: { mask },
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    validationMode: {
      type: String,
      default: 'lazy',
    },
    hint: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    appendicon: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: null,
    },
  },
  data: () => ({
    internalValue: '',
  }),
  computed: {
    finalRules() {
      return `${this.rules}|token|length:1`;
    },
  },
  watch: {
    value(vl) {
      this.internalValue = vl;
    },
  },
  created() {
    this.internalValue = this.value || '';
  },
  methods: {
    input() {
      this.$emit('input', this.internalValue);
    },
  },
};
</script>
