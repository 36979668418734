import EstipulanteConfigurationService from '@/services/estipulanteConfiguration.service';

const estipulanteConfiguration = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    one(_, estipulanteId) {
      return EstipulanteConfigurationService.one(estipulanteId);
    },
    update(_, contratanteConfiguration) {
      return EstipulanteConfigurationService.update(contratanteConfiguration);
    },
  },

  getters: {},
};

export default estipulanteConfiguration;
