const utils = {
  pg: (parameters) => `page=${parameters.pagination.page}&size=${parameters.pagination.size}`,
  filter: (parameters) => {
    let filter = '';

    if (!parameters.filter) return filter;

    Object.entries(parameters.filter).forEach(([key, value]) => {
      if (value && value !== '') {
        filter += `&${key}=${value}`;
      }
    });

    return filter;
  },
  sort: (parameters) => {
    let sort = '';
    if (!parameters.sort || parameters.sort.sortBy.length === 0) return sort;

    sort += `&sortBy=${parameters.sort.sortBy}&sortDesc=${parameters.sort.sortDesc}`;

    return sort;
  },
  onlyFilter: (parameters) => {
    let filter = '';

    if (!parameters.filter) return filter;

    Object.entries(parameters.filter).forEach(([key, value]) => {
      if (value && value !== '') {
        if (filter === '') {
          filter += `?${key}=${value}`;
        } else {
          filter += `&${key}=${value}`;
        }
      }
    });
    return filter;
  },
};

module.exports = utils;
