<template>
  <v-container>
    <v-app-bar app elevate-on-scroll>
      <v-img
        src="@/assets/mb-econocard.png"
        to="/"
        contain
        width="240"
        aspect-ratio="1.2"
        max-width="240"
        max-height="55"
      />

      <v-spacer />

      <v-btn
        outlined rounded color="primary" dark
        @click="login"
      >
        Acessar
        <v-icon right dark>
          mdi-arrow-right
        </v-icon>
      </v-btn>
    </v-app-bar>

    <v-row>
      <v-col class="headline text-center">
        Recuperar Senha
      </v-col>
    </v-row>

    <v-divider />

    <v-row>
      <v-col align="center">
        <recover-password-form />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RecoverPasswordForm from '@/components/recoverPassword/RecoverPasswordForm';

export default {
  components: {
    RecoverPasswordForm,
  },
  data: () => ({}),
  methods: {
    login() {
      this.$router.push({ name: this.$route.query.from });
    },
  },
};
</script>
