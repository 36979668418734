var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-progress-linear',{attrs:{"indeterminate":"","active":_vm.loading}}),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.commissions,"item-key":"id","sort-by":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{staticClass:"pa-0 ma-0 py-4 pb-6",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0 pl-4 pt-2",attrs:{"xl":"2","lg":"2","md":"2","sm":"2","cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.estipulanteId == null},on:{"click":_vm.handleAdd}},[_vm._v(" Adicionar ")])],1)],1)],1)]},proxy:true},{key:"item.fixed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.fixed) ? 'Valor Fixo' : 'Faixa de Vidas')+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.valueType == '%') ? item.valueType : 'R$')+" "+_vm._s(_vm._f("valueToDecimal")(item.value))+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.$options.filters.activeIcon(item.active)))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$options.filters.active(item.active)))])])]}},{key:"item.personType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$options.filters.personType(item.personType))+" ")]}},{key:"item.syndicate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$options.filters.syndicate(item.syndicate))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{on:{"click":function($event){return _vm.handleEdit(item)}}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('commission-cad',{attrs:{"type":_vm.type,"estipulanteId":_vm.estipulanteId},on:{"changed":_vm.handleChanged,"canceled":_vm.handleCanceled},model:{value:(_vm.commissionToEdit),callback:function ($$v) {_vm.commissionToEdit=$$v},expression:"commissionToEdit"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }