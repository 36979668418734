<template>
  <v-card class="elevation-1 pa-0 ma-0">
    <v-card-title>
      <span v-if="broker && broker.id" class="headline mb-0">
        {{ broker.name }}
      </span>
      <span v-else class="headline mb-0">Novo Corretor</span>
    </v-card-title>

    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-card-text>
        <form autocomplete="off">
          <!-- Name/Genre -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="6" lg="6" md="6"
              sm="6" cols="12" class="pa-0 ma-0 mr-4"
            >
              <mb-input-text
                v-model="broker.name"
                name="bkrs_name" label="Nome"
                rules="required|max:60"
              />
            </v-col>
            <v-col
              xl="2" lg="2" md="2"
              sm="2" cols="12" class="pa-0 ma-0 mr-4"
            >
              <mb-select
                v-model="broker.genre"
                :items="sexItems"
                itemText="text"
                itemValue="vl"
                name="bkrs_sex"
                label="Sexo"
                rules="required"
              />
            </v-col>

            <v-col
              xl="2" lg="2" md="2"
              sm="2" cols="12" class="pa-0 ma-0"
            >
              <v-switch
                v-model="broker.active" label="Ativo?"
                v-if="broker.id != null" />
            </v-col>
          </v-row>
          <!-- Email/Document -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4">
              <mb-input-text
                v-model.trim="broker.email"
                name="bkrs_email" label="Email" rules="required"
              />
            </v-col>
            <v-col
              xl="5" lg="5" md="5" sm="5"
              cols="12"
            >
              <mb-input-cpf
                v-model="broker.countryRegister"
                name="bkrs_countryRegister" label="Documento"
                rules="required"
                :disabled="broker.id != null"
              />
            </v-col>
          </v-row>
          <!-- Mobile/Phone -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 mr-4">
              <mb-input-phone
                v-model="broker.mobile"
                name="bkrs_mobile" label="Celular" rules="required|phone"
              />
            </v-col>
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0">
              <mb-input-phone
                v-model="broker.work"
                name="bkrs_work" label="Telefone"
              />
            </v-col>
          </v-row>
        </form>
      </v-card-text>

      <v-card-actions>
        <mb-text-button
          name="prd_btn_save" label="Salvar"
          :disabled="invalid"
          :loading="saveBtnLoading"
          class="ml-2 mb-2"
          @click="save"
        />
        <v-spacer />
        <mb-text-button
          name="prd_btn_cancel" label="Cancelar" :primary="false"
          class="mb-2" @click="cancel"
        />
      </v-card-actions>
    </ValidationObserver>
  </v-card>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import bus from '@/plugins/eventBus';
import MbInputText from '@/components/general/inputs/MbInputText';
import MbInputPhone from '@/components/general/inputs/MbInputPhone';
import MbInputCpf from '@/components/general/inputs/MbInputCpf';
import MbSelect from '@/components/general/inputs/MbSelect';
import MbTextButton from '@/components/general/buttons/MbTextButton';

export default {
  components: {
    ValidationObserver,
    MbInputText,
    MbInputPhone,
    MbInputCpf,
    MbSelect,
    MbTextButton,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      saveBtnLoading: false,
      brokerId: null,
      broker: null,
      defaultBroker: {
        name: '',
        genre: '',
        countryRegister: '',
        email: '',
        mobile: '',
        work: '',
        active: null,
      },
      sexItems: [
        { vl: 'female', text: 'Feminino' },
        { vl: 'male', text: 'Masculino' },
      ],
    };
  },
  computed: {
  },
  watch: {
    value(vl) {
      this.broker = vl || JSON.parse(JSON.stringify(this.defaultBroker));
      this.$refs.observer.reset();
    },
  },
  created() {
    const user = this.$store.getters['user/getUser'];
    this.brokerId = user.company.id;

    this.broker = this.value || JSON.parse(JSON.stringify(this.defaultBroker));
  },
  destroyed() {
    this.broker = JSON.parse(JSON.stringify(this.defaultBroker));
  },
  methods: {
    save() {
      if (this.broker.id) {
        this.update();
      } else {
        this.create();
      }
    },
    async create() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.saveBtnLoading = true;
        this.$store
          .dispatch('brokers/create', {
            brokerId: this.brokerId,
            broker: this.broker,
          })
          .then(() => {
            this.broker = this.defaultBroker;
            bus.$emit('message', {
              message: 'Corretor criado com sucesso!',
              type: 'success',
            });
            this.$emit('input', this.broker);
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
    async update() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.saveBtnLoading = true;
        this.$store
          .dispatch('brokers/update', this.broker)
          .then((brokerSaved) => {
            bus.$emit('message', {
              message: 'Corretor atualizado com sucesso!',
              type: 'success',
            });
            this.$emit('input', brokerSaved);
            this.$emit('updated');
            this.$emit('saved');
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
    cancel() {
      this.$emit('canceled');
    },
  },
};
</script>
