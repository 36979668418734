<template>
    <v-container class="pa-0 ma-0" v-if="value">
        <v-card color="grey lighten-5" class="pa-0 ma-0">
            <!-- dark -->
            <v-card-title class="headline pa-0 ma-0 pl-3 pr-1">
            {{ value.description }}
            <v-spacer />
            <v-img
                :src="brandImg" contain max-width="64"
                height="35" class="ma-2"
            />
            </v-card-title>

            <v-card-text clas="ma-0 pa-0">
            <div>{{ value.data.holder }}</div>
            <div>{{ value.data.display_number }}</div>
            <div>{{ value.data.month }}/{{ value.data.year }}</div>
            </v-card-text>

            <v-card-actions class="pa-0 ma-0">
            <v-spacer />
            <v-btn
              v-if="value && user" color="primary" text
              :disabled="!canRemove"
              @click="confirmRemove = true" :loading="removeLoading"
            >
              remover
            </v-btn>
            </v-card-actions>
        </v-card>

      <confirmation-dialog
        v-model="confirmRemove" title="Confirmação"
        @confirm="doRemove" v-if="value">
        <div>
          Confirma a remoção do cartão {{ this.value.data.display_number }}?
        </div>
      </confirmation-dialog>

    </v-container>
</template>

<script>
import bus from '@/plugins/eventBus';
import ConfirmationDialog from '@/components/general/ConfirmationDialog';

export default {
  components: {
    ConfirmationDialog,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    canRemove: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    removeLoading: false,
    confirmRemove: false,
    doingRemove: false,
  }),
  computed: {
    brandImg() {
      return `${process.env.VUE_APP_PATH}/icons/${this.value.data.brand.toLowerCase()}.png`;
    },
  },
  created() {
    this.user = this.$store.getters['user/getUser'];
  },
  methods: {
    doRemove() {
      this.removeLoading = true;

      this.$store
        .dispatch('paymentMethod/remove', this.value.id)
        .then(() => {
          bus.$emit('message', {
            message: 'Cartão removido com sucesso!',
            type: 'success',
          });

          this.$emit('card-removed', this.value.id);
        })
        .catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        })
        .finally(async () => {
          this.removeLoading = false;
        });
    },
  },
};
</script>
