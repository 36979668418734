<template>
  <v-container class="pa-0 ma-0">
    <v-card>
      <!-- Title -->
      <v-card-title class="headline">
        Alteração de senha
      </v-card-title>
      <!-- Body -->
      <v-card-text>
        <ValidationObserver v-if="user" ref="observer" v-slot="{ }">
          <v-row dense>
            <v-col>
              <mb-input-password
                v-model="form.password"
                name="password"
                label="Senha Atual"
                rules="required"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <mb-input-password
                v-model="form.newPassword"
                name="newPassword"
                label="Nova Senha"
                rules="required"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <mb-input-password
                v-model="form.confirmPassword"
                name="confirmPassword"
                label="Confirme a nova Senha"
                rules="required|password:newPassword"
              />
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>
      <!-- Buttons -->
      <v-card-actions>
        <v-btn text color="primary" @click="cancel">
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn color="secondary" text @click="save">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import bus from '@/plugins/eventBus';
import MbInputPassword from '@/components/general/inputs/MbInputPassword';

export default {
  components: {
    ValidationObserver,
    MbInputPassword,
  },
  data: () => ({
    form: {
      password: null,
      newPassword: null,
      confirmPassword: null,
    },
    saveBtnLoading: false,
  }),
  computed: {
    user() {
      return this.$store.getters['user/getUser'];
    },
  },
  methods: {
    async save() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.saveBtnLoading = true;

        this.$store.dispatch('user/changePassword', {
          userId: this.user.id,
          password: this.form.password,
          newPassword: this.form.newPassword,
        }).then(() => {
          bus.$emit('message', {
            message: 'Senha atualizada com sucesso!',
            type: 'success',
          });

          this.form = {
            password: null,
            newPassword: null,
            confirmPassword: null,
          };

          this.$refs.observer.reset();

          this.$emit('save');
        }).catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.saveBtnLoading = false;
        });
      }
    },
    cancel() {
      this.form = {
        password: null,
        newPassword: null,
        confirmPassword: null,
      };
      this.$refs.observer.reset();
      this.$emit('cancel');
    },
  },
};
</script>
