<template>
  <v-container>
    <v-tabs
      v-model="tab"
      fixed-tabs
      background-color="primary"
      dark
    >
      <v-tab>Geral</v-tab>
      <v-tab>Adesão</v-tab>
      <v-tab v-if="estipulante && estipulante.legalPerson == 1">
        Mensalidade PJ
      </v-tab>
      <v-tab v-if="estipulante && estipulante.naturalPerson == 1">
        Mensalidade PF
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" grow>
      <v-tab-item>
        <general-conf v-model="estipulante"/>
      </v-tab-item>
      <v-tab-item>
        <adhesion-conf v-model="estipulante"/>
      </v-tab-item>
      <v-tab-item v-if="estipulante && estipulante.legalPerson">
        <mensalidade :estipulante="estipulante" personType="pj"/>
      </v-tab-item>
      <v-tab-item v-if="estipulante && estipulante.naturalPerson">
        <mensalidade :estipulante="estipulante" personType="pf"/>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import AdhesionConf from '@/components/estipulante/AdhesionConf';
import GeneralConf from '@/components/estipulante/GeneralConf';
import Mensalidade from '@/components/estipulante/Mensalidade';

const Hashids = require('hashids');

export default {
  components: {
    GeneralConf,
    AdhesionConf,
    Mensalidade,
  },
  data: () => ({
    hashids: null,
    tab: null,
    estipulante: null,
  }),
  created() {
    // eslint-disable-next-line new-cap
    this.hashids = new Hashids.default(
      process.env.VUE_APP_HASHID_SALT, Number(process.env.VUE_APP_HASHID_PAD),
    );

    const { estipulante } = this.$route.params;
    if (estipulante && Object.entries(estipulante).length > 0) {
      this.estipulante = estipulante;
    } else if (this.$route.params.id) {
      this.get(this.hashids.decode(this.$route.params.id)[0]);
    }
  },
  watch: {
  },
  methods: {
    get(id) {
      this.$store
        .dispatch('estipulante/one', id)
        .then((res) => {
          this.estipulante = res;
        });
    },
  },
};
</script>
