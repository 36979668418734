import { pg, filter } from '../utils/query';
import HTTP from './axios';

const InvoiceService = {
  all: (p) => HTTP.get(`/invoice?${pg(p)}${filter(p)}`).then((response) => response.data),
  allByCompanyType: (p) => HTTP.get(`/invoice/type?companyId=${p.companyId}&${pg(p)}${filter(p)}`).then((response) => response.data),
  duplicate: (id) => HTTP.post(`/invoice/${id}/duplicate`).then((response) => response.data),
  cancel: (id) => HTTP.post(`/invoice/${id}/cancel`).then((response) => response.data),
};

export default InvoiceService;
