<template>
  <v-container class="pa-0 ma-0">

    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-card-text>
        <form autocomplete="off">
          <!-- Document -->
          <v-row dense>
            <v-col
              xl="3" lg="4" md="6" sm="6"
              cols="12"
            >
              <v-radio-group v-model="contratante.type" row :disabled="contratante.id != null">
                <v-radio label="Pessoa Jurídica" value="pj" />
                <v-radio label="Pessoa Física" value="pf" />
              </v-radio-group>
            </v-col>
            <v-col
              v-if="contratante.type == 'pj'"
              xl="2" lg="3" md="4" sm="4"
              cols="12"
            >
              <mb-input-cnpj
                v-model="contratante.document"
                name="cpny_cnpj" label="CNPJ"
                rules="required"
                :disabled="contratante.id != null"
              />
            </v-col>
            <v-col
              v-else
              xl="2" lg="2" md="2" sm="2"
              cols="12"
            >
              <mb-input-cpf
                v-model="contratante.document"
                name="cpf_cpf" label="CPF"
                rules="required"
                :disabled="contratante.id != null"
              />
            </v-col>
          </v-row>
          <!-- Name/Slug -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              v-if="contratante.type == 'pj'"
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4"
            >
              <mb-input-text
                v-model="contratante.name"
                name="cpny_name" label="Nome"
                rules="required|max:150"
              />
            </v-col>
            <v-col
              v-else
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4"
            >
              <mb-input-text
                v-model="contratante.name"
                name="cpny_name" label="Nome"
                rules="required|max:60"
              />
            </v-col>

            <v-col
              v-if="contratante.type == 'pj'"
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4"
            >
              <mb-input-text
                v-model="contratante.slug"
                name="cpny_slug" label="Nome Divulgação"
                rules="required|max:50"
              />
            </v-col>
          </v-row>
          <!-- Contact/Email -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              v-if="contratante.type == 'pj'"
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4">
              <mb-input-text
                v-model="contratante.contact"
                name="cpny_contact" label="Contato"
                rules="required|max:50"
              />
            </v-col>
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0">
              <mb-input-text
                v-model.trim="contratante.email"
                name="cpny_email" label="Email" rules="required"
              />
            </v-col>
          </v-row>
          <!-- Phones -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4">
              <mb-input-phone
                v-model="contratante.mobile"
                name="cpny_mobile" label="Celular" rules="required|phone"
              />
            </v-col>
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4">
              <mb-input-phone
                v-model="contratante.phone"
                name="cpny_phone" label="Telefone"
              />
            </v-col>
          </v-row>
          <v-row dense class="pa-0 ma-0" v-if="contratante.type == 'pf'">
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 mr-4"
            >
              <mb-date
                v-model="contratante.birthdate"
                name="cpny_birthdate"
                label="Data de Nascimento"
                rules="required"
                :allowedDates="(vl) => { return $moment(vl).isBefore($moment()) }"
              />
            </v-col>
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 mr-4"
            >
              <mb-select
                v-model="contratante.genre"
                :items="sexItems"
                itemText="text"
                itemValue="vl"
                name="cpny_sex"
                label="Sexo"
              />
            </v-col>
          </v-row>

          <!-- ============== Address ============== -->
          <v-divider />

          <v-row class="pa-0 ma-0">
            <v-col cols="12" class="pa-0 ma-0">
              <address-form v-model="contratante" />
            </v-col>
          </v-row>

        </form>
      </v-card-text>

      <v-card-actions>
        <mb-text-button
          name="prd_btn_save" label="Salvar"
          :disabled="invalid"
          :loading="saveBtnLoading"
          class="ml-2 mb-2"
          @click="save"
        />
        <v-spacer />
        <mb-text-button
          name="prd_btn_cancel" label="Cancelar" color="accent"
          class="mb-2" @click="cancel"
        />
      </v-card-actions>
    </ValidationObserver>
  </v-container>

</template>

<script>
import { ValidationObserver } from 'vee-validate';
import bus from '@/plugins/eventBus';
import MbInputText from '@/components/general/inputs/MbInputText';
import MbInputPhone from '@/components/general/inputs/MbInputPhone';
import MbInputCnpj from '@/components/general/inputs/MbInputCnpj';
import MbDate from '@/components/general/inputs/MbDate';
import MbInputCpf from '@/components/general/inputs/MbInputCpf';
import MbSelect from '@/components/general/inputs/MbSelect';
import MbTextButton from '@/components/general/buttons/MbTextButton';
import AddressForm from '@/components/general/AddressForm';

export default {
  components: {
    ValidationObserver,
    MbInputText,
    MbInputPhone,
    MbInputCnpj,
    MbInputCpf,
    MbSelect,
    MbDate,
    MbTextButton,
    AddressForm,
  },
  props: {
    estipulanteId: {
      type: Number,
      default: null,
    },
    subestipulanteId: {
      type: Number,
      default: null,
    },
    brokerId: {
      type: Number,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      saveBtnLoading: false,
      contratante: null,
      defaultContratante: {
        document: null,
        name: null,
        slug: null,
        contact: null,
        email: null,
        phone: null,
        mobile: null,
        birthdate: null,
        genre: null,

        zip: null,
        street: null,
        number: null,
        complement: null,
        district: null,
        city: null,
        state: null,
        latitude: null,
        longitude: null,

        type: 'pj',
      },
      sexItems: [
        { vl: 'female', text: 'Feminino' },
        { vl: 'male', text: 'Masculino' },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser'];
    },
  },
  watch: {
    value(vl) {
      this.contratante = vl || JSON.parse(JSON.stringify(this.defaultContratante));
      this.$refs.observer.reset();
    },
  },
  created() {
    this.contratante = this.value || JSON.parse(JSON.stringify(this.defaultContratante));
  },
  destroyed() {
    this.contratante = JSON.parse(JSON.stringify(this.defaultContratante));
  },
  methods: {
    save() {
      if (this.contratante.id) {
        this.update();
      } else {
        this.create();
      }
    },
    async create() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        let method = 'contratante/createEstipulante';

        const params = {
          estipulanteId: this.estipulanteId,
        };

        if (this.subestipulanteId != null) {
          params.subestipulanteId = this.subestipulanteId;
          method = 'contratante/createSubestipulante';
        }

        if (this.brokerId != null) {
          params.brokerId = this.brokerId;
        }

        if (this.user && this.user.loginType === 'brokers') {
          params.brokerId = this.user.id;
        }

        params.contratante = this.contratante;

        this.saveBtnLoading = true;
        this.$store
          .dispatch(method, params)
          .then(() => {
            this.contratante = this.defaultContratante;
            bus.$emit('message', {
              message: 'Contratante criado com sucesso!',
              type: 'success',
            });
            this.$refs.observer.reset();
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
    async update() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.saveBtnLoading = true;
        this.$store
          .dispatch('contratante/update', this.contratante)
          .then((subestipulanteSaved) => {
            bus.$emit('message', {
              message: 'Contratante atualizado com sucesso!',
              type: 'success',
            });
            this.$emit('updated');
            this.$emit('input', subestipulanteSaved);
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
    cancel() {
      this.$emit('cancel');
      this.contratante = JSON.parse(JSON.stringify(this.defaultContratante));
      this.$refs.observer.reset();
      this.$router.push({ name: 'contratante-list' });
    },
  },
};
</script>
