<template>
  <v-card>
    <v-card-title>
      <span class="headline mb-0">Regra de Comissão</span>
    </v-card-title>

   <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-card-text>
        <!-- <v-row class="pa-0 ma-0">
          <v-alert
            outlined type="warning" border="left"
            dense
            v-if="(!commission.withoutBond && hasFixedBound > 0) ||
            (commission.withoutBond && hasFixedWithoutBound > 0)"
          >
            Já há uma regra do tipo "Valor Fixo" ativa.
          </v-alert>
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-alert
            outlined type="warning" border="left"
            dense
            v-if="!canAddLifeRange"
          >
            Já há uma regra do tipo "Faixa de Vidas" ativa.
          </v-alert>
        </v-row> -->
        <v-row class="pa-0 ma-0">
          <form autocomplete="off">
            <!-- Fixed/Active/Value Type -->
            <v-row>
              <!-- Fixed -->
              <v-col
                xl="5" lg="5" md="5" sm="5"
                cols="12"
              >
                <v-switch
                  v-model="commission.fixed"
                  label="Valor Fixo?"
                  :disabled="isUpdate"
                ></v-switch>
              </v-col>
              <!-- Active -->
              <v-col
                xl="3" lg="3" md="3" sm="3"
                cols="12"
              >
                <v-switch
                  v-model="commission.active"
                  label="Ativo?"
                ></v-switch>
              </v-col>
              <!-- syndicate -->
              <v-col
                xl="5" lg="5" md="5" sm="5"
                cols="12"
              >
                <mb-select
                  v-model="commission.syndicate"
                  :items="syndicates"
                  itemText="text"
                  itemValue="vl"
                  name="cmc_syndicate"
                  label="Vinculo"
                  rules="required"
                />
              </v-col>
              <!-- personType -->
              <v-col
                xl="5" lg="5" md="5" sm="5"
                cols="12"
              >
                <mb-select
                  v-model="commission.personType"
                  :items="personTypes"
                  itemText="text"
                  itemValue="vl"
                  name="cmc_person_type"
                  label="PJ/PF"
                  rules="required"
                />
              </v-col>
              <!-- Value Type -->
              <!-- <v-col
              xl="5" lg="5" md="5" sm="5"
              cols="12"
              >
                <mb-select
                  v-model="commission.valueType" :items="valueTypes"
                  itemText="desc" itemValue="val" name="cmc_value_type"
                  label="Tipo de Valor"
                  rules="required"
                />
              </v-col> -->
            </v-row>
            <!-- Lifes Num -->
            <v-row v-if="!commission.fixed">
              <!-- Start Lifes -->
              <v-col
                xl="5" lg="5" md="5" sm="5"
                cols="12">
                <mb-input-number
                  v-model="commission.startNumLifes"
                  name="cmc_start_lifes"
                  label="N. Inicial de Vidas"
                  hideDetails="auto"
                  rules="required"
                />
              </v-col>
              <!-- End Lifes -->
              <v-col
                xl="5" lg="5" md="5" sm="5"
                cols="12">
                <mb-input-number
                  v-model="commission.endNumLifes"
                  name="cmc_end_lifes"
                  label="N. Final de Vidas"
                  hideDetails="auto"
                  rules="required"
                />
              </v-col>
            </v-row>
            <v-row>
              <!-- Value -->
              <v-col
                xl="8" lg="8" md="8" sm="8"
                cols="12"
              >
                <mb-input-percent-or-val
                  v-model="commissionValue"
                  name="cmc_value"
                  label="Valor"
                  hideDetails="auto"
                  rules="required"
                />
              </v-col>
            </v-row>
          </form>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <mb-text-button
          name="prd_btn_save" label="Salvar"
          :disabled="invalid"
          :loading="saveBtnLoading"
          class="ml-2 mb-2"
          @click="save"
        />
        <v-spacer />
        <mb-text-button
          name="prd_btn_cancel" label="Cancelar" :primary="false"
          class="mb-2" @click="cancel"
        />
      </v-card-actions>
    </ValidationObserver>
  </v-card>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import bus from '@/plugins/eventBus';
import MbInputNumber from '@/components/general/inputs/MbInputNumber';
// import MbInputPercent from '@/components/general/inputs/MbInputPercent';
import MbInputPercentOrVal from '@/components/general/inputs/MbInputPercentOrVal';
import MbTextButton from '@/components/general/buttons/MbTextButton';
import MbSelect from '@/components/general/inputs/MbSelect';

export default {
  components: {
    ValidationObserver,
    MbInputNumber,
    // MbInputPercent,
    MbInputPercentOrVal,
    MbTextButton,
    MbSelect,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      required: true,
    },
    estipulanteId: {
      type: [Number, String],
    },
    // hasFixedBound: {
    //   type: Boolean,
    //   default: false,
    // },
    // hasFixedWithoutBound: {
    //   type: Boolean,
    //   default: false,
    // },
    // hasDynamic: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      commission: null,
      defaultCommission: {
        estipulanteId: null,
        type: null,
        personType: 'pj',
        syndicate: 'with',
        fixed: false,
        startNumLifes: null,
        endNumLifes: null,
        valueType: '%',
        active: true,
        value: null,
      },
      personTypes: [
        { vl: 'pj', text: this.$options.filters.personType('pj') },
        { vl: 'pf', text: this.$options.filters.personType('pf') },
        { vl: 'both', text: this.$options.filters.personType('both') },
      ],
      syndicates: [
        { vl: 'with', text: this.$options.filters.syndicate('with') },
        { vl: 'without', text: this.$options.filters.syndicate('without') },
        { vl: 'both', text: this.$options.filters.syndicate('both') },
      ],
      // valueTypes: [
      //   { desc: 'Percentual', val: '%' },
      //   { desc: 'Reais', val: '$' },
      // ],
      saveBtnLoading: false,
    };
  },
  computed: {
    isUpdate() {
      return this.commission && this.commission.id != null;
    },
    // canAddFixed() {
    //   return !(this.hasFixed && !this.isUpdate);
    // },
    // canAddLifeRange() {
    //   return !(this.hasDynamic && this.commission.fixed && !this.isUpdate);
    // },
    commissionValue: {
      get() {
        return { type: this.commission.valueType, value: this.commission.value };
      },
      set(vl) {
        this.commission.valueType = vl.type;
        this.commission.value = vl.value;
      },
    },
  },
  watch: {
    value(vl) {
      this.commission = vl || JSON.parse(JSON.stringify(this.defaultCommission));
    },
  },
  created() {
    this.commission = this.value || JSON.parse(JSON.stringify(this.defaultCommission));
  },
  methods: {
    save() {
      if (this.commission.id) {
        this.update();
      } else {
        this.create();
      }
    },
    async create() {
      const isValid = await this.$refs.observer.validate();

      if (isValid && this.estipulanteId && this.type) {
        this.commission.type = this.type;
        this.commission.estipulanteId = this.estipulanteId;
        this.addBtnLoading = true;
        this.$store
          .dispatch('commission/create', this.commission)
          .then((res) => {
            this.$emit('changed', res);
            bus.$emit('message', {
              message: 'Regra de comissão salva com sucesso!',
              type: 'success',
            });
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.addBtnLoading = false;
          });
      }
    },
    async update() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.saveBtnLoading = true;
        this.$store
          .dispatch('commission/update', this.commission)
          .then(() => {
            bus.$emit('message', {
              message: 'Regra de comissão salva com sucesso!',
              type: 'success',
            });
            this.$emit('changed');
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
    async cancel() {
      this.$emit('canceled');
    },
  },
};
</script>
