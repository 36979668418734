<template>
  <v-container>
    <v-row class="mb-5">
      <v-col cols="12" class="pa-0 ma-0">
        <contratante-configuration-pj v-model="configuration" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ContratanteConfigurationPj from '@/components/contratante/ContratanteConfigurationPj';

export default {
  components: {
    ContratanteConfigurationPj,
  },
  data: () => ({
    hashids: null,
    configuration: null,
  }),
  created() {
    this.user = this.$store.getters['user/getUser'];
    this.get();
  },
  methods: {
    get() {
      this.$store
        .dispatch('contratanteConfiguration/one', this.user.company.id)
        .then((configuration) => {
          this.configuration = configuration;
        });
    },
  },
};
</script>
