<template>
  <v-container>
    <configurations-form />

    <!-- <v-tabs
      v-model="tab"
      fixed-tabs
      background-color="primary"
      dark
    >
      <v-tab>Configurações</v-tab>
      <v-tab>Planos Pessoa Física</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" grow>
      <v-tab-item>
        <configurations-form />
      </v-tab-item>
      <v-tab-item >
        <pf-plans />
      </v-tab-item>
    </v-tabs-items> -->

    <!-- <v-card>
      <configurations-form />
      <configurations-pf-form />
    </v-card> -->
  </v-container>
</template>

<script>
import ConfigurationsForm from '@/components/configurations/ConfigurationsForm';
// import PfPlans from '@/components/configurations/PfPlans';
// import ConfigurationsPfForm from '@/components/configurations/ConfigurationsPfForm';

export default {
  components: {
    ConfigurationsForm,
    // PfPlans,
  },
  data: () => ({
    tab: null,
  }),
  created() {
  },
  watch: {
  },
  methods: {
  },
};
</script>
