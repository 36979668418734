<template>
  <v-container class="pa-0 ma-0">

    <!-- Postalcode -->
    <v-row dense class="mt-5">
      <!-- CEP -->
      <v-col
        xl="3" lg="3" md="3" sm="3"
        cols="12"
      >
        <mb-input-cep
          v-model="address.zip" name="cpny_adrs_postal-code"
          rules="required|cep" @gettedByCep="gettedByCep"
        />
      </v-col>
    </v-row>
    <!-- Neighbourhood/State/City -->
    <v-row dense>
      <v-col
        xl="4" lg="4" md="4" sm=""
        cols="12"
      >
        <mb-input-text
          v-model="address.district"
          name="cpny_adrs_district" label="Bairro"
          rules="required|max:60"
        />
      </v-col>
      <v-col
        xl="2" lg="2" md="2" sm="2"
        cols="12"
      >
        <mb-select
          v-model="address.state" :items="states" name="cpny_adrs_state"
          label="UF"
        />
      </v-col>
      <v-col>
        <mb-input-text
          v-model="address.city" name="cpny_adrs_city" label="Cidade"
          rules="required|max:60"
        />
      </v-col>
    </v-row>
    <!-- Street/Number -->
    <v-row dense>
      <v-col
        xl="9" lg="9" md="9" sm="9"
        cols="12"
      >
        <mb-input-text
          v-model="address.street"
          name="cpny_adrs_street" label="Logradouro"
          rules="required|max:60"
        />
      </v-col>
      <v-col
        xl="3" lg="3" md="3" sm="3"
        cols="12"
      >
        <mb-input-text
          v-model="address.number" name="cpny_adrs_number"
          label="Número" rules="required|max:10"
        />
      </v-col>
    </v-row>
    <!-- Complement -->
    <v-row dense>
      <v-col cols="12">
        <mb-input-text
          v-model="address.complement" name="cpny_adrs_complement"
          label="Complemento" rules="max:30"
        />
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import MbSelect from '@/components/general/inputs/MbSelect';
import MbInputText from '@/components/general/inputs/MbInputText';
import MbInputCep from '@/components/general/inputs/MbInputCep';
import stateList from '@/utils/states';

export default {
  components: {
    MbSelect,
    MbInputText,
    MbInputCep,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      states: stateList,
      address: null,
      defaultAddress: {
        zip: null,
        street: null,
        number: null,
        complement: null,
        district: null,
        city: null,
        state: null,
        latitude: null,
        longitude: null,
      },
    };
  },
  watch: {
    value(vl) {
      this.address = vl || JSON.parse(JSON.stringify(this.defaultAddress));
    },
    address: {
      handler() {
        this.$emit('input', this.address);
      },
      deep: true,
    },
  },
  created() {
    this.address = this.value || JSON.parse(JSON.stringify(this.defaultAddress));
  },
  methods: {
    gettedByCep(obj) {
      this.address = { ...this.address, ...obj };
    },
    getGeolocation() {
      const query = encodeURI(`${this.address.street} ${(this.address.district) ? this.address.district : ''} ${this.address.city}`);
      const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${query}&key=${process.env.VUE_APP_GOOGLE_API}`;

      return fetch(url).then((res) => {
        if (res.status === 200) {
          return res.json().then((data) => {
            this.address.latitude = `${data.results[0].geometry.location.lat}`;
            this.address.longitude = `${data.results[0].geometry.location.lng}`;
          });
        }
        return null;
      });
    },
  },
};
</script>
