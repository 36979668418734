var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-progress-linear',{attrs:{"indeterminate":"","active":_vm.loading}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.contratantes,"options":_vm.options,"server-items-length":_vm.total,"item-key":"id","sort-by":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{staticClass:"pa-0 ma-0 py-4 pb-6",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0 ml-5"},[_c('v-col',{staticClass:"pa-0 ma-0 text--disabled",attrs:{"cols":"12"}},[_vm._v(" Pedentes de? ")])],1),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0 pl-4",attrs:{"xl":"5","lg":"5","md":"5","sm":"5","cols":"12"}},[_c('v-radio-group',{attrs:{"row":""},on:{"change":_vm.changeFilter},model:{value:(_vm.filter.type),callback:function ($$v) {_vm.$set(_vm.filter, "type", $$v)},expression:"filter.type"}},[_c('v-radio',{attrs:{"label":"Contrato","value":"contract"}}),_c('v-radio',{attrs:{"label":"Verificação","value":"verification"}}),_c('v-radio',{attrs:{"label":"Pagamento","value":"payment"}})],1)],1)],1)],1)]},proxy:true},{key:"item.document",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("document")(item.document))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mb-upper"},[_vm._v(_vm._s(item.type))])]}},{key:"item.mobile",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("phone")(item.mobile))+" ")]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("phone")(item.phone))+" ")]}},{key:"item.verified",fn:function(ref){
var item = ref.item;
return [(item.type == 'pj')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.$options.filters.verifiedIcon(item.verified)))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$options.filters.verified(item.verified)))])]):_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-minus")])],1)]}}],null,true)},[_c('span',[_vm._v("Não se aplica")])])]}},{key:"item.contractFile",fn:function(ref){
var item = ref.item;
return [(item.type == 'pj')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(_vm._s(_vm.$options.filters.contractIcon(item.contractFile)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$options.filters.contract(item.contractFile)))])]):_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-minus")])],1)]}}],null,true)},[_c('span',[_vm._v("Não se aplica")])])]}},{key:"item.paymentStatus",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.$options.filters.paymentStatusIcon(item.paymentStatus)))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$options.filters.paymentStatus(item.paymentStatus)))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{on:{"click":function($event){return _vm.handleEdit(item)}}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{on:{"click":function($event){return _vm.handleConfig(item)}}},[_vm._v("mdi-cog")])],1)]}}],null,true)},[_c('span',[_vm._v("Configurações")])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }