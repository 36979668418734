var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-progress-linear',{attrs:{"indeterminate":"","active":_vm.loading}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.beneficiaries,"options":_vm.options,"server-items-length":_vm.totalRecords,"item-key":"id","sort-by":"id","loading":_vm.loadingGrid},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{staticClass:"pa-0 ma-0 py-4 pb-6",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0 pl-4 pt-2",attrs:{"xl":"3","lg":"3","md":"3","sm":"3","cols":"12"}},[_c('mb-input-text',{attrs:{"name":"bnfc_fltr_searchstr","label":"Nome/Documento"},on:{"input":_vm.changeFilterSearchstr},model:{value:(_vm.filter.searchstr),callback:function ($$v) {_vm.$set(_vm.filter, "searchstr", $$v)},expression:"filter.searchstr"}})],1)],1)],1)]},proxy:true},{key:"item.type",fn:function(ref){
var item = ref.item;
return [((item.type == 'titular'))?_c('v-icon',[_vm._v(" "+_vm._s(_vm.$options.filters.activeIcon(item.beneficiaryActive))+" ")]):_c('span')]}},{key:"item.birthdate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.birthdate))+" ")]}},{key:"item.countryRegister",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("document")(item.countryRegister))+" ")]}},{key:"item.mobile",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("phone")(item.mobile))+" ")]}},{key:"item.work",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("phone")(item.work))+" ")]}},{key:"item.contratanteType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$options.filters.personType(item.contratanteType))+" ")]}},{key:"item.beneficiaryActive",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.$options.filters.activeIcon(item.beneficiaryActive)))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$options.filters.active(item.beneficiaryActive)))])])]}},{key:"item.contratantePaymentStatus",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.$options.filters.paymentStatusIcon(item.contratantePaymentStatus))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$options.filters.paymentStatus(item.contratantePaymentStatus)))])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }