import { pg } from '../utils/query';
import HTTP from './axios';

const EstipulanteService = {
  all: (parameters) => HTTP.get(`/estipulante?${pg(parameters)}`).then((response) => response.data),
  one: (id) => HTTP.get(`/estipulante/${id}`).then((response) => response.data),
  oneLink: (id) => {
    const key = process.env.VUE_APP_LINK_KEY;
    return HTTP.get(`/estipulante/link/${id}`, { headers: { key } }).then((response) => response.data);
  },
  oneSite: (p) => {
    const key = process.env.VUE_APP_LINK_KEY;
    return HTTP.get(`/estipulante/site?from=${p.from}&type=${p.type}`, { headers: { key } }).then((response) => response.data);
  },
  oneByCompany: (id) => HTTP.get(`/estipulante/company/${id}`).then((response) => response.data),
  create: (estipulante) => HTTP.post('/estipulante', estipulante)
    .then((response) => response.data),
  update: (estipulante) => HTTP.put(`/estipulante/${estipulante.id}`, estipulante)
    .then((response) => response.data),
  updateValues: (estipulante) => HTTP.put(`/estipulante/${estipulante.id}/values`, estipulante)
    .then((response) => response.data),
  updateTypes: (estipulante) => HTTP.put(`/estipulante/${estipulante.id}/types`, estipulante)
    .then((response) => response.data),
};

export default EstipulanteService;
