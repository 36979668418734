import HTTP from './axios';

const ProductService = {
  all: () => HTTP.get('/paymentMethod')
    .then((response) => response.data),

  create: (paymentMethod) => HTTP.post('/paymentMethod', paymentMethod)
    .then((response) => response.data),

  remove: (paymentMethodId) => HTTP.delete(`/paymentMethod/${paymentMethodId}`)
    .then((response) => response.data),
};

export default ProductService;
