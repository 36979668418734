import BrokerService from '@/services/broker.service';

const brokers = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    all(_, parameters) {
      return BrokerService.all(parameters);
    },
    one(_, id) {
      return BrokerService.one(id);
    },
    create(_, broker) {
      return BrokerService.create(broker);
    },
    update(_, broker) {
      return BrokerService.update(broker);
    },
  },

  getters: {},
};

export default brokers;
