import { pg, filter } from '../utils/query';
import HTTP from './axios';

const PlansService = {
  all: (p) => HTTP.get(`/estipulante/${p.estipulanteId}/plan?${pg(p)}${filter(p)}`).then((response) => response.data),
  actives: (p) => {
    const query = (p.subestipulanteId) ? `?subestipulanteId=${p.subestipulanteId}` : '';
    return HTTP.get(`/estipulante/${p.estipulanteId}/plan/actives${query}`).then((response) => response.data);
  },
  activesByContratantePf: (subestipulanteId) => {
    const queryP = (subestipulanteId != null) ? `?subestipulanteId=${subestipulanteId}` : '';
    return HTTP.get(`/plan/activesByContratantePf${queryP}`).then((response) => response.data);
  },
  activesByContratantePfLink: (userId, subestipulanteId) => {
    let queryP = `?uId=${userId}`;
    queryP += (subestipulanteId != null) ? `&subestipulanteId=${subestipulanteId}` : '';
    return HTTP.get(`/plan/activesByContratantePf/link${queryP}`).then((response) => response.data);
  },
  one: (id) => HTTP.get(`/plan/${id}`).then((response) => response.data),
  create: (plan) => HTTP.post(`/estipulante/${plan.estipulanteId}/plan`, plan).then((response) => response.data),
  update: (plan) => HTTP.put(`/plan/${plan.id}`, plan).then((response) => response.data),
  // remove: (plan) => HTTP.delete(`/plan/${plan.id}`).then((response) => response.data),
};

export default PlansService;
