import { pg } from '../utils/query';
import HTTP from './axios';

const SubestipulanteService = {
  all: (p) => HTTP.get(`/estipulante/${p.id}/subestipulante?${pg(p)}`).then((response) => response.data),
  allSite: (id) => HTTP.get(`/subestipulante/site?estipulanteId=${id}`).then((response) => response.data),
  one: (id) => HTTP.get(`/subestipulante/${id}`).then((response) => response.data),
  create: (p) => HTTP.post(`/estipulante/${p.estipulanteId}/subestipulante`, p.subestipulante)
    .then((response) => response.data),
  update: (subestipulante) => HTTP.put(`/subestipulante/${subestipulante.id}`, subestipulante)
    .then((response) => response.data),
};

export default SubestipulanteService;
