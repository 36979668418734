import EstipulanteService from '@/services/estipulante.service';

const estipulantes = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    all(_, parameters) {
      return EstipulanteService.all(parameters);
    },
    one(_, id) {
      return EstipulanteService.one(id);
    },
    oneLink(_, id) {
      return EstipulanteService.oneLink(id);
    },
    oneSite(_, p) {
      return EstipulanteService.oneSite(p);
    },
    oneByCompany(_, companyId) {
      return EstipulanteService.oneByCompany(companyId);
    },
    create(_, estipulante) {
      return EstipulanteService.create(estipulante);
    },
    update(_, estipulante) {
      return EstipulanteService.update(estipulante);
    },
    updateValues(_, estipulante) {
      return EstipulanteService.updateValues(estipulante);
    },
    updateTypes(_, estipulante) {
      return EstipulanteService.updateTypes(estipulante);
    },
  },

  getters: {},
};

export default estipulantes;
