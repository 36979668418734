import { pg, filter } from '../utils/query';
import HTTP from './axios';

const SubscriptionService = {
  all: (parameters) => HTTP.get(`/subscription?${pg(parameters)}${filter(parameters)}`).then((response) => response.data),
  my: (contratanteId) => HTTP.get(`/subscription/my?contratanteId=${contratanteId}`).then((response) => response.data),
  create: (subscription) => HTTP.post('/subscription', subscription).then((response) => response.data),
  createLink: (p) => {
    const query = `?uId=${p.uId}`;
    return HTTP.post(`/subscription/link${query}`, p.subscription).then((response) => response.data);
  },
  update: (subscription) => HTTP.put(`/subscription/${subscription.id}`, subscription).then((response) => response.data),
  calculate: (subscription) => HTTP.post('/subscription/calculate', subscription).then((response) => response.data),
  calculateLink: (subscription) => HTTP.post('/subscription/calculate/link', subscription).then((response) => response.data),
  cancel: (id) => HTTP.delete(`/subscription/${id}`).then((response) => response.data),
  suspend: (id) => HTTP.put(`/subscription/${id}/suspend`).then((response) => response.data),
  activate: (id) => HTTP.put(`/subscription/${id}/activate`).then((response) => response.data),
};

export default SubscriptionService;
