var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-progress-linear',{attrs:{"indeterminate":"","active":_vm.loading}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dependents,"item-key":"id","sort-by":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{staticClass:"pa-0 ma-0 py-4 pb-6",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0 pt-3"},[_c('v-col',{staticClass:"pa-0 ma-0 pl-4 pt-2",attrs:{"xl":"1","lg":"1","md":"1","sm":"1","cols":"12"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","disabled":_vm.titularId == null},on:{"click":_vm.handleNew}},[_vm._v(" Novo ")])],1)],1)],1)]},proxy:true},{key:"item.birthdate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.birthdate))+" ")]}},{key:"item.countryRegister",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("document")(item.countryRegister))+" ")]}},{key:"item.mobile",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("phone")(item.mobile))+" ")]}},{key:"item.work",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("phone")(item.work))+" ")]}},{key:"item.beneficiaryActive",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.$options.filters.activeIcon(item.beneficiaryActive)))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$options.filters.active(item.beneficiaryActive)))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.handleEdit(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.toChangeActive = item; _vm.confirmChangeActive = true;}}},on),[_c('v-icon',[_vm._v(_vm._s((item.beneficiaryActive) ? 'mdi-cancel' : 'mdi-check-bold'))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s((item.beneficiaryActive) ? 'Desativar' : 'Ativar'))])])]}}],null,true)}),(_vm.toChangeActive)?_c('confirmation-dialog',{attrs:{"title":"Confirmação"},on:{"confirm":_vm.toggleActive},model:{value:(_vm.confirmChangeActive),callback:function ($$v) {_vm.confirmChangeActive=$$v},expression:"confirmChangeActive"}},[_c('div',[_vm._v(" "+_vm._s(("Deseja " + ((_vm.toChangeActive.beneficiaryActive) ? 'desativar' : 'ativar') + " o dependente"))+" "),_c('b',[_vm._v(_vm._s(_vm.toChangeActive.name))])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }