import ConfigurationsService from '@/services/configurations.service';

const configurations = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    get() {
      return ConfigurationsService.get();
    },
    update(_, p) {
      return ConfigurationsService.update(p);
    },
  },

  getters: {},
};

export default configurations;
