<template>
  <v-container>
    <v-progress-linear indeterminate :active="loading" />
    <v-data-table
      :headers="headers"
      :items="contratantes"
      :options.sync="options"
      :server-items-length="total"
      item-key="id"
      multi-sort
      class="elevation-1"
    >
    <!-- sort-by="id" -->
      <template v-slot:top>
        <v-container fluid class="pa-0 ma-0 py-4 pb-6">
          <!-- Filters -->
          <v-row class="pa-0 ma-0">
            <v-col
              xl="2" lg="2" md="2" sm="2"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-select
                v-model="filter.verified" :items="verifieds"
                item-text="txt" item-value="vl" name="ctrnt_fltr_verified" label="Verificados"
                @input="changeFilter"
              />
            </v-col>
            <v-col
              xl="2" lg="2" md="2" sm="2"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-select
                v-model="filter.contract" :items="contracts"
                item-text="txt" item-value="vl" name="ctrnt_fltr_contract" label="Com Contrato"
                @input="changeFilter"
              />
            </v-col>
            <v-col
              xl="2" lg="2" md="2" sm="2"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-select
                v-model="filter.paymentStatus" :items="paymentsStatus"
                item-text="txt" item-value="vl" name="ctrnt_fltr_payment_status" label="Status Fin."
                @input="changeFilter"
              />
            </v-col>

          </v-row>
          <!-- Brokers/Relations  -->
          <v-row class="pa-0 ma-0">
            <v-col
              v-if="user && user.loginType === 'broker'"
              xl="2" lg="2" md="2" sm="2"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-select
                v-model="broker" :items="brokers"
                item-text="desc" name="ctrnt_fltr_broker" label="Corretores"
                @input="changeFilter"
                :loading="selectBrokersLoading"
              />
            </v-col>
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-select
                v-model="estipulante" :items="estipulantes"
                item-text="slug" name="ctrnt_fltr_estipulante" label="Estipulantes"
                @input="changeFilter" :loading="selectEstipulanteLoading"
              />
            </v-col>
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-select
                v-model="subestipulante" :items="subestipulantes" clearable
                item-text="slug" name="ctrnt_fltr_subestipulante" label="Subestipulantes"
                @input="changeFilter" :loading="selectSubestipulanteLoading"
              />
            </v-col>
            <!-- Button -->
            <v-col
              xl="2" lg="2" md="2" sm="2"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <v-btn
                v-if="user && !['company', 'subestipulante'].includes(user.loginType)"
                color="primary" dark class="mb-2"
                @click="handleNew"
                :disabled="estipulante == null"
              >Novo</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:[`item.document`]="{ item }">
        {{ item.document | document }}
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <div class="mb-upper">{{ item.type }}</div>
      </template>

      <template v-slot:[`item.mobile`]="{ item }">
        {{ item.mobile | phone }}
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAt | ISOToDate }}
      </template>

      <template v-slot:[`item.verified`]="{ item }">
        <v-tooltip left v-if="item.type == 'pj'">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>{{ $options.filters.verifiedIcon(item.verified) }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $options.filters.verified(item.verified) }}</span>
        </v-tooltip>
        <v-tooltip left v-else>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </template>
          <span>Não se aplica</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.contractFile`]="{ item }">
        <v-tooltip left v-if="item.type == 'pj'">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">{{ $options.filters.contractIcon(item.contractFile) }}</v-icon>
          </template>
          <span>{{ $options.filters.contract(item.contractFile) }}</span>
        </v-tooltip>
        <v-tooltip left v-else>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </template>
          <span>Não se aplica</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.paymentStatus`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>{{ $options.filters.paymentStatusIcon(item.paymentStatus) }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $options.filters.paymentStatus(item.paymentStatus) }}</span>
        </v-tooltip>
      </template>

      <template
        v-slot:[`item.actions`]="{ item }"
        v-if="['brokers', 'medibook'].includes(user.loginType)">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon @click="handleEdit(item)">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <!-- <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon @click="handleBank(item)">mdi-account-cash</v-icon>
            </v-btn>
          </template>
          <span>Dados Bancários</span>
        </v-tooltip> -->
        <v-tooltip left v-if="item.type == 'pj'">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon @click="handleConfig(item)">mdi-cog</v-icon>
            </v-btn>
          </template>
          <span>Configurações</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import bus from '@/plugins/eventBus';
import MbSelect from '@/components/general/inputs/MbSelect';

const Hashids = require('hashids');

export default {
  components: {
    MbSelect,
  },
  data: () => ({
    Hashids: null,
    total: 0,
    edit: false,
    headers: [
      {
        text: 'Nome', align: 'start', value: 'name',
      },
      // { text: 'Nome de Divulgação', value: 'slug' },
      { text: 'Documento', value: 'document' },
      { text: 'Tipo', value: 'type' },
      { text: 'Contato', value: 'contact' },
      { text: 'Email', value: 'email' },
      { text: 'Celular', value: 'mobile' },
      // { text: 'Telefone', value: 'phone' },
      // { text: 'Status', value: 'paymentAccountStatus' },
      { text: 'Criado Em', value: 'createdAt' },
      { text: 'Verificado', value: 'verified' },
      { text: 'Contrato', value: 'contractFile' },
      { text: 'Status Finan.', value: 'paymentStatus' },
      { text: 'Ações', sortable: false, value: 'actions' },
    ],
    options: {
      itemsPerPage: 10,
    },
    filter: {
      verified: 'all',
      contract: 'all',
      paymentStatus: 'all',
    },
    broker: null,
    brokers: [],
    verifieds: [
      { txt: 'Todos', vl: 'all' },
      { txt: 'Verificados', vl: '1' },
      { txt: 'Não verificados', vl: '0' },
    ],
    contracts: [
      { txt: 'Todos', vl: 'all' },
      { txt: 'Com contratos', vl: '1' },
      { txt: 'Sem contratos', vl: '0' },
    ],
    paymentsStatus: [
      { txt: 'Todos', vl: 'all' },
      { txt: 'Pagamentos Ok', vl: 'ok' },
      { txt: 'Com pendência', vl: 'pending' },
    ],
    contratantes: [],
    estipulantes: [],
    estipulante: null,
    subestipulantes: [],
    subestipulante: null,
    confirmRemove: false,
    loading: false,
    selectBrokersLoading: false,
    selectEstipulanteLoading: false,
    selectSubestipulanteLoading: false,
  }),
  watch: {
    options: {
      handler() {
        if (this.options.page != null && this.options.itemsPerPage != null) {
          this.getAll();
        }
      },
      deep: true,
    },
    estipulante(vl) {
      if (vl) {
        this.subestipulante = null;
        this.getAll();
        this.getSubestipulantes();
      }
    },
  },
  beforeMount() {
    this.getEstipulantes();
  },
  created() {
    this.user = this.$store.getters['user/getUser'];
    if (['broker', 'company', 'subestipulante'].includes(this.user.loginType)) {
      this.headers.pop();
    }

    // eslint-disable-next-line new-cap
    this.Hashids = new Hashids.default(
      process.env.VUE_APP_HASHID_SALT, Number(process.env.VUE_APP_HASHID_PAD),
    );

    // console.log(`loginType: ${this.user.loginType}`);
    // console.log(`is broker: ${this.user.loginType === 'broker'}`);
    if (this.user.loginType === 'broker') {
      // console.log(`@@@@ getBrokers: ${this.user.company.id}`);
      this.getBrokers();
    }
  },
  methods: {
    getAll() {
      let params = null;
      if (this.estipulante != null) {
        params = { estipulanteId: this.estipulante.id };
      }

      let method = 'contratante/allByEstipulante';
      if (this.estipulante !== null && this.subestipulante != null) {
        method = 'contratante/allBySubestipulante';
        params.subestipulanteId = this.subestipulante.id;
      }

      if (params) {
        // console.log(`options: ${JSON.stringify(this.options)}`);
        params.pagination = { page: this.options.page, size: this.options.itemsPerPage };
        params.sort = { sortBy: this.options.sortBy, sortDesc: this.options.sortDesc };
        params.filter = this.filter;

        if (this.user && this.user.loginType === 'brokers') {
          params.brokerId = this.user.company.id;
          params.brokersId = this.user.id;
        }
        if (this.user && this.user.loginType === 'broker') {
          params.brokerId = this.broker.brokerId;
          params.brokersId = this.broker.id;
        }
        this.loading = true;
        this.contratantes = [];
        this.$store.dispatch(method, params).then((resp) => {
          this.total = resp.meta.total;
          this.contratantes = resp.data;
        }).catch((err) => {
          console.log(err);
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    created() {
      setTimeout(() => {
        this.estipulante = null;
      }, 1000);
      this.getAll();
    },
    updated() {
      setTimeout(() => {
        this.estipulante = null;
      }, 1000);
      this.getAll();
    },
    handleNew() {
      const subestipulanteId = (this.subestipulante) ? this.subestipulante.id : null;
      this.$router.push({
        name: 'contratante-cad',
        query: {
          eId: this.Hashids.encode(this.estipulante.id),
          sbId: this.Hashids.encode(subestipulanteId),
          bs: (this.broker) ? this.Hashids.encode(this.broker.id) : null,
        },
      });
    },
    handleEdit(item) {
      const subestipulanteId = (this.subestipulante) ? this.subestipulante.id : null;
      this.$router.push({
        name: 'contratante-cad',
        params: {
          id: this.Hashids.encode(item.id),
          estipulante: item,
        },
        query: {
          eId: this.Hashids.encode(this.estipulante.id),
          sbId: this.Hashids.encode(subestipulanteId),
        },
      });
    },
    handleConfig(item) {
      this.$router.push({
        name: 'contratante-configuration',
        params: {
          id: this.Hashids.encode(item.id),
        },
        query: {
          id: this.Hashids.encode(item.id),
        },
      });
    },
    changeFilter() {
      this.getAll();
    },
    getBrokers() {
      // console.log(`getBrokers: ${this.user.company.id}`);
      this.selectBrokersLoading = true;
      this.$store.dispatch('brokers/all', {
        brokerId: this.user.company.id,
        pagination: { page: 1, size: -1 },
      }).then((resp) => {
        this.brokers = resp.data.map((it) => {
          const brks = it;
          brks.desc = `${brks.name} - ${brks.countryRegister}`;
          return brks;
        });
        // eslint-disable-next-line prefer-destructuring
        this.broker = (this.brokers.length > 0) ? this.brokers[0] : null;
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.selectBrokersLoading = false;
      });
    },
    getEstipulantes() {
      this.selectEstipulanteLoading = true;
      this.$store.dispatch('estipulante/all', {
        pagination: { page: 1, size: -1 },
      }).then((resp) => {
        this.estipulantes = resp.data;
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.selectEstipulanteLoading = false;
      });
    },
    getSubestipulantes() {
      if (this.estipulante != null) {
        this.selectSubestipulanteLoading = true;
        this.$store.dispatch('subestipulante/all', {
          id: this.estipulante.id,
          pagination: { page: 1, size: -1 },
        }).then((resp) => {
          this.subestipulantes = resp.data;
        }).catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.selectSubestipulanteLoading = false;
        });
      }
    },
    cancel() {
      this.estipulante = null;
    },
  },
};
</script>

<style scoped>
  .mb-upper {
    text-transform: uppercase
  }
</style>
