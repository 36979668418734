import { pg, filter, sort } from '../utils/query';
import HTTP from './axios';

const ContratanteService = {
  allByEstipulante: (p) => {
    let brokerQuery = (p.brokerId) ? `brokerId=${p.brokerId}&` : '';
    brokerQuery += (p.brokersId) ? `brokersId=${p.brokersId}&` : '';
    return HTTP.get(
      `/estipulante/${p.estipulanteId}/contratante?${brokerQuery}${pg(p)}${filter(p)}${sort(p)}`,
    ).then((response) => response.data);
  },
  allBySubestipulante: (p) => {
    let brokerQuery = (p.brokerId) ? `brokerId=${p.brokerId}&` : '';
    brokerQuery += (p.brokersId) ? `brokersId=${p.brokersId}&` : '';
    return HTTP.get(
      `/subestipulante/${p.subestipulanteId}/contratante?${brokerQuery}${pg(p)}${filter(p)}${sort(p)}`,
    ).then((response) => response.data);
  },

  one: (id) => HTTP.get(`/contratante/${id}`).then((response) => response.data),
  oneLink: (p) => {
    let query = `?id=${p.id}`;
    query += `&uId=${p.uId}`;
    return HTTP.get(`/contratante/link${query}`).then((response) => response.data);
  },

  createEstipulante: (p) => {
    const brokerQuery = (p.brokerId) ? `?brokerId=${p.brokerId}` : '';
    return HTTP.post(`/estipulante/${p.estipulanteId}/contratante${brokerQuery}`, p.contratante)
      .then((response) => response.data);
  },
  createSubestipulante: (p) => {
    const brokerQuery = (p.brokerId) ? `?brokerId=${p.brokerId}` : '';
    return HTTP.post(`/subestipulante/${p.subestipulanteId}/contratante${brokerQuery}`, p.contratante)
      .then((response) => response.data);
  },

  createFromLink: (p) => {
    let query = `?id=${p.parentId}`;
    query += `&type=${p.type}`;
    query += `&uId=${p.userId}`;
    query += (p.brokerId) ? `&brokerId=${p.brokersId}` : '';
    query += (p.planId) ? `&planId=${p.planId}` : '';
    query += (p.withoutAdhesion) ? `&withoutAdhesion=${p.withoutAdhesion}` : '';
    return HTTP.post(`/contratante/link${query}`, p.contratante)
      .then((response) => response.data);
  },

  createFromSite: (p) => {
    let query = (p.estipulanteId) ? `?estipulanteId=${p.estipulanteId}` : '';
    query += (p.subestipulanteId) ? `&subestipulanteId=${p.subestipulanteId}` : '';
    return HTTP.post(`/contratante/site${query}`, p.contratante)
      .then((response) => response.data);
  },

  update: (contratante) => HTTP.put(`/contratante/${contratante.id}`, contratante)
    .then((response) => response.data),

  alterSubestipulante: (p) => {
    const queryP = `?subestipulanteId=${p.subestipulanteId}`;
    return HTTP.put(`/contratante/${p.id}/subestipulante${queryP}`).then((response) => response.data);
  },

  // Contract
  downloadContract: (p) => HTTP.get(`/contratante/${p.id}/downloadContract`)
    .then((response) => response.data),
  uploadContract: (p) => HTTP.post(`/contratante/${p.id}/uploadContract`, p.file)
    .then((response) => response.data),

  changePjToPf: (p) => HTTP.post('/contratante/change/pf', p)
    .then((response) => response.data),

  changePfToPj: (p) => HTTP.post('/contratante/change/pj', p),

  pjPendents: (p) => HTTP.get(`/contratante/pj/pendents?${pg(p)}${filter(p)}`).then((response) => response.data),
};

export default ContratanteService;
