import { pg, filter } from '../utils/query';
import HTTP from './axios';

const BeneficiaryService = {
  allByContratante: (p) => HTTP.get(`/contratante/${p.contratanteId}/beneficiary?${pg(p)}`).then((response) => response.data),
  allByEstipulante: (p) => HTTP.get(`/beneficiary/byEstipulante?${pg(p)}${filter(p)}`).then((response) => response.data),
  allTitularWithContratante: (p) => HTTP.get(`/beneficiary/titularWithContratante?${pg(p)}${filter(p)}`).then((response) => response.data),
  one: (id) => HTTP.get(`/beneficiary/${id}`).then((response) => response.data),
  create: (p) => HTTP.post(`/contratante/${p.contratanteId}/beneficiary`, p.beneficiary)
    .then((response) => response.data),
  import: (p) => HTTP.post('/beneficiary/import', p.file).then((response) => response.data),
  update: (beneficiary) => HTTP.put(`/beneficiary/${beneficiary.id}`, beneficiary)
    .then((response) => response.data),
  toggleActive: (id) => HTTP.put(`/beneficiary/${id}/toggleActive`).then((response) => response.data),
  toggleDimissed: (id) => HTTP.put(`/beneficiary/${id}/toggleDimissed`).then((response) => response.data),
};

export default BeneficiaryService;
