<template>
  <v-container>
    <v-row class="mb-5">
      <v-col cols="12" class="pa-0 ma-0">
        <dependent-form v-model="dependent" :titularId="titularId" @cancel="edit = false" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DependentForm from '@/components/dependent/DependentForm';

const Hashids = require('hashids');

export default {
  components: {
    DependentForm,
  },
  data: () => ({
    hashids: null,
    dependent: null,
    titularId: null,
  }),
  created() {
    // eslint-disable-next-line new-cap
    this.hashids = new Hashids.default(
      process.env.VUE_APP_HASHID_SALT, Number(process.env.VUE_APP_HASHID_PAD),
    );

    // eslint-disable-next-line prefer-destructuring
    this.titularId = this.hashids.decode(this.$route.query.tId)[0];

    const { dependent } = this.$route.params;
    if (dependent && Object.entries(dependent).length > 0) {
      this.dependent = dependent;
    } else if (this.$route.params.id) {
      this.get(this.hashids.decode(this.$route.params.id)[0]);
    }
  },
  methods: {
    get(id) {
      this.$store
        .dispatch('dependent/one', id)
        .then((res) => {
          this.dependent = res;
        });
    },
  },
};
</script>
