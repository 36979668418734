import DependentService from '@/services/dependent.service';

const dependents = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    all(_, parameters) {
      return DependentService.all(parameters);
    },
    allLink(_, parameters) {
      return DependentService.allLink(parameters);
    },
    one(_, id) {
      return DependentService.one(id);
    },
    create(_, p) {
      return DependentService.create(p);
    },
    createLink(_, p) {
      return DependentService.createLink(p);
    },
    update(_, dependent) {
      return DependentService.update(dependent);
    },
    updateLink(_, dependent) {
      return DependentService.updateLink(dependent);
    },
    toggleActive(_, id) {
      return DependentService.toggleActive(id);
    },
  },

  getters: {},
};

export default dependents;
