<template>
  <v-container>
    <v-progress-linear indeterminate :active="loading" />
    <v-card class="elevation-1">
      <v-card-title>
        <span v-if="contratante && contratante.id" class="headline mb-0">
          {{ contratante.name }}
        </span>
        <span v-else class="headline mb-0">Novo Contratante</span>
      </v-card-title>

      <contratante-form
        v-model="contratante" :estipulanteId="estipulanteId" :subestipulanteId="subestipulanteId"
        :brokerId="brokerId"
        @cancel="edit = false" />

      <!-- <contratante-form-pf
        v-if="estipulante && estipulante.naturalPerson"
        v-model="contratante" :estipulanteId="estipulanteId" :subestipulanteId="subestipulanteId"
        @cancel="edit = false" />

      <contratante-form-pj
        v-else-if="estipulante && !estipulante.naturalPerson"
        v-model="contratante" :estipulanteId="estipulanteId" :subestipulanteId="subestipulanteId"
        @cancel="edit = false" /> -->

    </v-card>
  </v-container>
</template>

<script>
import ContratanteForm from '@/components/contratante/ContratanteForm';

const Hashids = require('hashids');

export default {
  components: {
    ContratanteForm,
    // ContratanteFormPj,
    // ContratanteFormPf,
  },
  data: () => ({
    hashids: null,
    loading: false,
    estipulanteId: null,
    subestipulanteId: null,
    brokerId: null,
    contratante: null,
    estipulante: null,
  }),
  created() {
    // eslint-disable-next-line new-cap
    this.hashids = new Hashids.default(
      process.env.VUE_APP_HASHID_SALT, Number(process.env.VUE_APP_HASHID_PAD),
    );

    // eslint-disable-next-line prefer-destructuring
    this.estipulanteId = this.hashids.decode(this.$route.query.eId)[0];
    this.getEstipulante();

    // eslint-disable-next-line prefer-destructuring
    this.subestipulanteId = this.hashids.decode(this.$route.query.sbId)[0];

    // eslint-disable-next-line prefer-destructuring
    this.brokerId = this.hashids.decode(this.$route.query.bs)[0];

    const { contratante } = this.$route.params;
    if (contratante && Object.entries(contratante).length > 0) {
      this.contratante = contratante;
    } else if (this.$route.params.id) {
      this.get(this.hashids.decode(this.$route.params.id)[0]);
    }
  },
  methods: {
    get(id) {
      this.loading = true;
      this.$store
        .dispatch('contratante/one', id)
        .then((contratante) => {
          this.contratante = contratante;
        }).finally(() => {
          this.loading = false;
        });
    },
    getEstipulante() {
      this.$store
        .dispatch('estipulante/one', this.estipulanteId)
        .then((estipulante) => {
          this.estipulante = estipulante;
        }).finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
