<template>
  <v-card class="elevation-1">
    <v-card-title>
      <span class="headline mb-0">Envio do Contrato</span>
    </v-card-title>

    <v-card-text>
      <v-row dense class="pa-0 ma-0 mt-4 mb-1 py-2">
        Escolha o arquivo para enviar:
      </v-row>
      <v-row dense class="pa-0 ma-0">
        <v-col
          xl="5" lg="5" md="5" sm="5"
          cols="12" class="pa-0 ma-0 mr-4">
          <v-file-input
            v-model="file"
            outlined dense prepend-icon="mdi-microsoft-excel"
            truncate-length="50"
            accept=".pdf"
            :disable="loading">
          </v-file-input>
        </v-col>
        <v-col
          xl="2" lg="2" md="" sm=""
          cols="12" class="pa-0 ma-0"
        >
          <v-btn
            color="primary" class="mb-2"
            @click="doUpload"
            :loading="loading"
            :disable="loading"
          >
            Enviar
            <v-icon right dark >
              mdi-upload
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
</v-card>
</template>

<script>
import bus from '@/plugins/eventBus';

export default {
  components: {
  },
  props: {
    contratanteId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    hashids: null,
    loading: false,
    file: null,
  }),
  created() {
  },
  methods: {
    doUpload() {
      this.loading = true;
      const formData = new FormData();
      formData.append('file', this.file);

      this.$store
        .dispatch('contratanteConfiguration/uploadContract', {
          contratanteId: this.contratanteId,
          file: formData,
        })
        .then(() => {
          bus.$emit('message', {
            message: 'Contrato enviado com sucesso!',
            type: 'success',
          });
        })
        .catch((err) => {
          console.log(err);
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        })
        .finally(async () => {
          this.loading = false;
        });
    },
  },
};
</script>
