<template>
  <v-dialog v-model="dialog" persistent :max-width="maxWidth">
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>

      <v-card-text>
        <slot />
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" text @click="cancel">
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn color="secondary" text @click="confirm">
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
    title: {
      type: String,
      default: 'Confirmar remoção?',
    },
    maxWidth: {
      type: String,
      default: '290',
    },
  },
  data: () => ({
    dialog: false,
  }),
  watch: {
    value(vl) {
      this.dialog = vl;
    },
  },
  created() {
    this.dialog = this.value;
  },
  destroyed() {
    this.dialog = false;
    this.$emit('input', false);
  },
  methods: {
    cancel() {
      this.dialog = false;
      this.$emit('input', false);
      this.$emit('cancel', false);
    },
    confirm() {
      this.dialog = false;
      this.$emit('input', false);
      this.$emit('confirm', true);
    },
  },
};
</script>
