var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-progress-linear',{attrs:{"indeterminate":"","active":_vm.loading}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.contratantes,"options":_vm.options,"server-items-length":_vm.total,"item-key":"id","sort-by":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{staticClass:"pa-0 ma-0 py-4 pb-6",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0 pl-4 pt-2",attrs:{"xl":"2","lg":"2","md":"2","sm":"2","cols":"12"}},[_c('mb-select',{attrs:{"items":_vm.verifieds,"item-text":"txt","item-value":"vl","name":"ctrnt_fltr_verified","label":"Verificados"},on:{"input":_vm.changeFilter},model:{value:(_vm.filter.verified),callback:function ($$v) {_vm.$set(_vm.filter, "verified", $$v)},expression:"filter.verified"}})],1),_c('v-col',{staticClass:"pa-0 ma-0 pl-4 pt-2",attrs:{"xl":"2","lg":"2","md":"2","sm":"2","cols":"12"}},[_c('mb-select',{attrs:{"items":_vm.contracts,"item-text":"txt","item-value":"vl","name":"ctrnt_fltr_contract","label":"Com Contrato"},on:{"input":_vm.changeFilter},model:{value:(_vm.filter.contract),callback:function ($$v) {_vm.$set(_vm.filter, "contract", $$v)},expression:"filter.contract"}})],1),_c('v-col',{staticClass:"pa-0 ma-0 pl-4 pt-2",attrs:{"xl":"2","lg":"2","md":"2","sm":"2","cols":"12"}},[_c('mb-select',{attrs:{"items":_vm.paymentsStatus,"item-text":"txt","item-value":"vl","name":"ctrnt_fltr_payment_status","label":"Status Fin."},on:{"input":_vm.changeFilter},model:{value:(_vm.filter.paymentStatus),callback:function ($$v) {_vm.$set(_vm.filter, "paymentStatus", $$v)},expression:"filter.paymentStatus"}})],1)],1)],1)]},proxy:true},{key:"item.document",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("document")(item.document))+" ")]}},{key:"item.mobile",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("phone")(item.mobile))+" ")]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("phone")(item.phone))+" ")]}},{key:"item.verified",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.$options.filters.verifiedIcon(item.verified)))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$options.filters.verified(item.verified)))])])]}},{key:"item.contractFile",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(_vm._s(_vm.$options.filters.contractIcon(item.contractFile)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$options.filters.contract(item.contractFile)))])])]}},{key:"item.paymentStatus",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.$options.filters.paymentStatusIcon(item.paymentStatus)))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$options.filters.paymentStatus(item.paymentStatus)))])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }