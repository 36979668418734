/* eslint-disable no-trailing-spaces */
<template>
    <v-card class="pa-2">
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-switch v-model="accept" label="ESTOU CIENTE QUE:" />
                </v-col>
                <v-col cols="12">
                    O CARTÃO ECONOCARD, caso contratado, prevê o prazo mínimo de
                    contratação de 12(meses), estando submetido a multa de até 6 (seis)
                    mensalidades na hipótese de cancelamento em prazo inferior.
                </v-col>
                <v-col cols="12">
                    * Estou pagando neste momento a TAXA DE ADESÃO juntamente com o valor
                    da PRIMEIRA MENSALIDADE.
                </v-col>
                <v-col cols="12">
                    * As demais mensalidades terão seus vencimentos no mesmo dia do mês
                    da data do pagamento da 1ª mensalidade.
                </v-col>
                <v-col cols="12">
                    * Uma vez que a efetivação do pagamento da Taxa de Adesão + 1ª Mensalidade
                    tenha sido identificada pelo ECONOCARD haverá um prazo de até 48 horas úteis
                    para que o CARTÃO ECONOCARD possa ser enviado por meio digital no endereço
                    eletrônico e/ou telefone celular cadastrado(s) e esteja liberado para
                    utilização.
                </v-col>
                <v-col cols="12">
                    * Os benefícios e descontos oferecidos pelo meu CARTÃO ECONOCARD relativos aos
                    serviços de saúde NÃO SE TRATAM DE PLANOS DE SAÚDE e portanto não são regulados
                    pela ANS (Agência Nacional de Saúde Suplementar).
                </v-col>
                <v-col cols="12">
                    * Os serviços utilizados junto à rede de parceiros ECONOCARD deverão ser pagos
                    diretamente aos fornecedores e/ou prestadores do serviço, sendo responsabilidade
                    do ECONOCARD unicamente a disponibilização dos benefícios e respectivos
                    descontos divulgados em seus canais oficiais de comunicação.
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-actions>
            <v-btn
                color="primary"
                @click="(accept) ? $emit('accept') : $emit('not-accept')"
            >
                Ok
            </v-btn>
        </v-card-actions>

    </v-card>
</template>

<script>

export default {
  props: {
    clear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      accept: false,
    };
  },
  watch: {
    clear() {
      this.accept = false;
    },
  },
};
</script>
