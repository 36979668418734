<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="name"
    :name="label"
    :mode="validationMode"
    :rules="rules"
  >
    <v-text-field
      :id="name"
      :ref="name"
      v-model="moneyVal"
      autocomplete="off"
      :label="label"
      :hint="hint"
      :persistent-hint="persistentHint"
      :placeholder="placeholder"
      :append-icon="appendicon"
      prefix="R$"
      type="text"
      :loading="loading"
      :disabled="disabled"
      outlined dense

      :error="errors.length > 0"
      :error-messages="errors[0]"
      @input="input"
      @click:append="changeType"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    validationMode: {
      type: String,
      default: 'lazy',
    },
    hint: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    appendicon: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: null,
    },
  },
  data: () => ({
    moneyVal: null,
  }),
  watch: {
    value(vl) {
      if (vl !== null) {
        this.moneyVal = (Number(vl) / 100).toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 });
      }
    },
  },
  created() {
    if (this.value != null) {
      this.moneyVal = (Number(this.value) / 100).toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 });
    }
  },
  methods: {
    input() {
      if (this.moneyVal.replace(',', '') !== Number(this.moneyVal.replace(',', ''))) {
        this.$emit('input', (Number(this.moneyVal.replace(',', '').replace('.', ''))));
      }
    },
    changeType() {
      this.$emit('input', (Number(this.moneyVal.replace(',', '').replace('.', ''))));
    },
  },
};
</script>
