import BrokersService from '@/services/brokers.service';

const brokers = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    one(_, id) {
      return BrokersService.one(id);
    },
    all(_, parameters) {
      return BrokersService.all(parameters);
    },
    create(_, parameters) {
      return BrokersService.create(parameters);
    },
    update(_, broker) {
      return BrokersService.update(broker);
    },
    remove(_, parameters) {
      return BrokersService.remove(parameters);
    },
  },

  getters: {},
};

export default brokers;
