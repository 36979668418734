<template>
  <v-container>
    <v-row class="py-4">
      <v-col
        xl="3" lg="3" md="3" sm="3"
        cols="12">
        <mb-select
          v-model="estipulante" :items="estipulantes"
          item-text="slug" name="sbcntte_fltr_estipulante" label="Estipulantes"
          :loading="selectEstipulanteLoading"
        />
      </v-col>

      <v-col
        xl="3" lg="3" md="3" sm="3"
        cols="12"
      >
        <mb-date
          v-model="dateStart"
          name="sbcntte_fltr_date_start"
          label="Data Inicial"
          :max="dateEnd"
        />
      </v-col>
      <v-col
        xl="3" lg="3" md="3" sm="3"
        cols="12"
      >
        <mb-date
          v-model="dateEnd"
          name="sbcntte_fltr_date_end"
          label="Data Final"
          rules="dateAfter:@sbcntte_fltr_date_end"
          :min="dateStart"
        />
      </v-col>

      <v-col>
        <v-btn
          color="primary" dark class="mb-2" :loading="exportLoading"
          @click="exportToExcel" :disabled="!estipulante"
        >
          Exportar
          <v-icon right dark>
            mdi-microsoft-excel
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>

      <v-expansion-panels multiple v-model="panel" class="mb-4">
        <v-expansion-panel>
          <v-expansion-panel-header class="secondary">Totais</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card elevation="0">
              <v-card-text>
                <totals :data="totalsData" />
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels>

        <v-expansion-panel>
          <v-expansion-panel-header class="secondary">Corretoras</v-expansion-panel-header>
          <v-expansion-panel-content>
            <broker-card :data="brokersData" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header class="secondary">Subestipulantes</v-expansion-panel-header>
          <v-expansion-panel-content>
            <subestipulante-card :data="subestipulantesData" />
          </v-expansion-panel-content>
        </v-expansion-panel>

      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import bus from '@/plugins/eventBus';
import MbSelect from '@/components/general/inputs/MbSelect';
import Totals from '@/components/reports/Totals';
import BrokerCard from '@/components/reports/BrokerCard';
import SubestipulanteCard from '@/components/reports/SubestipulanteCard';
import MbDate from '@/components/general/inputs/MbDate';

export default {
  components: {
    MbSelect,
    Totals,
    BrokerCard,
    SubestipulanteCard,
    MbDate,
  },
  data: () => ({
    loading: false,
    selectEstipulanteLoading: false,
    estipulantes: [],
    estipulante: null,
    dateStart: null,
    dateEnd: null,
    panel: [0],
    totalsData: null,
    brokersData: [],
    subestipulantesData: [],
    exportLoading: false,
  }),
  watch: {
    estipulante(vl) {
      if (vl) {
        this.loadData();
      }
    },
    dateStart(vl) {
      if (vl) {
        this.loadData();
      }
    },
    dateEnd(vl) {
      if (vl) {
        this.loadData();
      }
    },
  },
  created() {
    this.getEstipulantes().then(() => {
      this.dateStart = this.$moment().startOf('month').format('YYYY-MM-DD');
      this.dateEnd = this.$moment().endOf('month').format('YYYY-MM-DD');
    });
  },
  methods: {
    loadData() {
      this.getTotalsData();
      this.getBrokersData();
      this.getsubEstipulantesData();
    },
    getEstipulantes() {
      this.selectEstipulanteLoading = true;

      return this.$store.dispatch('estipulante/all', {
        pagination: { page: 1, size: -1 },
      }).then((resp) => {
        this.estipulantes = resp.data.slice(0, 1); // TODO - REMOVE

        if (this.estipulantes.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          this.estipulante = this.estipulantes[0];
        }
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.selectEstipulanteLoading = false;
      });
    },
    getTotalsData() {
      this.$store.dispatch('report/totals', {
        estipulanteId: this.estipulante.id,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      })
        .then((resp) => {
          this.totalsData = resp;
        }).catch((err) => {
          console.log(err);
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.loading = false;
        });
    },
    getBrokersData() {
      this.$store.dispatch('report/brokers', {
        estipulanteId: this.estipulante.id,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      })
        .then((resp) => {
          this.brokersData = resp;
        }).catch((err) => {
          console.log(err);
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.loading = false;
        });
    },
    getsubEstipulantesData() {
      this.$store.dispatch('report/subestipulantes', {
        estipulanteId: this.estipulante.id,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      })
        .then((resp) => {
          this.subestipulantesData = resp;
        }).catch((err) => {
          console.log(err);
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.loading = false;
        });
    },
    exportToExcel() {
      this.exportLoading = true;
      this.$store.dispatch('report/exportExcel', {
        estipulanteId: this.estipulante.id,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }).then((resp) => {
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${resp}`;
        const downloadLink = document.createElement('a');
        const fileName = `comissoes-${this.$moment().format('DDMMYY_HH-mm-ss')}.xlsx`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.exportLoading = false;
      });
    },
  },
};
</script>
