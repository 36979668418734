import { pg, filter, onlyFilter } from '../utils/query';
import HTTP from './axios';

const LeadService = {
  all: (p) => HTTP.get(`/lead/contratante?${pg(p)}${filter(p)}`).then((response) => response.data),
  create: (lead) => HTTP.post('/lead/create/contratante', lead)
    .then((response) => response.data),
  exportExcel: (p) => HTTP.post(`/lead/excel/contratante${onlyFilter(p)}`).then((response) => response.data),
};

export default LeadService;
