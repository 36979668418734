<template>
  <v-menu
    :ref="name"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
    :color="color"
    :dark="dark"
  >
    <template v-slot:activator="{ on }">
      <ValidationProvider
        v-slot="{ errors }" :name="label" :mode="validationMode"
        :rules="rules"
      >
        <v-text-field
          :id="name"
          v-model="dateFormatted"
          v-mask="'##/##/####'"
          autocomplete="off"
          :data-vv-as="name"
          :label="label"
          :hint="hint"
          :persistent-hint="persistentHint"
          :placeholder="placeholder"
          :disabled="disabled"
          append-icon="mdi-calendar" outlined
          dense
          :error="errors.length > 0"
          :error-messages="errors[0]"
          @blur="blur"
          v-on="on"
        />
      </ValidationProvider>
    </template>
    <v-date-picker
      v-model="internalValue"
      :allowed-dates="allowedDates"
      :min="min"
      :disabled="disabled"
      no-title
      locale="pt-br"
      @input="menu = false"
    />
  </v-menu>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mask } from 'vue-the-mask';

export default {
  directives: { mask },
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    validationMode: {
      type: String,
      default: 'lazy',
    },
    hint: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    persistentHint: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    allowedDates: {
      type: Function,
      default: null,
    },
    min: {
      type: String,
      default: null,
    },
    rules: {
      type: [String, Object],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
    dark: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    menu: false,
    internalValue: null,
    dateFormatted: '',
    date: null,
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    internalValue() {
      this.dateFormatted = this.formatDate(this.internalValue);
      this.$emit('input', this.internalValue);
    },
    value(vl) {
      this.internalValue = vl;
      this.dateFormatted = this.formatDate(this.internalValue);
    },
  },
  created() {
    this.dateFormatted = '';
    if (this.value) {
      this.internalValue = this.value;
      this.dateFormatted = this.parseDate(this.value);
    }
  },
  methods: {
    blur() {
      this.internalValue = this.parseDate(this.dateFormatted);
      this.$emit('input', this.internalValue);
    },
    formatDate(date) {
      if (!date) return null;
      return this.$moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    parseDate(date) {
      if (!date) return null;
      return this.$moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    },
  },
};
</script>
