function verificacaoGeral(cnpj) {
  const excludeArray = [
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999',
  ];

  if (cnpj === '') return false;
  if (cnpj.length !== 14) return false;
  if (excludeArray.some((o) => cnpj === o)) return false;

  return true;
}

function verificarDigito(cnpj, pesos) {
  const numbers = cnpj.split('').slice(0, pesos.length);
  // Soma numeros do CNPJ baseado nos pesos
  const acumuladora = numbers.reduce(
    (anterior, atual, index) => anterior + (atual * pesos[index]), 0,
  );
  const resto = acumuladora % 11;
  const digito = resto < 2 ? 0 : 11 - resto;
  return parseInt(cnpj[pesos.length], 10) === digito;
}

function validate(cnpj) {
  const cnpjN = cnpj.replace(/[^\d]+/g, '');

  const pesosDigito1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  const pesosDigito2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  return verificacaoGeral(cnpjN)
    && verificarDigito(cnpjN, pesosDigito1)
    && verificarDigito(cnpjN, pesosDigito2);
}

export default validate;
