import BankAccountService from '@/services/bankAccount.service';

const bankAccounts = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    one(_, id) {
      return BankAccountService.one(id);
    },
    create(_, p) {
      return BankAccountService.create(p);
    },
    update(_, bankAccount) {
      return BankAccountService.update(bankAccount);
    },
  },

  getters: {},
};

export default bankAccounts;
