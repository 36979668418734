<template>
  <v-container>
    <v-progress-linear indeterminate :active="loading" />
    <v-data-table
      :headers="headers"
      :items="beneficiaries"
      :options.sync="options"
      :server-items-length="total"
      item-key="id"
      sort-by="id"
      class="elevation-1"
      :loading="loadingGrid"
    >
      <template v-slot:top>
        <v-container fluid class="pa-0 ma-0 py-4 pb-6">
          <!-- Fields -->
          <v-row class="pa-0 ma-0" v-if="showFilter">
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-select
                v-model="estipulante" :items="estipulantes"
                item-text="slug" name="bnfc_fltr_estipulante" label="Estipulantes"
                :loading="selectEstipulanteLoading"
              />
            </v-col>
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-select
                v-model="subestipulante" :items="subestipulantes"
                clearable @click-clear="clearSubestipulante"
                item-text="slug" name="bnfc_fltr_subestipulante" label="Subestipulantes"
                :loading="selectSubestipulanteLoading"
              />
            </v-col>
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-select
                v-model="contratante" :items="contratantes"
                clearable @click-clear="clearContratantes"
                item-text="slug" name="bnfc_fltr_contratante" label="Contratantes"
                :loading="selectCompanyLoading"
              />
              {{contratantes.size}}
            </v-col>
          </v-row>
          <!-- Buttons -->
          <v-row class="pa-0 ma-0 pt-3">
            <v-col
              xl="1" lg="1" md="1" sm="1"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
              v-if="contratante && contratante.type == 'pj'"
            >
              <v-btn
                color="primary" dark class="mb-2"
                @click="handleNew"
                :disabled="contratanteId == null"
              >
                Novo
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:[`item.birthdate`]="{ item }">
        {{ item.birthdate | date }}
      </template>

      <template v-slot:[`item.countryRegister`]="{ item }">
        {{ item.countryRegister | document }}
      </template>

      <template v-slot:[`item.mobile`]="{ item }">
        {{ item.mobile | phone }}
      </template>

      <template v-slot:[`item.work`]="{ item }">
        {{ item.work | phone }}
      </template>

      <template v-slot:[`item.beneficiaryActive`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>{{ $options.filters.activeIcon(item.beneficiaryActive) }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $options.filters.active(item.beneficiaryActive) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="handleEdit(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip left v-if="contratante && contratante.allowsDependent">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="handleDependents(item)">
              <v-icon>mdi-account-multiple</v-icon>
            </v-btn>
          </template>
          <span>Dependentes</span>
        </v-tooltip>
        <v-tooltip
          left
          v-if="$moment().startOf('day').diff($moment(item.hiredAt, 'YYYY-MM-DD'), 'year') >= 1"
        >
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="objOnConfirm = item; confirmActive = true;">
              <v-icon>{{ (item.beneficiaryActive) ? 'mdi-cancel' : 'mdi-check-bold' }}</v-icon>
            </v-btn>
          </template>
          <span>
            {{ (item.beneficiaryActive) ? 'Desativar' : 'Ativar' }}
          </span>
        </v-tooltip>
        <v-tooltip left v-if="handleDimissed && (contratante && contratante.type == 'pj')">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click="objOnConfirm = item; confirmDismiss = true;"
            >
              <v-icon>{{ (item.dismissed) ? 'mdi-account-off' : 'mdi-account' }}</v-icon>
            </v-btn>
          </template>
          <span>
            {{ (item.dismissed) ? 'Demitido' : 'Admitido' }}
          </span>
        </v-tooltip>
        <!-- <v-tooltip left v-if="user && ['medibook'].includes(user.loginType)">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="handleChangeType(item)">
              <v-icon>mdi-swap-horizontal</v-icon>
            </v-btn>
          </template>
          <span>
            Transformar Beneficiário
          </span>
        </v-tooltip> -->
      </template>
    </v-data-table>

    <confirmation-dialog
      v-model="confirmActive" title="Confirmação"
      @confirm="handleConfirm('active')" v-if="objOnConfirm">
      <div>
        {{
          `Deseja ${(objOnConfirm.beneficiaryActive) ? 'desativar': 'ativar'} o beneficiário`
        }}
        <b>{{ objOnConfirm.name }}</b>
      </div>
    </confirmation-dialog>

    <confirmation-dialog
      v-model="confirmDismiss" title="Confirmação"
      @confirm="handleConfirm('dimiss')" v-if="objOnConfirm">
      <div>
        {{
          `Confirma ${(objOnConfirm.dimissed) ? 'admissão': 'demissão'} do beneficiário`
        }}
        <b>{{ objOnConfirm.name }}</b>
      </div>
    </confirmation-dialog>

  </v-container>
</template>

<script>
import bus from '@/plugins/eventBus';
import MbSelect from '@/components/general/inputs/MbSelect';
import ConfirmationDialog from '@/components/general/ConfirmationDialog';

const Hashids = require('hashids');

export default {
  components: {
    MbSelect,
    ConfirmationDialog,
  },
  props: {
    handleDimissed: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    Hashids: null,
    total: 0,
    edit: false,
    headers: [
      {
        text: 'Nome', align: 'start', sortable: false, value: 'name',
      },
      { text: 'Dt. Nascimento', value: 'birthdate' },
      { text: 'Email', value: 'email' },
      { text: 'Documento', value: 'countryRegister' },
      { text: 'Celular', value: 'mobile' },
      { text: 'Telefone', value: 'work' },
      { text: 'Ativo?', value: 'beneficiaryActive' },

      { text: 'Ações', value: 'actions' },
    ],
    options: {
      itemsPerPage: 10,
    },
    showFilter: true,
    beneficiaries: [],
    formDialog: false,
    beneficiary: null,
    estipulante: null,
    estipulantes: [],
    subestipulante: null,
    subestipulantes: [],
    contratanteId: null,
    contratante: null,
    contratantes: [],
    confirmActive: false,
    confirmDismiss: false,
    objOnConfirm: null,
    loadingGrid: false,
    loading: false,
    selectEstipulanteLoading: false,
    selectSubestipulanteLoading: false,
    selectCompanyLoading: false,
    user: null,
  }),
  computed: {
  },
  watch: {
    options: {
      handler() {
        if (this.options.page != null && this.options.itemsPerPage != null && !this.contratanteId) {
          this.getBeneficiaries();
        }
      },
      deep: true,
    },
    estipulante(vl) {
      this.subestipulantes = [];
      this.subestipulante = null;
      this.contratantes = [];
      this.contratante = null;
      if (vl != null) {
        this.getSubestipulantes();
        this.getContratantes();
      }
    },
    subestipulante(vl) {
      this.contratantes = [];
      this.contratante = null;
      if (vl != null) {
        this.getContratantes();
      }
    },
    contratante(vl) {
      if (vl != null) {
        this.contratanteId = vl.id;
      }
    },
    contratanteId(vl) {
      this.beneficiaries = [];
      if (vl != null) {
        this.getBeneficiaries();
      }
    },
  },
  beforeMount() {
    this.getEstipulantes();
  },
  created() {
    this.user = this.$store.getters['user/getUser'];
    this.showFilter = (this.user != null && ['brokers', 'medibook'].includes(this.user.loginType));
    // console.log(this.user.company);

    if (!this.showFilter) {
      this.contratante = this.user.company;
      this.contratanteId = this.user.company.id;
      // console.log('set contratante');
      // this.getEstipulante();
    }

    // eslint-disable-next-line new-cap
    this.Hashids = new Hashids.default(
      process.env.VUE_APP_HASHID_SALT, Number(process.env.VUE_APP_HASHID_PAD),
    );
  },
  methods: {
    clearSubestipulante() {
      this.subestipulante = null;
      this.contratantes = [];
      this.contratante = null;
      this.beneficiaries = [];
      this.getContratantes();
    },
    clearContratantes() {
      this.contratante = null;
      this.beneficiaries = [];
    },
    getBeneficiaries() {
      if (this.contratanteId != null) {
        this.loading = true;
        this.$store.dispatch('beneficiary/allByContratante', {
          contratanteId: this.contratanteId,
          pagination: { page: this.options.page, size: this.options.itemsPerPage },
        }).then((resp) => {
          this.total = resp.meta.total;
          this.beneficiaries = resp.data;
        }).catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    created() {
      setTimeout(() => {
        this.formDialog = false;
        this.beneficiary = null;
      }, 1000);
      this.getBeneficiaries();
    },
    updated() {
      setTimeout(() => {
        this.formDialog = false;
        this.beneficiary = null;
      }, 1000);

      this.getBeneficiaries();
    },
    handleNew() {
      this.$router.push({ name: 'beneficiary-cad', query: { cId: this.Hashids.encode(this.contratanteId) } });
    },
    handleEdit(item) {
      this.$router.push({
        name: 'beneficiary-cad',
        params: { id: this.Hashids.encode(item.id), beneficiary: item },
        query: { cId: this.Hashids.encode(this.contratanteId) },
      });
    },
    handleConfirm(type) {
      if (type === 'dimiss') {
        this.toggleDimissed();
      } else if (type === 'active') {
        this.toggleActive();
      }
    },
    toggleActive() {
      this.loadingGrid = true;
      this.$store
        .dispatch('beneficiary/toggleActive', this.objOnConfirm.id)
        .then(() => {
          bus.$emit('message', {
            message: `Beneficiário ${(this.objOnConfirm.beneficiaryActive) ? 'desativado' : 'ativado'} com sucesso!`,
            type: 'success',
          });
          this.getBeneficiaries();
        })
        .catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        })
        .finally(() => {
          this.loadingGrid = false;
          this.objOnConfirm = null;
        });
    },
    toggleDimissed() {
      if (!this.handleDimissed) return;

      this.loadingGrid = true;
      this.$store
        .dispatch('beneficiary/toggleDimissed', this.objOnConfirm.id)
        .then(() => {
          bus.$emit('message', {
            message: 'Admissão do beneficiário alterada com sucesso!',
            type: 'success',
          });
          this.getBeneficiaries();
        })
        .catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        })
        .finally(() => {
          this.loadingGrid = false;
          this.objOnConfirm = null;
        });
    },
    handleDependents(item) {
      this.$router.push({
        name: 'dependent-list',
        query: { id: this.Hashids.encode(item.id) },
      });
    },
    // handleChangeType(item) {
    //   this.$router.push({
    //     name: 'change-type',
    //     // params: { id: this.Hashids.encode(item.id), beneficiary: item },
    //     query: { bId: this.Hashids.encode(item.id) },
    //   });
    // },
    getEstipulante() {
      if (!this.showFilter) {
        this.selectEstipulanteLoading = true;
        this.$store.dispatch('estipulante/one', this.user.company.estipulanteId).then((resp) => {
          this.estipulante = resp;
        }).catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.selectEstipulanteLoading = false;
        });
      }
    },
    getEstipulantes() {
      if (this.showFilter) {
        this.selectEstipulanteLoading = true;
        this.$store.dispatch('estipulante/all', {
          pagination: { page: 1, size: -1 },
        }).then((resp) => {
          this.estipulantes = resp.data;
        }).catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.selectEstipulanteLoading = false;
        });
      }
    },
    getSubestipulantes() {
      if (this.estipulante != null) {
        this.selectSubestipulanteLoading = true;
        this.$store.dispatch('subestipulante/all', {
          id: this.estipulante.id,
          pagination: { page: 1, size: -1 },
        }).then((resp) => {
          this.subestipulantes = resp.data;
        }).catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.selectSubestipulanteLoading = false;
        });
      }
    },
    getContratantes() {
      let params = null;
      if (this.estipulante != null) {
        params = { estipulanteId: this.estipulante.id };
      }

      let method = 'contratante/allByEstipulante';
      if (this.estipulante !== null && this.subestipulante != null) {
        method = 'contratante/allBySubestipulante';
        params.subestipulanteId = this.subestipulante.id;
      }

      if (this.user && this.user.type === 'brokers') {
        params.brokerId = this.user.id;
      }

      if (params) {
        params.pagination = { page: 1, size: -1 };
        this.$store.dispatch(method, params).then((resp) => {
          this.total = resp.meta.total;
          this.contratantes = resp.data;
        }).catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    cancel() {
      this.formDialog = false;
      this.beneficiary = null;
    },
  },
};
</script>
