import HTTP from './axios';

const DependentService = {
  all: (id) => HTTP.get(`/beneficiary/${id}/dependent`).then((response) => response.data),
  allLink: (id) => HTTP.get(`/dependent/titular/link?titularId=${id}`).then((response) => response.data),
  one: (id) => HTTP.get(`/beneficiary/${id}`).then((response) => response.data),
  create: (p) => HTTP.post(`/beneficiary/${p.titularId}/dependent`, p.dependent)
    .then((response) => response.data),
  createLink: (p) => {
    const query = `?id=${p.titularId}`;
    return HTTP.post(`/dependent/link${query}`, p.dependent)
      .then((response) => response.data);
  },
  update: (dependent) => HTTP.put(`/dependent/${dependent.id}`, dependent)
    .then((response) => response.data),
  updateLink: (p) => {
    const query = `?id=${p.titularId}`;
    return HTTP.put(`/dependent/link${query}`, p.dependent)
      .then((response) => response.data);
  },
  toggleActive: (id) => HTTP.put(`/dependent/${id}/toggleActive`)
    .then((response) => response.data),
};

export default DependentService;
