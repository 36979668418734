import bus from '@/plugins/eventBus';
import UserService from '../services/user.service';
import HTTP from '../services/axios';

const auth = {
  namespaced: true,

  state: {
    user: null,
    token: null,
    renewToken: localStorage.getItem('mbecrt'),
    userActivated: null,
    expiresIn: null,
  },

  mutations: {
    LOGIN(state, payload) {
      state.user = payload.user;
      state.token = payload.token;
      state.expiresIn = payload.expiresIn;
      state.renewToken = payload.renewToken;
      state.userActivated = payload.userActivated;
      localStorage.setItem('mbecrt', payload.renewToken);
      localStorage.setItem('mbeclogin', '1');
    },
    LOGOUT(state) {
      localStorage.removeItem('mbecrt');
      state.renewToken = null;
      state.token = null;
      state.expiresIn = null;
      state.userActivated = null;
      localStorage.setItem('mbeclogin', '0');
    },
    UPDATE_USER(state, payload) {
      state.user = payload;
    },
    UPDATE_USER_ACTIVATED(state, vl) {
      state.userActivated = vl;
    },
    RENEW(state, payload) {
      state.user = payload.user;
      state.token = payload.token;
      state.expiresIn = payload.expiresIn;
      state.renewToken = payload.renewToken;
      localStorage.setItem('mbecrt', payload.renewToken);
    },
  },

  actions: {
    reload({ commit, state }) {
      return UserService.one(state.user.id).then((user) => {
        commit('UPDATE_USER', user);
      });
    },
    oneByCountryRegister(_, countryRegister) {
      return UserService.oneByCountryRegister(countryRegister);
    },
    oneByCnpj(_, cnpj) {
      // TODO - REMOVE
      return UserService.oneByCnpj(cnpj);
    },
    login({ commit, state }, parameters) {
      return UserService.login(parameters).then((resp) => {
        HTTP.interceptors.request.use((config) => {
          const cfg = config;
          cfg.headers.token = state.token;
          return cfg;
        });

        commit('LOGIN', resp);
        // if (resp.user.type !== 'medibook') {
        //   commit('company/SET_COMPANY_ID', resp.user.company.id, { root: true });
        // }
        bus.$emit('login', resp.expiresIn);

        return resp;
      });
    },
    changePassword(_, parameters) {
      return UserService.changePassword(parameters);
    },
    recoverPassword(_, phoneOrEmailOrDoc) {
      return UserService.recoverPassword(phoneOrEmailOrDoc);
    },
    resetPassword(_, { code, phoneOrEmailOrDoc, password }) {
      return UserService.resetPassword(code, phoneOrEmailOrDoc, password);
    },
    logout({ commit }) {
      commit('LOGOUT');
    },
    updateUser({ commit }, user) {
      commit('UPDATE_USER', user);
    },
    renew({ commit, state }) {
      if (!state.renewToken) {
        return null;
      }
      return UserService.renew(state.renewToken).then((resp) => {
        HTTP.interceptors.request.use((config) => {
          const cfg = config;
          cfg.headers.token = state.token;
          return cfg;
        });

        commit('RENEW', resp);
        bus.$emit('renew', resp.expiresIn);
        return resp;
      }).catch((err) => {
        console.log(err);
      });
    },
    updateUserActivated({ commit }, vl) {
      commit('UPDATE_USER_ACTIVATED', vl);
    },
    verifyCNPJ(_, p) {
      return UserService.verifyCNPJ(p);
    },
  },

  getters: {
    getUser(state) {
      return state.user;
    },
    isAuthenticated(state) {
      return !!state.token;
    },
    isUserActivated(state) {
      return state.userActivated;
    },
    needRenew(state) {
      return (!state.token && state.renewToken != null);
    },
  },
};

export default auth;
