<template>
  <v-container>
    <v-progress-linear indeterminate :active="loading" />
    <!-- {{beneficiaries}} -->
    <v-data-table
      :headers="headers"
      :items="beneficiaries"
      :options.sync="options"
      :server-items-length="totalRecords"
      item-key="id"
      sort-by="id"
      class="elevation-1"
      :loading="loadingGrid"
    >
      <template v-slot:top>
        <v-container fluid class="pa-0 ma-0 py-4 pb-6">
          <!-- Fields -->
          <v-row class="pa-0 ma-0">
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-select
                v-model="filter.estipulanteId" :items="estipulantes"
                item-text="slug" item-value="id"
                name="bnfc_fltr_estipulante" label="Estipulantes"
                :loading="selectEstipulanteLoading"
              />
            </v-col>
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-select
                v-model="filter.subestipulanteId" :items="subestipulantes"
                clearable @click-clear="clearSubestipulante"
                item-text="slug" item-value="id"
                name="bnfc_fltr_subestipulante" label="Subestipulantes"
                :loading="selectSubestipulanteLoading"
              />
            </v-col>
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-input-text
                v-model="filter.searchstr" name="bnfc_fltr_searchstr" label="Nome/Documento"
                @input="changeFilterSearchstr"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <v-icon v-if="(item.type == 'titular')">
          {{ $options.filters.activeIcon(item.beneficiaryActive) }}
        </v-icon>
        <span v-else></span>
      </template>

      <template v-slot:[`item.birthdate`]="{ item }">
        {{ item.birthdate | date }}
      </template>

      <template v-slot:[`item.countryRegister`]="{ item }">
        {{ item.countryRegister | document }}
      </template>

      <template v-slot:[`item.mobile`]="{ item }">
        {{ item.mobile | phone }}
      </template>

      <template v-slot:[`item.work`]="{ item }">
        {{ item.work | phone }}
      </template>

      <template v-slot:[`item.contratanteType`]="{ item }">
        {{$options.filters.personType(item.contratanteType) }}
      </template>

      <template v-slot:[`item.beneficiaryActive`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>{{ $options.filters.activeIcon(item.beneficiaryActive) }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $options.filters.active(item.beneficiaryActive) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.contratantePaymentStatus`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>
                {{ $options.filters.paymentStatusIcon(item.contratantePaymentStatus) }}
                </v-icon>
            </v-btn>
          </template>
          <span>{{ $options.filters.paymentStatus(item.contratantePaymentStatus) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="handleChangeType(item)">
              <v-icon>mdi-swap-horizontal</v-icon>
            </v-btn>
          </template>
          <span>
            Transformar Beneficiário
          </span>
        </v-tooltip>
      </template>

    </v-data-table>

  </v-container>
</template>

<script>
import bus from '@/plugins/eventBus';
import _ from 'lodash';
import MbInputText from '@/components/general/inputs/MbInputText';
import MbSelect from '@/components/general/inputs/MbSelect';

const Hashids = require('hashids');

export default {
  components: {
    MbInputText,
    MbSelect,
  },
  props: {
    handleDimissed: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    Hashids: null,
    headers: [
      {
        text: 'Nome', align: 'start', sortable: false, value: 'name',
      },
      { text: 'Titular', value: 'type' },
      { text: 'Dt. Nascimento', value: 'birthdate' },
      // { text: 'Email', value: 'email' },
      { text: 'Documento', value: 'countryRegister' },
      { text: 'Celular', value: 'mobile' },
      { text: 'Telefone', value: 'work' },
      { text: 'Contratante', value: 'contratanteName' },
      { text: 'Tipo Contratante', align: 'center', value: 'contratanteType' },
      { text: 'Status Pgto', align: 'center', value: 'contratantePaymentStatus' },
      { text: 'Ações', value: 'actions' },
    ],
    totalRecords: 0,
    options: {},
    estipulantes: [],
    subestipulantes: [],
    filter: {
      estipulanteId: null,
      subestipulanteId: null,
      searchstr: null,
    },
    beneficiaries: [],
    loadingGrid: false,
    selectEstipulanteLoading: false,
    selectSubestipulanteLoading: false,
    loading: false,
  }),
  computed: {
  },
  watch: {
    options: {
      handler() {
        if (this.options.page != null && this.options.itemsPerPage != null) {
          this.getBeneficiaries();
        }
      },
      deep: true,
    },
    // eslint-disable-next-line func-names
    'filter.estipulanteId': function (vl) {
      this.subestipulantes = [];
      this.subestipulante = null;
      this.beneficiaries = [];
      if (vl != null) {
        this.getSubestipulantes();
      }
      this.getBeneficiaries();
    },
    // eslint-disable-next-line func-names
    'filter.subestipulanteId': function () {
      this.beneficiaries = [];
      this.getBeneficiaries();
    },
  },
  beforeMount() {
    // eslint-disable-next-line new-cap
    this.Hashids = new Hashids.default(
      process.env.VUE_APP_HASHID_SALT, Number(process.env.VUE_APP_HASHID_PAD),
    );

    this.getEstipulantes();
  },
  methods: {
    getEstipulantes() {
      this.selectEstipulanteLoading = true;
      this.$store.dispatch('estipulante/all', {
        pagination: { page: 1, size: -1 },
      }).then((resp) => {
        this.estipulantes = resp.data;
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.selectEstipulanteLoading = false;
      });
    },
    getSubestipulantes() {
      if (this.filter.estipulanteId != null) {
        this.selectSubestipulanteLoading = true;
        this.$store.dispatch('subestipulante/all', {
          id: this.filter.estipulanteId,
          pagination: { page: 1, size: -1 },
        }).then((resp) => {
          this.subestipulantes = resp.data;
        }).catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.selectSubestipulanteLoading = false;
        });
      }
    },
    getBeneficiaries() {
      // console.log(`this.filter: ${JSON.stringify(this.filter)}`);
      this.loading = true;
      this.$store.dispatch('beneficiary/allTitularWithContratante', {
        pagination: { page: this.options.page, size: this.options.itemsPerPage },
        filter: this.filter,
      }).then((resp) => {
        this.totalRecords = resp.meta.total;
        this.beneficiaries = resp.data;
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    handleChangeType(item) {
      this.$router.push({
        name: 'change-type',
        // params: { id: this.Hashids.encode(item.id), beneficiary: item },
        query: { bId: this.Hashids.encode(item.id) },
      });
    },
    clearSubestipulante() {
      this.subestipulante = null;
      this.beneficiaries = [];
      this.getBeneficiaries();
    },
    cancel() {
      this.formDialog = false;
      this.beneficiary = null;
    },
    changeFilterSearchstr: _.debounce(function () {
      // if ((vl && vl.length > 2) || (!vl || vl.length === 0)) {
      this.getBeneficiaries();
      // }
    }, 700),
  },
};
</script>
