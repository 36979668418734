import ContratanteService from '@/services/contratante.service';

const contratantes = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    one(_, id) {
      return ContratanteService.one(id);
    },
    oneLink(_, p) {
      return ContratanteService.oneLink(p);
    },
    allByEstipulante(_, parameters) {
      return ContratanteService.allByEstipulante(parameters);
    },
    allBySubestipulante(_, parameters) {
      return ContratanteService.allBySubestipulante(parameters);
    },
    createEstipulante(_, p) {
      return ContratanteService.createEstipulante(p);
    },
    createSubestipulante(_, p) {
      return ContratanteService.createSubestipulante(p);
    },
    createFromLink(_, p) {
      return ContratanteService.createFromLink(p);
    },
    createFromSite(_, p) {
      return ContratanteService.createFromSite(p);
    },
    changePjToPf(_, p) {
      return ContratanteService.changePjToPf(p);
    },
    changePfToPj(_, p) {
      return ContratanteService.changePfToPj(p);
    },
    update(_, contratante) {
      return ContratanteService.update(contratante);
    },
    alterSubestipulante(_, parameters) {
      return ContratanteService.alterSubestipulante(parameters);
    },
    downloadContract(_, parameters) {
      return ContratanteService.downloadContract(parameters);
    },
    uploadContract(_, parameters) {
      return ContratanteService.uploadContract(parameters);
    },
    pjPendents(_, parameters) {
      return ContratanteService.pjPendents(parameters);
    },
  },

  getters: {},
};

export default contratantes;
