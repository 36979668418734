<template>
  <div @click="handleIdleTime">
    <v-app v-if="isAuthenticated" id="app" v-resize="onResize">
      <v-navigation-drawer
        v-model="drawer.model"
        :clipped="drawer.clipped"
        :floating="drawer.floating"
        :mini-variant="drawer.mini"
        :permanent="drawer.type === 'permanent'"
        :temporary="drawer.type === 'temporary'"
        app
        absolute
        color="primary"
        dark
      >
        <v-list nav>
          <v-list-item v-if="$vuetify.breakpoint.xsOnly" to="/" class="py-2">
            <img
              src="@/assets/mb-econocard.png"
              contain
              max-width="224"
              max-height="52"
              position="center center"
            >
          </v-list-item>

          <v-divider v-if="$vuetify.breakpoint.xsOnly" />

          <div v-for="item in items" :key="item.title">
            <!-- simple item -->
            <div v-if="!item.items">
              <v-list-item @click="handleItemClick(item)">
                <!-- v-if="verifyPerm(item)"  -->
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </div>

            <!-- item group -->
            <div v-else>
              <div>
                 <!-- v-if="verifyPerm(item)" -->
                <v-list-group
                  v-model="item.active"
                  :prepend-icon="item.icon"
                  :append-icon="(!item.items) ? null : '$expand'"
                  no-action
                  color="secondary"
                >
                  <template v-slot:activator>
                    <v-list-item-content @click="handleItemClick(item)">
                      <v-list-item-title v-text="item.title" />
                    </v-list-item-content>
                  </template>

                  <div v-for="subItem in item.items" :key="subItem.title">
                    <div>
                       <!-- v-if="verifyPerm(item)" -->
                      <v-list-item
                        @click="handleItemClick(subItem)"
                      >
                        <v-list-item-title v-text="subItem.title" />
                        <v-list-item-icon>
                          <v-icon>{{ subItem.icon }}</v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                    </div>
                  </div>
                </v-list-group>
              </div>
            </div>
          </div>
        </v-list>
      </v-navigation-drawer>

      <!-- App bar -->
      <v-app-bar :clipped-left="drawer.clipped" app>
        <!-- Menu icon and Logo -->
        <v-app-bar-nav-icon
          v-if="drawer.type !== 'permanent'"
          @click.stop="drawer.model = !drawer.model"
        />

        <v-img
          v-if="$vuetify.breakpoint.smAndUp"
          src="@/assets/mb-econocard.png"
          to="/"
          contain
          width="240"
          aspect-ratio="1.2"
          max-width="240"
          max-height="55"
        />

        <v-spacer />

        <div class="blue-grey--text" :class="[$vuetify.breakpoint.xsOnly ? 'caption' : 'body-1']">
          {{ $route.meta.desc }}
        </div>

        <v-spacer />
        <!-- Left icons -->
        <div>
          <v-menu bottom left nudge-bottom="50">
            <template v-slot:activator="{ on }">
              <v-avatar v-on="on">
                <img :key="user.updatedAt" :src="user.avatar">
              </v-avatar>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-title>{{ user.name }}</v-list-item-title>
              </v-list-item>

              <v-divider />

              <v-list-item
                v-for="(item, i) in profileMenuItems"
                :key="i"
                @click="handleProfileMenuClick(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-app-bar>
      <!-- Content -->
      <v-main>
        <!-- <v-container fluid pa-0> -->
        <router-view />
        <!-- </v-container> -->
      </v-main>
    </v-app>

    <v-app v-else id="app" v-resize="onResize">
      <v-main fluid pa-0>
        <router-view />
      </v-main>
    </v-app>

    <!-- Snackbar -->
    <v-app v-if="snackbar.show">
      <v-snackbar v-model="snackbar.show" :color="snackbar.color" bottom>
        {{ snackbar.message }}
        <v-btn text @click="snackbar.show = false">
          Fechar
        </v-btn>
      </v-snackbar>
    </v-app>

    <!-- Change Password -->
    <v-app v-if="changePasswordDialog">
      <v-dialog v-model="changePasswordDialog" max-width="350">
        <change-password-card
          @cancel="changePasswordDialog = false"
          @save="changePasswordDialog = false"
        />
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import bus from '@/plugins/eventBus';
import getMenu from '@/utils/menu';
import ChangePasswordCard from '@/components/general/ChangePasswordCard';
import { resetRouter } from './router';

export default {
  components: {
    ChangePasswordCard,
  },
  data: () => ({
    drawer: {
      model: null,
      type: 'permanent',
      clipped: true,
      floating: false,
    },
    items: [],
    footer: {
      inset: false,
    },
    snackbar: {
      show: false,
      message: null,
      color: 'success',
    },
    changePasswordDialog: false,
    idleTimeTimer: null,
    renewTimer: null,
    profileMenuItems: [
      { name: 'changepass', text: 'Alterar Senha' },
      // { name: 'terms', text: 'Termos de Uso' },
      // { name: 'privacyPolicy', text: 'Política de Privacidade' },
      { name: 'sair', text: 'Sair' },
    ],
  }),
  sockets: {
    connect() {
      this.$socket.client.emit('authenticate', { token: process.env.VUE_APP_SOCKET_SECRET });
    },
    unauthorized() {
      // console.error(`SOCKET - unauthorized`);
    },
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['user/isAuthenticated'];
    },
    user() {
      return this.$store.getters['user/getUser'];
    },
    isUserActivated() {
      return this.$store.getters['user/isUserActivated'];
    },
  },
  created() {
    // Messages
    bus.$on('message', ({ message, type }) => {
      this.snackbar.message = message;
      this.snackbar.color = type;
      this.snackbar.show = true;
    });

    // Renew Token
    bus.$on('login', (expiresIn) => {
      this.items = getMenu();
      this.handleRenew(expiresIn);
    });

    bus.$on('renew', (expiresIn) => {
      this.items = getMenu();
      this.handleRenew(expiresIn);
    });

    // Idle time
    this.handleIdleTime();

    // Trata login/logout no caso de utilização de mais de uma aba
    window.onstorage = (e) => {
      const loginRoute = (this.user && this.user.type === 'medibook')
        ? 'login-medibook' : 'login';

      // Logout
      if (e.key === 'mbeclogin' && e.newValue === '0') {
        if (this.renewTimer) clearInterval(this.renewTimer);
        this.$router.push({ name: loginRoute });
        this.$store.dispatch('user/logout');
      }
      // Login
      if (e.key === 'mbeclogin' && e.newValue === '1') {
        this.$store.dispatch('user/renew').then(() => {
          this.$router.push({ name: 'start' });
        });
      }
    };
  },
  methods: {
    onResize() {
      this.drawer.type = this.$vuetify.breakpoint.mdAndUp
        ? 'permanent'
        : 'temporary';
      this.drawer.clipped = this.$vuetify.breakpoint.mdAndUp;
    },
    handleItemClick(item) {
      if (item.path) {
        if (this.$route.path !== item.path) this.$router.push({ path: item.path });
      }
    },
    handleProfileMenuClick(item) {
      if (item.name === 'changepass') {
        this.changePasswordDialog = true;
      } else if (item.name === 'terms') {
        window.open('https://dev.medibook.com.br/app/terms/term', '_blank');
      } else if (item.name === 'privacyPolicy') {
        window.open('https://dev.medibook.com.br/app/terms/policy', '_blank');
      } else if (item.name === 'sair') {
        this.logout();
      }
    },
    logout() {
      // const loginRoute = (this.user && this.user.type === 'medibook')
      //   ? 'login-medibook' : 'login';
      const path = `/${this.user.loginType}/login`;

      resetRouter();

      this.$store.dispatch('user/logout')
        .then(() => {
          this.$router.push({ path });
        })
        .catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        });
    },
    handleIdleTime() {
      if (this.idleTimeTimer) clearInterval(this.idleTimeTimer);

      this.idleTimeTimer = setTimeout(() => {
        if (!['register', 'login', 'recover-password', 'link', 'site'].includes(this.$route.name)) {
          if (this.renewTimer) clearInterval(this.renewTimer);
          this.logout();
        }
      }, Number(process.env.VUE_APP_IDLE_TIME));
    },
    handleRenew(expiresIn) {
      const expire = (Number(expiresIn) * 0.90) * 1000;
      if (this.renewTimer) clearInterval(this.renewTimer);

      this.renewTimer = setTimeout(() => {
        this.$store.dispatch('user/renew')
          .catch((err) => {
            if (err.response.data.message === 'Token inválido') {
              bus.$emit('message', {
                message: 'Por favor, efetue login novamente',
                type: 'error',
              });
            } else {
              bus.$emit('message', {
                message: err.response.data.message,
                type: 'error',
              });
            }
            this.logout();
          });
      }, expire);
    },
    // verifyPerm(item) {
    //   if (item.product && this.user.client) {
    //     const subscriptions = this.user.client.subscriptions || {};
    //     return subscriptions[item.product];
    //   } if (!item.product || this.user.type === 'medibook') {
    //     return true;
    //   }
    //   return false;
    // },
  },
};
</script>
