export default {
  close: 'Fechar',
  dataIterator: {
    pageText: '{0}-{1} de {2}',
    noResultsText: 'Nenhum registro correspondente encontrado',
    loadingText: 'Carregando itens...',
  },
  dataTable: {
    itemsPerPageText: 'Linhas por página:',
    ariaLabel: {
      sortDescending: ': Ordenado decrescente. Ativar para remover a classificação.',
      sortAscending: ': Ordenado ascendente. Ative para classificar em ordem decrescente.',
      sortNone: ': Não classificado. Ative para classificar em ordem crescente.',
      activateDescending: 'Ativar descendente',
      activateAscending: 'Ativar ascendente',
      activateNone: 'Ativção nenhuma',
    },
    sortBy: 'Ordenar por',
  },
  dataFooter: {
    pageText: '{0}-{1} de {2}',
    itemsPerPageText: 'Linhas por página:',
    itemsPerPageAll: 'Todos',
    nextPage: 'Próxima página',
    prevPage: 'Página anterior',
    firstPage: 'Primeira página',
    lastPage: 'Última página',
  },
  datePicker: {
    itemsSelected: '{0} selecionado',
    prevMonthAriaLabel: 'Anterior',
    nextMonthAriaLabel: 'Próx. Mês',
  },
  noDataText: 'Não há dados disponíveis',
  carousel: {
    prev: 'Visual anterior',
    next: 'Próximo visual',
    ariaLabel: {
      delimiter: 'Delimitador',
    },
  },
  calendar: {
    moreEvents: '{0} mais',
  },
  badge: 'Emblemas',
};
