import { pg } from '../utils/query';
import HTTP from './axios';

const BrokersService = {
  one: (id) => HTTP.get(`/brokers/${id}`).then((response) => response.data),
  all: (p) => HTTP.get(`/broker/${p.brokerId}/brokers?${pg(p)}`).then((response) => response.data),
  create: (p) => HTTP.post(`/broker/${p.brokerId}/brokers`, p.broker)
    .then((response) => response.data),
  update: (broker) => HTTP.put(`/brokers/${broker.id}`, broker)
    .then((response) => response.data),
  remove: (p) => HTTP.post(`/brokers/${p.brokerId}`)
    .then((response) => response.data),
};

export default BrokersService;
