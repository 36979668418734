<template>
  <v-card class="elevation-1">
    <v-card-title>
      <span v-if="broker && broker.id" class="headline mb-0">
        {{ broker.name }}
      </span>
      <span v-else class="headline mb-0">Nova Corretora</span>
    </v-card-title>

    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-card-text>
        <form autocomplete="off">
          <!-- Document -->
          <v-row dense>
            <v-col
              xl="5" lg="5" md="5" sm="5"
              cols="12"
            >
              <mb-input-cnpj
                v-model="broker.document"
                name="brk_cnpj" label="CNPJ"
                rules="required"
                :disabled="broker.id != null"
              />
            </v-col>
          </v-row>
          <!-- Name/Slug -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4"
            >
              <mb-input-text
                v-model="broker.name"
                name="brk_name" label="Nome"
                rules="required|max:150"
              />
            </v-col>
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4"
            >
              <mb-input-text
                v-model="broker.slug"
                name="brk_slug" label="Nome Divulgação"
                rules="required|max:50"
              />
            </v-col>
            <!-- <v-col
              xl="3" lg="3" md="3"
              sm="3" cols="12" class="pa-0 ma-0"
            >
              <v-switch v-model="broker.active" label="Ativo?" />
            </v-col> -->
          </v-row>
          <!-- Contact/Email -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4">
              <mb-input-text
                v-model="broker.contact"
                name="brk_contact" label="Contato"
                rules="required|max:50"
              />
            </v-col>
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0">
              <mb-input-text
                v-model.trim="broker.email"
                name="brk_email" label="Email"
                rules="required"
              />
            </v-col>
          </v-row>
          <!-- Phones -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4">
              <mb-input-phone
                v-model="broker.mobile"
                name="brk_mobile" label="Celular" rules="required|phone"
              />
            </v-col>
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4">
              <mb-input-phone
                v-model="broker.phone"
                name="brk_phone" label="Telefone" rules="required"
              />
            </v-col>
          </v-row>
          <!-- ============== Address ============== -->
          <v-divider />

          <v-row class="pa-0 ma-0">
            <v-col cols="12" class="pa-0 ma-0">
              <address-form v-model="broker"/>
            </v-col>
          </v-row>

          </form>
      </v-card-text>

      <v-card-actions>
        <mb-text-button
          name="prd_btn_save" label="Salvar"
          :disabled="invalid"
          :loading="saveBtnLoading"
          class="ml-2 mb-2"
          @click="save"
        />
        <v-spacer />
        <mb-text-button
          name="prd_btn_cancel" label="Cancelar" :primary="false"
          class="mb-2" @click="cancel"
        />
      </v-card-actions>
    </ValidationObserver>
  </v-card>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import bus from '@/plugins/eventBus';
import MbInputText from '@/components/general/inputs/MbInputText';
import MbInputCnpj from '@/components/general/inputs/MbInputCnpj';
import MbInputPhone from '@/components/general/inputs/MbInputPhone';
import MbTextButton from '@/components/general/buttons/MbTextButton';
import AddressForm from '@/components/general/AddressForm';

export default {
  components: {
    ValidationObserver,
    MbInputText,
    MbInputCnpj,
    MbInputPhone,
    MbTextButton,
    AddressForm,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      saveBtnLoading: false,
      broker: null,
      defaultBroker: {
        document: null,
        name: null,
        slug: null,
        contact: null,
        email: null,
        phone: null,
        mobile: null,

        zip: null,
        street: null,
        number: null,
        complement: null,
        district: null,
        city: null,
        state: null,
        latitude: null,
        longitude: null,
      },
    };
  },
  computed: {
  },
  watch: {
    value(vl) {
      this.broker = vl || JSON.parse(JSON.stringify(this.defaultBroker));
      this.$refs.observer.reset();
    },
  },
  created() {
    this.broker = this.value || JSON.parse(JSON.stringify(this.defaultBroker));
  },
  destroyed() {
    this.broker = JSON.parse(JSON.stringify(this.defaultBroker));
  },
  methods: {
    save() {
      if (this.broker.id) {
        this.update();
      } else {
        this.create();
      }
    },
    async create() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.saveBtnLoading = true;
        this.$store
          .dispatch('broker/create', this.broker)
          .then(() => {
            this.broker = this.defaultBroker;
            bus.$emit('message', {
              message: 'Corretor criado com sucesso!',
              type: 'success',
            });
            this.$emit('created');
            this.$emit('input', this.broker);
            this.$refs.observer.reset();
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
    async update() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.saveBtnLoading = true;
        this.$store
          .dispatch('broker/update', this.broker)
          .then((brokerSaved) => {
            bus.$emit('message', {
              message: 'Corretor atualizado com sucesso!',
              type: 'success',
            });
            this.$emit('updated');
            this.$emit('input', brokerSaved);
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
    cancel() {
      this.$emit('cancel');
      this.broker = JSON.parse(JSON.stringify(this.defaultBroker));
      this.$refs.observer.reset();
      this.$router.push({ name: 'broker-list' });
    },
  },
};
</script>
