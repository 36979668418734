<template>
  <v-container>
    <v-row class="mb-5">
      <v-col cols="12" class="pa-0 ma-0">
        <broker-form v-model="broker"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BrokerForm from '@/components/broker/BrokerForm';

const Hashids = require('hashids');

export default {
  components: {
    BrokerForm,
  },
  data: () => ({
    hashids: null,
    broker: null,
  }),
  created() {
    // eslint-disable-next-line new-cap
    this.hashids = new Hashids.default(
      process.env.VUE_APP_HASHID_SALT, Number(process.env.VUE_APP_HASHID_PAD),
    );

    const { broker } = this.$route.params;
    if (broker && Object.entries(broker).length > 0) {
      this.broker = broker;
    } else if (this.$route.params.id) {
      this.get(this.hashids.decode(this.$route.params.id)[0]);
    }
  },
  methods: {
    get(id) {
      this.$store
        .dispatch('broker/one', id)
        .then((res) => {
          this.broker = res;
        });
    },
  },
};
</script>
