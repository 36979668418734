<template>
  <v-card>

   <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-card-text>
        <v-progress-linear indeterminate :active="loading" />
        <v-row class="pa-0 ma-0" v-if="configurations">
          <form autocomplete="off">
            <!-- Bank Slip Fee / Withdraw Fee -->
            <v-row class="mx-2 my-2 body-1">
              Tarifas
            </v-row>
            <v-row>
              <!-- Bank Slip Fee -->
              <v-col
                xl="6" lg="6" md="6" sm="6"
                cols="12" >
                <mb-input-money
                  v-model="configurations.bankSlipFee"
                  name="cnf_bank_slip_tax"
                  label="Tarifa do Boleto"
                  hideDetails="auto"
                  rules="required"
                />
              </v-col>

              <!-- Withdraw Fee -->
              <v-col
                xl="6" lg="6" md="6" sm="6"
                cols="12" >
                <mb-input-money
                  v-model="configurations.withdrawFee"
                  name="cnf_withdraw_fee"
                  label="Tarifa de Saque"
                  hideDetails="auto"
                  rules="required"
                />
              </v-col>
            </v-row>

            <v-row>
              <!-- Card Value -->
              <v-col
                xl="6" lg="6" md="6" sm="6"
                cols="12" >
                <mb-input-money
                  v-model="configurations.cardValue"
                  name="cnf_card_value"
                  label="Valor da carteirinha"
                  hideDetails="auto"
                  rules="required"
                />
              </v-col>
            </v-row>

          </form>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <mb-text-button
          name="cnf_btn_save" label="Salvar"
          :disabled="invalid"
          :loading="saveBtnLoading"
          class="ml-2 mb-2"
          @click="save"
        />
        <v-spacer />
      </v-card-actions>
    </ValidationObserver>
  </v-card>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import bus from '@/plugins/eventBus';
import MbInputMoney from '@/components/general/inputs/MbInputMoney';
import MbTextButton from '@/components/general/buttons/MbTextButton';

export default {
  components: {
    ValidationObserver,
    MbInputMoney,
    MbTextButton,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      configurations: null,
      loading: false,
      saveBtnLoading: false,
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
    this.getConfs();
  },
  methods: {
    getConfs() {
      this.loading = true;
      // console.log('getConfs');
      this.$store
        .dispatch('configurations/get')
        .then((res) => {
          // console.log(res);
          this.configurations = res;
        })
        .catch((err) => {
          console.log(err);
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        })
        .finally(async () => {
          this.loading = false;
        });
    },
    async save() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.saveBtnLoading = true;
        this.$store
          .dispatch('configurations/update', this.configurations)
          .then(() => {
            bus.$emit('message', {
              message: 'Configurações salvas com sucesso!',
              type: 'success',
            });
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
  },
};
</script>
