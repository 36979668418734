<template>
  <v-card elevation="0">
    <v-card-text class="pa-0 ma-0">
      <v-container v-if="data" class="pa-0 ma-0 text-center">
        <v-row class="pa-0 ma-0">
          <v-col
            sm="4" md="3" lg="3" xl="2"
            cols="12"
            justify="space-between"
            class="text-center pb-5"
          >
            <v-row class="pa-0 ma-0" >
              <v-col class="text-subtitle-2 pa-0 ma-0">Contratantes</v-col>
            </v-row>
            <v-row>
              <v-col v-if="data.allContratante" class="pa-0 ma-0">{{ data.allContratante }}</v-col>
              <v-col v-else class="pa-0 ma-0">-</v-col>
            </v-row>
          </v-col>

          <v-col
            sm="4" md="3" lg="3" xl="2"
            cols="12"
            justify="space-between"
            class="text-center pb-5"
          >
            <v-row class="pa-0 ma-0" >
              <v-col class="text-subtitle-2 pa-0 ma-0">Previstos</v-col>
            </v-row>
            <v-row>
              <v-col v-if="data.predictedVal" class="pa-0 ma-0">{{ data.predictedVal }}</v-col>
              <v-col v-else class="pa-0 ma-0">-</v-col>
            </v-row>
          </v-col>

          <v-col
            sm="4" md="3" lg="3" xl="2"
            cols="12"
            justify="space-between"
            class="text-center pb-5"
          >
            <v-row class="pa-0 ma-0" >
              <v-col class="text-subtitle-2 pa-0 ma-0">Recebidos</v-col>
            </v-row>
            <v-row>
              <v-col v-if="data.receivedQtd" class="pa-0 ma-0">
                ({{ data.receivedQtd }}) {{ data.receivedVal }}
              </v-col>
              <v-col v-else class="pa-0 ma-0">-</v-col>
            </v-row>
          </v-col>

          <v-col
            sm="4" md="3" lg="3" xl="2"
            cols="12"
            justify="space-between"
            class="text-center pb-5"
          >
            <v-row class="pa-0 ma-0" >
              <v-col class="text-subtitle-2 pa-0 ma-0">Inadimplentes</v-col>
            </v-row>
            <v-row>
              <v-col v-if="data.defaultingsQtd" class="pa-0 ma-0">
                ({{ data.defaultingsQtd }}) {{ data.defaultingsVal }}
              </v-col>
              <v-col v-else class="pa-0 ma-0">-</v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="pa-0 ma-0">
          <v-col
            sm="4" md="3" lg="3" xl="2"
            cols="12"
            justify="space-between"
            class="text-center pb-5"
          >
            <v-row class="pa-0 ma-0" >
              <v-col class="text-subtitle-2 pa-0 ma-0">Estipulante</v-col>
            </v-row>
            <v-row>
              <v-col v-if="data.estipulanteCom" class="pa-0 ma-0">{{ data.estipulanteCom }}</v-col>
              <v-col v-else class="pa-0 ma-0">-</v-col>
            </v-row>
          </v-col>

          <v-col
            sm="4" md="3" lg="3" xl="2"
            cols="12"
            justify="space-between"
            class="text-center pb-5"
          >
            <v-row class="pa-0 ma-0" >
              <v-col class="text-subtitle-2 pa-0 ma-0">Subestipulante</v-col>
            </v-row>
            <v-row>
              <v-col v-if="data.subestipulanteCom" class="pa-0 ma-0">
                {{ data.subestipulanteCom }}
              </v-col>
              <v-col v-else class="pa-0 ma-0">-</v-col>
            </v-row>
          </v-col>

          <v-col
            sm="4" md="3" lg="3" xl="2"
            cols="12"
            justify="space-between"
            class="text-center pb-5"
          >
            <v-row class="pa-0 ma-0" >
              <v-col class="text-subtitle-2 pa-0 ma-0">Corretora</v-col>
            </v-row>
            <v-row>
              <v-col v-if="data.brokerCom" class="pa-0 ma-0">{{ data.brokerCom }}</v-col>
              <v-col v-else class="pa-0 ma-0">-</v-col>
            </v-row>
          </v-col>

          <v-col
            sm="4" md="3" lg="3" xl="2"
            cols="12"
            justify="space-between"
            class="text-center pb-5"
          >
            <v-row class="pa-0 ma-0" >
              <v-col class="text-subtitle-2 pa-0 ma-0">APSS</v-col>
            </v-row>
            <v-row>
              <v-col v-if="data.apssCom" class="pa-0 ma-0">{{ data.apssCom }}</v-col>
              <v-col v-else class="pa-0 ma-0">-</v-col>
            </v-row>
          </v-col>

          <v-col
            sm="4" md="3" lg="3" xl="2"
            cols="12"
            justify="space-between"
            class="text-center pb-5"
          >
            <v-row class="pa-0 ma-0" >
              <v-col class="text-subtitle-2 pa-0 ma-0">Proplast</v-col>
            </v-row>
            <v-row>
              <v-col v-if="data.proplastCom" class="pa-0 ma-0">{{ data.proplastCom }}</v-col>
              <v-col v-else class="pa-0 ma-0">-</v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  components: {
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
  }),
};
</script>
