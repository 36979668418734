<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="name"
    :name="label"
    :mode="validationMode"
    :rules="rules"
  >
    <v-text-field
      :id="name"
      v-model="internalValue"
      v-mask="'#####-###'"
      autocomplete="off"
      :label="label"
      :hint="hint"
      :persistent-hint="persistentHint"
      :placeholder="placeholder"
      type="text"
      :loading="loading || loadingGetByCep"
      :disabled="disabled"
      outlined dense
      :error="errors.length > 0"
      :error-messages="errors[0]"
      @input="input"
      @change="change"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mask } from 'vue-the-mask';

export default {
  directives: { mask },
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    validationMode: {
      type: String,
      default: 'lazy',
    },
    hint: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'CEP',
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'CEP',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    searchCep: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: [String, Object],
      default: 'cep',
    },
  },
  data: () => ({
    internalValue: '',
    loadingGetByCep: false,
  }),
  watch: {
    value(vl) {
      this.internalValue = vl;
    },
  },
  created() {
    this.internalValue = this.value || '';
  },
  methods: {
    input(vl) {
      this.$emit('input', this.unmask(vl));
    },
    change() {
      if (this.searchCep) {
        this.getByCep();
      }
    },
    unmask(vl) {
      return vl.replace('-', '');
    },
    getByCep() {
      if (this.value && this.value.length === 8) {
        this.loadingGetByCep = true;

        this.$viaCep
          .buscarCep(this.value)
          .then((obj) => {
            if (!('error' in obj)) {
              this.$emit('gettedByCep', {
                state: obj.uf,
                city: obj.localidade,
                district: obj.bairro,
                street: obj.logradouro,
              });
            }
          })
          .finally(() => {
            this.loadingGetByCep = false;
          });
      }
    },
  },
};
</script>
