<template>
  <v-container class="pa-0 ma-0">
    <v-progress-linear indeterminate :active="loading" />

      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <v-card-text>

          <form autocomplete="off">

            <!-- Estipulante/Subestipulante -->
            <v-row class="pa-0 ma-0">
              <v-col
                cols="12" sm="4" md="4" lg="4"
                xl="3"
              >
                <mb-select
                  v-model="form.estipulanteId" :items="estipulantes"
                  item-text="slug" item-value="id"
                  name="altr_bnfry_estipulante" label="Estipulantes"
                  :loading="selectEstipulanteLoading"
                  :disabled="true"
                />
              </v-col>
              <v-col
                cols="12" sm="4" md="4" lg="4"
                xl="3"
              >
                <mb-select
                  v-model="form.subestipulanteId" :items="subestipulantes" clearable
                  item-text="slug" item-value="id"
                  name="altr_bnfry_subestipulante" label="Subestipulantes"
                  :loading="selectSubestipulanteLoading"
                />
              </v-col>
            </v-row>

            <!-- WithoutAdhesion -->
            <v-row class="pa-0 ma-0">
              <v-col
                cols="12" sm="4" md="4" lg="4"
                xl="2">
                <v-switch
                  v-model="form.withoutAdhesion"
                  label="Planos sem adesão?"
                ></v-switch>
              </v-col>
              <v-col
                cols="12" sm="1" md="1" lg="1"
                xl="1" class="pt-8"
              >
                OU
              </v-col>
              <v-col
                cols="12" sm="4" md="4" lg="4"
                xl="3">
                <mb-select
                  v-model="form.planId" :items="plans"
                  item-text="description" item-value="id"
                  name="altr_bnfry_plan" label="Plano específico"
                  :loading="loadingPlans"
                />
              </v-col>
            </v-row>

            <v-divider />

            <v-row class="pa-0 ma-0">
              <v-col cols="12">
                <address-form v-model="form"/>
              </v-col>
            </v-row>
          </form>

        </v-card-text>

        <v-card-actions>
          <mb-text-button
            name="altr_bnfry_btn_alter" label="Alterar"
            :loading="alterBtnLoading"
            class="ml-2 mb-2"
            @click="alterBeneficiary"
            :disabled="invalid"
          />
        </v-card-actions>

      </ValidationObserver>

  </v-container>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import bus from '@/plugins/eventBus';
import MbSelect from '@/components/general/inputs/MbSelect';
import MbTextButton from '@/components/general/buttons/MbTextButton';
import AddressForm from '@/components/general/AddressForm';

export default {
  components: {
    ValidationObserver,
    MbSelect,
    MbTextButton,
    AddressForm,
  },
  props: {
    beneficiary: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      contratante: null,
      estipulantes: [],
      subestipulantes: [],
      plans: [],
      selectEstipulanteLoading: false,
      selectSubestipulanteLoading: false,
      loadingPlans: false,
      form: {
        contratanteId: null,
        beneficiaryId: null,
        estipulanteId: null,
        subestipulanteId: null,
        withoutAdhesion: false,
        planId: null,

        zip: null,
        street: null,
        number: null,
        complement: null,
        district: null,
        city: null,
        state: null,
        latitude: null,
        longitude: null,
      },
      alterBtnLoading: false,
    };
  },
  watch: {
    beneficiary(vl) {
      if (vl != null) {
        this.getContratante();
      }
    },
    // eslint-disable-next-line func-names
    'form.estipulanteId': function (vl) {
      this.subestipulantes = [];
      // this.form.subestipulanteId = null;
      if (vl != null) {
        this.getSubestipulantes();
      }
      this.getPlans();
    },
    // eslint-disable-next-line func-names
    'form.subestipulanteId': function () {
      // this.form.subestipulanteId = null;
      this.getPlans();
    },
  },
  mounted() {
    this.form.beneficiaryId = this.beneficiary.id;
    this.getEstipulantes();
    this.getSubestipulantes();
    this.getContratante();
  },
  methods: {
    getContratante() {
      if (this.beneficiary) {
        this.loading = true;
        this.$store.dispatch('contratante/one', this.beneficiary.contratanteId).then((resp) => {
          this.contratante = resp;
          this.form.contratanteId = this.contratante.id;
          this.form.estipulanteId = this.contratante.estipulanteId;
          this.form.subestipulanteId = this.contratante.subestipulanteId;
        }).catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    getEstipulantes() {
      this.selectEstipulanteLoading = true;
      this.$store.dispatch('estipulante/all', {
        pagination: { page: 1, size: -1 },
      }).then((resp) => {
        this.estipulantes = resp.data;
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.selectEstipulanteLoading = false;
      });
    },
    getSubestipulantes() {
      if (this.form.estipulanteId != null) {
        this.selectSubestipulanteLoading = true;
        this.$store.dispatch('subestipulante/all', {
          id: this.form.estipulanteId,
          pagination: { page: 1, size: -1 },
        }).then((resp) => {
          this.subestipulantes = resp.data;
        }).catch((err) => {
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.selectSubestipulanteLoading = false;
        });
      }
    },
    getPlans() {
      this.loadingPlans = true;
      this.$store.dispatch('plan/actives', {
        estipulanteId: this.form.estipulanteId,
        subestipulanteId: this.form.subestipulanteId,
      }).then((plans) => {
        // console.log('plans');
        // console.log(plans);
        this.plans = plans.filter((i) => ((i.type === 'pf') ? i : null));
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.loadingPlans = false;
      });
    },
    alterBeneficiary() {
      this.alterBtnLoading = true;
      this.$store.dispatch(
        'contratante/changePjToPf', this.form,
      ).then(() => {
        // this.subestipulantes = resp.data;
        bus.$emit('message', {
          message: 'Alteração efetuada com sucesso!',
          type: 'success',
        });
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.alterBtnLoading = false;
      });
    },
  },
};
</script>
