<template>
  <v-card class="elevation-1">
    <v-card-title>
      <span v-if="estipulante && estipulante.id" class="headline mb-0">
        {{ estipulante.name }}
      </span>
      <span v-else class="headline mb-0">Novo Estipulante</span>
    </v-card-title>

    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-card-text>
        <form autocomplete="off">
          <!-- Document -->
          <v-row dense>
            <v-col
              xl="5" lg="5" md="5" sm="5"
              cols="12" class="mr-4"
            >
              <mb-input-cnpj
                v-model="estipulante.document"
                name="crte_cnpj" label="CNPJ"
                rules="required"
                :disabled="estipulante.id != null"
              />
            </v-col>
          </v-row>
          <!-- Name/Slug -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4"
            >
              <mb-input-text
                v-model="estipulante.name"
                name="crte_name" label="Nome"
                rules="required|max:150"
                :disabled="estipulante.id != null"
              />
            </v-col>
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4"
            >
              <mb-input-text
                v-model="estipulante.slug"
                name="crte_slug" label="Nome Divulgação"
                rules="required|max:50"
              />
            </v-col>
            <!-- <v-col
              xl="3" lg="3" md="3"
              sm="3" cols="12" class="pa-0 ma-0"
            >
              <v-switch v-model="estipulante.active" label="Ativo?" />
            </v-col> -->
          </v-row>
          <!-- Contact/Email -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4">
              <mb-input-text
                v-model="estipulante.contact"
                name="crte_contact" label="Contato"
                rules="required|max:50"
              />
            </v-col>
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0">
              <mb-input-text
                v-model.trim="estipulante.email"
                name="crte_email" label="Email" rules="required"
              />
            </v-col>
          </v-row>
          <!-- Phones -->
          <v-row dense class="pa-0 ma-0">
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4">
              <mb-input-phone
                v-model="estipulante.mobile"
                name="crte_mobile" label="Celular" rules="required|phone"
              />
            </v-col>
            <v-col
              xl="5" lg="5" md="5"
              sm="5" cols="12" class="pa-0 ma-0 mr-4">
              <mb-input-phone
                v-model="estipulante.phone"
                name="crte_phone" label="Telefone" rules="required|phone"
              />
            </v-col>
          </v-row>
          <!-- <v-row dense>
            <v-col
              xl="5" lg="5" md="5" sm="5"
              cols="12" class="mr-4"
            >
              <v-switch
                v-model="estipulante.naturalPerson" label="Contratante Pessoa Física?"/>
            </v-col>
          </v-row> -->
          <!-- ============== Address ============== -->
          <v-divider />

          <v-row class="pa-0 ma-0">
            <v-col cols="12" class="pa-0 ma-0">
              <address-form v-model="estipulante"/>
            </v-col>
          </v-row>

        </form>
      </v-card-text>

      <v-card-actions>
        <mb-text-button
          name="prd_btn_save" label="Salvar"
          :disabled="invalid"
          :loading="saveBtnLoading"
          class="ml-2 mb-2"
          @click="save"
        />
        <v-spacer />
        <mb-text-button
          name="prd_btn_cancel" label="Cancelar" :primary="false"
          class="mb-2" @click="cancel"
        />
      </v-card-actions>
    </ValidationObserver>
  </v-card>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import bus from '@/plugins/eventBus';
import MbInputText from '@/components/general/inputs/MbInputText';
import MbInputPhone from '@/components/general/inputs/MbInputPhone';
import MbInputCnpj from '@/components/general/inputs/MbInputCnpj';
import MbTextButton from '@/components/general/buttons/MbTextButton';
import AddressForm from '@/components/general/AddressForm';

export default {
  components: {
    ValidationObserver,
    MbInputText,
    MbInputPhone,
    MbInputCnpj,
    MbTextButton,
    AddressForm,
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      saveBtnLoading: false,
      estipulante: null,
      defaultEstipulante: {
        document: null,
        name: null,
        slug: null,
        contact: null,
        email: null,
        phone: null,
        mobile: null,

        zip: null,
        street: null,
        number: null,
        complement: null,
        district: null,
        city: null,
        state: null,
        latitude: null,
        longitude: null,

        // naturalPerson: 0,
      },
    };
  },
  watch: {
    value(vl) {
      this.estipulante = vl || JSON.parse(JSON.stringify(this.defaultEstipulante));
      this.$refs.observer.reset();
    },
  },
  created() {
    this.estipulante = this.value || JSON.parse(JSON.stringify(this.defaultEstipulante));
  },
  destroyed() {
    this.estipulante = JSON.parse(JSON.stringify(this.defaultEstipulante));
  },
  methods: {
    save() {
      if (this.estipulante.id) {
        this.update();
      } else {
        this.create();
      }
    },
    async create() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.saveBtnLoading = true;
        this.$store
          .dispatch('estipulante/create', this.estipulante)
          .then(() => {
            this.estipulante = this.defaultEstipulante;
            bus.$emit('message', {
              message: 'Estipulante criado com sucesso!',
              type: 'success',
            });
            this.$emit('input', this.estipulante);
            this.$refs.observer.reset();
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
    async update() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.saveBtnLoading = true;
        this.$store
          .dispatch('estipulante/update', this.estipulante)
          .then((estipulanteSaved) => {
            bus.$emit('message', {
              message: 'Estipulante atualizado com sucesso!',
              type: 'success',
            });
            this.$emit('updated');
            this.$emit('input', estipulanteSaved);
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
    cancel() {
      this.$emit('cancel');
      this.estipulante = JSON.parse(JSON.stringify(this.defaultEstipulante));
      this.$refs.observer.reset();
      this.$router.push({ name: 'estipulante-list' });
    },
  },
};
</script>
