import CommissionService from '@/services/commission.service';

const commissions = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    allByEstipulante(_, p) {
      return CommissionService.allByEstipulante(p);
    },
    one(_, id) {
      return CommissionService.one(id);
    },
    create(_, commission) {
      return CommissionService.create(commission);
    },
    update(_, commission) {
      return CommissionService.update(commission);
    },
  },

  getters: {},
};

export default commissions;
