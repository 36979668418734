import Subscription from '@/services/subscription.service';

const subscriptions = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    all(_, parameters) {
      return Subscription.all(parameters);
    },
    my(_, contratanteId) {
      return Subscription.my(contratanteId);
    },
    create(_, subscription) {
      return Subscription.create(subscription);
    },
    createLink(_, p) {
      return Subscription.createLink(p);
    },
    update(_, subscription) {
      return Subscription.update(subscription);
    },
    calculate(_, subscription) {
      return Subscription.calculate(subscription);
    },
    calculateLink(_, subscription) {
      return Subscription.calculateLink(subscription);
    },
    cancel(_, id) {
      return Subscription.cancel(id);
    },
    suspend(_, id) {
      return Subscription.suspend(id);
    },
    activate(_, id) {
      return Subscription.activate(id);
    },
  },

  getters: {},
};

export default subscriptions;
