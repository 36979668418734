<template>
  <v-container>
    <v-row class="mb-5">
      <v-col cols="12" class="pa-0 ma-0">
        <estipulante-form v-model="estipulante" @cancel="edit = false" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EstipulanteForm from '@/components/estipulante/EstipulanteForm';

const Hashids = require('hashids');

export default {
  components: {
    EstipulanteForm,
  },
  data: () => ({
    hashids: null,
    estipulante: null,
  }),
  created() {
    // eslint-disable-next-line new-cap
    this.hashids = new Hashids.default(
      process.env.VUE_APP_HASHID_SALT, Number(process.env.VUE_APP_HASHID_PAD),
    );

    const { estipulante } = this.$route.params;
    if (estipulante && Object.entries(estipulante).length > 0) {
      this.estipulante = estipulante;
    } else if (this.$route.params.id) {
      this.get(this.hashids.decode(this.$route.params.id)[0]);
    }
  },
  methods: {
    get(id) {
      this.$store
        .dispatch('estipulante/oneByCompany', id)
        .then((res) => {
          this.estipulante = res;
        });
    },
  },
};
</script>
