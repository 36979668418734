// Common
import Start from '@/views/Start';
import RecoverPassword from '@/views/RecoverPassword';
import DefaultHome from '@/views/DefaultHome';

import RouterView from '@/components/general/RouterView';

// Bank Econocard
import BankAccountCadastre from '@/views/companyEco/BankAccountCadastre';
import BankAccountEdit from '@/views/companyEco/BankAccountEdit';

// Estipulante
import EstipulanteList from '@/views/estipulante/EstipulanteList';
import EstipulanteCad from '@/views/estipulante/EstipulanteCad';
import EstipulanteCadEdit from '@/views/estipulante/EstipulanteCadEdit';
import EstipulanteConfiguration from '@/views/estipulante/EstipulanteConfiguration';

// Subestipulante
import SubestipulanteList from '@/views/subestipulante/SubestipulanteList';
import SubestipulanteCad from '@/views/subestipulante/SubestipulanteCad';
import SubestipulanteCadEdit from '@/views/subestipulante/SubestipulanteCadEdit';

// Contratante
import ContratanteList from '@/views/contratante/ContratanteList';
import ContratanteListSubestipulante from '@/views/contratante/subestipulante/ContratanteList';
import ContratanteCad from '@/views/contratante/ContratanteCad';
import ContratanteContractUpload from '@/views/contratante/ContratanteContractUpload';
import ContratanteConfigurationPj from '@/views/contratante/ContratanteConfigurationPj';
import ContratanteCadEdit from '@/views/contratante/ContratanteCadEdit';
import ContratanteConfigurationPjEdit from '@/views/contratante/ContratanteConfigurationPjEdit';

// Broker
import BrokerList from '@/views/broker/BrokerList';
import BrokerCad from '@/views/broker/BrokerCad';
import BrokerCadEdit from '@/views/broker/BrokerCadEdit';
import LeadList from '@/views/broker/LeadList';

// Brokers
import BrokersList from '@/views/brokers/BrokersList';
import BrokersCad from '@/views/brokers/BrokersCad';

// Beneficiário
import BeneficiaryList from '@/views/beneficiary/BeneficiaryList';
import MedibookBeneficiaryList from '@/views/beneficiary/medibook/BeneficiaryList';
import BeneficiaryCad from '@/views/beneficiary/BeneficiaryCad';
import BeneficiaryImport from '@/views/beneficiary/BeneficiaryImport';
import BeneficiaryStatusList from '@/views/beneficiary/BeneficiaryStatusList';

// Dependente
import DependentList from '@/views/dependent/DependentList';
import DependentListPf from '@/views/dependent/contratantepf/DependentList';
import DependentCad from '@/views/dependent/DependentCad';

// Fatura
import InvoiceList from '@/views/invoice/InvoiceList';
import ContratanteInvoices from '@/views/invoice/contratante/InvoiceList';
// import ContratantePFInvoices from '@/views/invoice/contratantepf/InvoiceList';
import MedibookInvoices from '@/views/invoice/medibook/InvoiceList';

// Saques
// import WithdrawList from '@/views/withdraw/WithdrawList';

// Links
import LinksEstipulante from '@/views/links/estipulante/Links';
import LinksBrokers from '@/views/links/broker/Links';

// Subscription
import Subscription from '@/views/subscription/contratantepf/Subscription';

// ========================================= Users
// Medibook User
// import MedibookHome from '@/views/home/medibook/Home';
import LoginMedibook from '@/views/login/medibook/Login';
import Commissions from '@/views/commissions/medibook/Commissions';
import Configurations from '@/views/configurations/medibook/Configurations';

// Estipulante User
// import EstipulanteHome from '@/views/home/estipulante/Home';
import LoginEstipulante from '@/views/login/estipulante/Login';

// Subestipulante User
// import SubestipulanteHome from '@/views/home/subestipulante/Home';
import LoginSubestipulante from '@/views/login/subestipulante/Login';

// Contratante User
// import ContratanteHome from '@/views/home/contratante/Home';
import LoginContratantePJ from '@/views/login/contratantepj/Login';
import LoginContratantePF from '@/views/login/contratantepf/Login';

// Broker User
// import BrokerHome from '@/views/home/broker/Home';
import LoginBroker from '@/views/login/broker/Login';

// Brokers User
// import BrokersHome from '@/views/home/brokers/Home';
import LoginBrokers from '@/views/login/brokers/Login';

// Clinic User
import LoginCompany from '@/views/login/company/Login';

// Report
import AdhesionReport from '@/views/report/Adhesion';
import CardsReport from '@/views/report/Cards';
import CommissionReport from '@/views/report/Commission';
import ContratantesPjPendentesReport from '@/views/report/ContratantesPjPendentes';

// Change Beneficiario <-> Contratante PF
import ChangeType from '@/views/contratante/ChangeType';

const ContratanteCadLink = () => import('@/views/contratante/ContratanteCadLink');
const ContratanteSiteCadastre = () => import('@/views/contratante/ContratanteSiteCadastre');
const ContratanteSiteCad = () => import('@/views/contratante/ContratanteSiteCad');

const routesCommon = [
  { path: '/', redirect: '/home' },
  // {
  //   path: '/',
  //   name: 'home',
  //   component: DefaultHome,
  //   meta: { requiresAuth: true, desc: '' },
  // },
  {
    path: '/home',
    name: 'home',
    component: DefaultHome,
    meta: { requiresAuth: false, desc: '' },
  },
  {
    path: '/start',
    name: 'start',
    component: Start,
    meta: { requiresAuth: false, desc: 'Início' },
  },
  {
    path: '/recover-password',
    name: 'recover-password',
    component: RecoverPassword,
    meta: { requiresAuth: false, desc: 'Recuperar senha' },
  },
  {
    path: '/medibook/login',
    name: 'medibook-login',
    component: LoginMedibook,
    meta: { requiresAuth: false, desc: 'Login Medibook' },
  },
  {
    path: '/estipulante/login',
    name: 'estipulante-login',
    component: LoginEstipulante,
    meta: { requiresAuth: false, desc: 'Login Estipulante' },
  },
  {
    path: '/subestipulante/login',
    name: 'subestipulante-login',
    component: LoginSubestipulante,
    meta: { requiresAuth: false, desc: 'Login Subestipulante' },
  },
  {
    path: '/broker/login',
    name: 'broker-login',
    component: LoginBroker,
    meta: { requiresAuth: false, desc: 'Login Corretora' },
  },
  {
    path: '/brokers/login',
    name: 'brokers-login',
    component: LoginBrokers,
    meta: { requiresAuth: false, desc: 'Login Corretores' },
  },
  {
    path: '/contratantepj/login',
    name: 'contratantepj-login',
    component: LoginContratantePJ,
    meta: { requiresAuth: false, desc: 'Login Contratante' },
  },
  {
    path: '/contratantepf/login',
    name: 'contratantepf-login',
    component: LoginContratantePF,
    meta: { requiresAuth: false, desc: 'Login Contratante' },
  },
  {
    path: '/link/contratante/:p',
    name: 'link-contratante-cad',
    component: ContratanteCadLink,
    meta: { requiresAuth: false, desc: 'Cadastro' },
  },
  {
    path: '/site/contratante/:p',
    name: 'site-contratante-cad',
    component: ContratanteSiteCadastre,
    meta: { requiresAuth: false, desc: 'Cadastro' },
    props: { from: 'fcdf' },
  },
  {
    path: '/site/contratante/:from/:type',
    name: 'sitectba-contratante-cad',
    component: ContratanteSiteCad,
    meta: { requiresAuth: false, desc: 'Cadastro' },
    props: true,
  },
  {
    path: '/company/login',
    name: 'company-login',
    component: LoginCompany,
    meta: { requiresAuth: false, desc: 'Login Clinica' },
  },
];

const routesMedibook = [
  {
    path: '/broker',
    name: 'broker',
    component: RouterView,
    meta: { requiresAuth: true, desc: 'Corretor' },
    children: [
      {
        path: '/broker/list',
        name: 'broker-list',
        component: BrokerList,
        meta: { requiresAuth: true, desc: 'Corretor' },
      },
      {
        path: '/broker/cad/:id?',
        name: 'broker-cad',
        component: BrokerCad,
        meta: { requiresAuth: true, desc: 'Corretor' },
        props: true,
      },
      {
        path: '/broker/banckAccount/:id?',
        name: 'broker-banck-account',
        component: BankAccountCadastre,
        meta: { requiresAuth: true, desc: 'Corretor - Conta Bancária' },
        props: true,
      },
    ],
  },
  {
    path: '/estipulante',
    name: 'estipulante',
    component: RouterView,
    meta: { requiresAuth: true, desc: 'Estipulante' },
    children: [
      {
        path: '/estipulante/list',
        name: 'estipulante-list',
        component: EstipulanteList,
        meta: { requiresAuth: true, desc: 'Estipulantes' },
      },
      {
        path: '/estipulante/cad/:id?',
        name: 'estipulante-cad',
        component: EstipulanteCad,
        meta: { requiresAuth: true, desc: 'Estipulante' },
        props: true,
      },
      {
        path: '/estipulante/banckAccount/:id?',
        name: 'estipulante-banck-account',
        component: BankAccountCadastre,
        meta: { requiresAuth: true, desc: 'Estipulante - Conta Bancária' },
        props: true,
      },
      {
        path: '/estipulante/configuration/:id?',
        name: 'estipulante-configuration',
        component: EstipulanteConfiguration,
        meta: { requiresAuth: true, desc: 'Estipulante - Configuração' },
        props: true,
      },
    ],
  },
  {
    path: '/subestipulante',
    name: 'subestipulante',
    component: RouterView,
    meta: { requiresAuth: true, desc: 'Subestipulante' },
    children: [
      {
        path: '/subestipulante/list',
        name: 'subestipulante-list',
        component: SubestipulanteList,
        meta: { requiresAuth: true, desc: 'Subestipulante' },
      },
      {
        path: '/subestipulante/cad/:id?',
        name: 'subestipulante-cad',
        component: SubestipulanteCad,
        meta: { requiresAuth: true, desc: 'Subestipulante' },
        props: true,
      },
      {
        path: '/subestipulante/banckAccount/:id?',
        name: 'subestipulante-banck-account',
        component: BankAccountCadastre,
        meta: { requiresAuth: true, desc: 'Subestipulante - Conta Bancária' },
        props: true,
      },
    ],
  },
  {
    path: '/contratante',
    name: 'contratante',
    component: RouterView,
    meta: { requiresAuth: true, desc: 'Contratante' },
    children: [
      {
        path: '/contratante/list',
        name: 'contratante-list',
        component: ContratanteList,
        meta: { requiresAuth: true, desc: 'Contratante' },
      },
      {
        path: '/contratante/cad/:id?',
        name: 'contratante-cad',
        component: ContratanteCad,
        meta: { requiresAuth: true, desc: 'Contratante' },
        props: true,
      },
      {
        path: '/contratante/contract/:id?',
        name: 'contratante-contract-upload',
        component: ContratanteContractUpload,
        meta: { requiresAuth: true, desc: 'Contratante' },
        props: true,
      },
      // {
      //   path: '/contratante/banckAccount/:id?',
      //   name: 'contratante-banck-account',
      //   component: BankAccountCadastre,
      //   meta: { requiresAuth: true, desc: 'Contratante - Conta Bancária' },
      //   props: true,
      // },
      {
        path: '/contratante/configuration/:id?',
        name: 'contratante-configuration',
        component: ContratanteConfigurationPj,
        meta: { requiresAuth: true, desc: 'Contratante' },
        props: true,
      },
    ],
  },
  {
    path: '/beneficiary',
    name: 'beneficiary',
    component: RouterView,
    meta: { requiresAuth: true, desc: 'Beneficiários' },
    children: [
      {
        path: '/beneficiary/list',
        name: 'beneficiary-list',
        component: MedibookBeneficiaryList,
        meta: { requiresAuth: true, desc: 'Beneficiários' },
      },
      {
        path: '/changeType',
        name: 'change-type',
        component: ChangeType,
        meta: { requiresAuth: true, desc: 'Alteração Beneficiário' },
        props: true,
      },
    ],
  },
  {
    path: '/dependent',
    name: 'dependent',
    component: RouterView,
    meta: { requiresAuth: true, desc: 'Dependentes' },
    children: [
      {
        path: '/dependent/list',
        name: 'dependent-list',
        component: DependentList,
        meta: { requiresAuth: true, desc: 'Dependentes' },
      },
      {
        path: '/dependent/cad/:id?',
        name: 'dependent-cad',
        component: DependentCad,
        meta: { requiresAuth: true, desc: 'Dependentes' },
        props: true,
      },
    ],
  },
  {
    path: '/invoices',
    name: 'invoice-list',
    component: MedibookInvoices,
    meta: { requiresAuth: true, desc: 'Faturas' },
  },
  {
    path: '/commissions',
    name: 'commissions',
    component: Commissions,
    meta: { requiresAuth: true, desc: 'Regras de Comissões' },
  },
  {
    path: '/configurations',
    name: 'configurations',
    component: Configurations,
    meta: { requiresAuth: true, desc: 'Configurações' },
  },
  {
    path: '/report/adhesion',
    name: 'report-adhesion',
    component: AdhesionReport,
    meta: { requiresAuth: true, desc: 'Relatório - Adesão' },
  },
  {
    path: '/report/cards',
    name: 'report-cards',
    component: CardsReport,
    meta: { requiresAuth: true, desc: 'Relatório - Carteirinhas' },
  },
  {
    path: '/report/commission',
    name: 'report-commission',
    component: CommissionReport,
    meta: { requiresAuth: true, desc: 'Relatório - Comissão' },
  },
  {
    path: '/report/contratantesPjPendentes',
    name: 'report-contratantespj-pendentes',
    component: ContratantesPjPendentesReport,
    meta: { requiresAuth: true, desc: 'Relatório - Contratantes PJ Pendentes' },
  },
];

const routesEstipulante = [
  {
    path: '/estipulante/cadastre',
    name: 'estipulante-edit',
    component: EstipulanteCadEdit,
    meta: { requiresAuth: true, desc: 'Cadastro' },
  },
  {
    path: '/estipulante/banckAccount',
    name: 'banckAccount-edit',
    component: BankAccountEdit,
    meta: { requiresAuth: true, desc: 'Cadastro' },
  },
  // {
  //   path: '/withdraw/list',
  //   name: 'withdraw-list',
  //   component: WithdrawList,
  //   meta: { requiresAuth: true, desc: 'Saques' },
  // },
  {
    path: '/invoices',
    name: 'invoice-list',
    component: InvoiceList,
    meta: { requiresAuth: true, desc: 'Faturas' },
  },
  {
    path: '/links',
    name: 'links',
    component: LinksEstipulante,
    meta: { requiresAuth: true, desc: 'Links' },
  },
];

const routesSubestipulante = [
  {
    path: '/subestipulante/cadastre',
    name: 'estipulante-edit',
    component: SubestipulanteCadEdit,
    meta: { requiresAuth: true, desc: 'Cadastro' },
  },
  {
    path: '/subestipulante/banckAccount',
    name: 'banckAccount-edit',
    component: BankAccountEdit,
    meta: { requiresAuth: true, desc: 'Cadastro' },
  },
  {
    path: '/contratante/list',
    name: 'contratante',
    component: ContratanteListSubestipulante,
    meta: { requiresAuth: true, desc: 'Contratante' },
  },
  {
    path: '/invoices',
    name: 'invoice-list',
    component: InvoiceList,
    meta: { requiresAuth: true, desc: 'Faturas' },
  },
];

const routesBroker = [
  {
    path: '/broker/cadastre',
    name: 'broker-edit',
    component: BrokerCadEdit,
    meta: { requiresAuth: true, desc: 'Cadastro' },
  },
  {
    path: '/broker/banckAccount',
    name: 'banckAccount-edit',
    component: BankAccountEdit,
    meta: { requiresAuth: true, desc: 'Cadastro' },
  },
  {
    path: '/contratante',
    name: 'contratante',
    component: RouterView,
    meta: { requiresAuth: true, desc: 'Contratante' },
    children: [
      {
        path: '/contratante/list',
        name: 'contratante-list',
        component: ContratanteList,
        meta: { requiresAuth: true, desc: 'Contratante' },
      },
      {
        path: '/contratante/cad/:id?',
        name: 'contratante-cad',
        component: ContratanteCad,
        meta: { requiresAuth: true, desc: 'Contratante' },
        props: true,
      },
    ],
  },
  {
    path: '/brokers',
    name: 'brokers',
    component: RouterView,
    meta: { requiresAuth: true, desc: 'Corretores' },
    children: [
      {
        path: '/brokers/list',
        name: 'brokers-list',
        component: BrokersList,
        meta: { requiresAuth: true, desc: 'Corretores' },
      },
      {
        path: '/brokers/cad/:id?',
        name: 'brokers-cad',
        component: BrokersCad,
        meta: { requiresAuth: true, desc: 'Corretores' },
        props: true,
      },
    ],
  },
  // {
  //   path: '/withdraw/list',
  //   name: 'withdraw-list',
  //   component: WithdrawList,
  //   meta: { requiresAuth: true, desc: 'Saques' },
  // },
  {
    path: '/invoices',
    name: 'invoice-list',
    component: InvoiceList,
    meta: { requiresAuth: true, desc: 'Faturas' },
  },
  {
    path: '/leads',
    name: 'lead-list',
    component: LeadList,
    meta: { requiresAuth: true, desc: 'Leads' },
  },
  {
    path: '/links',
    name: 'links',
    component: LinksBrokers,
    meta: { requiresAuth: true, desc: 'Links' },
  },
];

const routesBrokers = [
  {
    path: '/contratante',
    name: 'contratante',
    component: RouterView,
    meta: { requiresAuth: true, desc: 'Contratante' },
    children: [
      {
        path: '/contratante/list',
        name: 'contratante-list',
        component: ContratanteList,
        meta: { requiresAuth: true, desc: 'Contratante' },
      },
      {
        path: '/contratante/cad/:id?',
        name: 'contratante-cad',
        component: ContratanteCad,
        meta: { requiresAuth: true, desc: 'Contratante' },
        props: true,
      },
      {
        path: '/contratante/contract/:id?',
        name: 'contratante-contract-upload',
        component: ContratanteContractUpload,
        meta: { requiresAuth: true, desc: 'Contratante' },
        props: true,
      },
      {
        path: '/contratante/configuration/:id?',
        name: 'contratante-configuration',
        component: ContratanteConfigurationPj,
        meta: { requiresAuth: true, desc: 'Contratante' },
        props: true,
      },
    ],
  },
  {
    path: '/beneficiary',
    name: 'beneficiary',
    component: RouterView,
    meta: { requiresAuth: true, desc: 'Beneficiário' },
    children: [
      {
        path: '/beneficiary/list',
        name: 'beneficiary-list',
        component: BeneficiaryList,
        meta: { requiresAuth: true, desc: 'Beneficiário' },
      },
      {
        path: '/beneficiary/cad/:id?',
        name: 'beneficiary-cad',
        component: BeneficiaryCad,
        meta: { requiresAuth: true, desc: 'Beneficiário' },
        props: true,
      },
      {
        path: '/changeType',
        name: 'change-type',
        component: ChangeType,
        meta: { requiresAuth: true, desc: 'Alteração Beneficiário' },
        props: true,
      },
    ],
  },
  {
    path: '/beneficiary/import',
    name: 'beneficiary-import',
    component: BeneficiaryImport,
    meta: { requiresAuth: true, desc: 'Importaçao de Beneficiário' },
    props: true,
  },
  {
    path: '/dependent',
    name: 'dependent',
    component: RouterView,
    meta: { requiresAuth: true, desc: 'Dependentes' },
    children: [
      {
        path: '/dependent/list',
        name: 'dependent-list',
        component: DependentList,
        meta: { requiresAuth: true, desc: 'Dependentes' },
      },
      {
        path: '/dependent/cad/:id?',
        name: 'dependent-cad',
        component: DependentCad,
        meta: { requiresAuth: true, desc: 'Dependentes' },
        props: true,
      },
    ],
  },
];

const routesContratantePj = [
  {
    path: '/configuration/:id?',
    name: 'contratante-configuration',
    component: ContratanteConfigurationPjEdit,
    meta: { requiresAuth: true, desc: 'Contratante' },
    props: true,
  },
  {
    path: '/contratante/contract/:id?',
    name: 'contratante-contract-upload',
    component: ContratanteContractUpload,
    meta: { requiresAuth: true, desc: 'Contratante' },
    props: true,
  },
  {
    path: '/contratante/cadastre',
    name: 'cadastre-edit',
    component: ContratanteCadEdit,
    meta: { requiresAuth: true, desc: 'Cadastro' },
  },
  {
    path: '/beneficiary',
    name: 'beneficiary',
    component: RouterView,
    meta: { requiresAuth: true, desc: 'Beneficiário' },
    children: [
      {
        path: '/beneficiary/list',
        name: 'beneficiary-list',
        component: BeneficiaryList,
        meta: { requiresAuth: true, desc: 'Beneficiário' },
        props: { handleDimissed: true },
      },
      {
        path: '/beneficiary/cad/:id?',
        name: 'beneficiary-cad',
        component: BeneficiaryCad,
        meta: { requiresAuth: true, desc: 'Beneficiário' },
        props: true,
      },
      {
        path: '/beneficiary/import/:id?',
        name: 'beneficiary-import',
        component: BeneficiaryImport,
        meta: { requiresAuth: true, desc: 'Beneficiário' },
        props: true,
      },
    ],
  },
  {
    path: '/dependent',
    name: 'dependent',
    component: RouterView,
    meta: { requiresAuth: true, desc: 'Dependentes' },
    children: [
      {
        path: '/dependent/list',
        name: 'dependent-list',
        component: DependentList,
        meta: { requiresAuth: true, desc: 'Dependentes' },
      },
      {
        path: '/dependent/cad/:id?',
        name: 'dependent-cad',
        component: DependentCad,
        meta: { requiresAuth: true, desc: 'Dependentes' },
        props: true,
      },
    ],
  },
  {
    path: '/invoice',
    name: 'invoice',
    component: RouterView,
    meta: { requiresAuth: true, desc: 'Faturas' },
    children: [
      {
        path: '/invoice/list',
        name: 'invoice-list',
        component: ContratanteInvoices,
        meta: { requiresAuth: true, desc: 'Faturas' },
      },
    ],
  },
];

const routesContratantePf = [
  {
    path: '/contratante/cadastre',
    name: 'cadastre-edit',
    component: ContratanteCadEdit,
    meta: { requiresAuth: true, desc: 'Cadastro' },
  },
  {
    path: '/configuration/:id?',
    name: 'contratante-configuration',
    component: ContratanteConfigurationPjEdit,
    meta: { requiresAuth: true, desc: 'Contratante' },
    props: true,
  },
  {
    path: '/dependent',
    name: 'dependent',
    component: RouterView,
    meta: { requiresAuth: true, desc: 'Dependentes' },
    children: [
      {
        path: '/dependent/list',
        name: 'dependent-list',
        component: DependentListPf,
        meta: { requiresAuth: true, desc: 'Dependentes' },
      },
      {
        path: '/dependent/cad/:id?',
        name: 'dependent-cad',
        component: DependentCad,
        meta: { requiresAuth: true, desc: 'Dependentes' },
        props: true,
      },
    ],
  },
  {
    path: '/invoice',
    name: 'invoice',
    component: RouterView,
    meta: { requiresAuth: true, desc: 'Faturas' },
    children: [
      {
        path: '/invoice/list',
        name: 'invoice-list',
        component: ContratanteInvoices,
        meta: { requiresAuth: true, desc: 'Faturas' },
      },
    ],
  },
  {
    path: '/subscription',
    name: 'contratantepf-subscription',
    component: Subscription,
    meta: { requiresAuth: true, desc: 'Assinatura' },
    props: true,
  },
];

const routesCompany = [
  {
    path: '/beneficiaries',
    name: 'beneficiaries-list',
    component: BeneficiaryStatusList,
    meta: { requiresAuth: true, desc: 'Beneficiários' },
  },
];

const getRoutes = (type) => {
  if (type === 'medibook') {
    return routesMedibook;
  } if (type === 'estipulante') {
    return routesEstipulante;
  } if (type === 'subestipulante') {
    return routesSubestipulante;
  } if (type === 'contratantepj') {
    return routesContratantePj;
  } if (type === 'contratantepf') {
    return routesContratantePf;
  } if (type === 'broker') {
    return routesBroker;
  } if (type === 'brokers') {
    return routesBrokers;
  } if (type === 'company') {
    return routesCompany;
  }

  return routesCommon;
};

export {
  routesCommon,
  routesMedibook,
  routesEstipulante,
  routesSubestipulante,
  routesContratantePj,
  routesContratantePf,
  routesBroker,
  routesBrokers,
  routesCompany,
  getRoutes,
};
