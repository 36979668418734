<template>
  <v-container>
    <v-row class="mb-5">
      <v-col cols="12" class="pa-0 ma-0">
        <contratante-configuration-pj v-model="configuration" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ContratanteConfigurationPj from '@/components/contratante/ContratanteConfigurationPj';

const Hashids = require('hashids');

export default {
  components: {
    ContratanteConfigurationPj,
  },
  data: () => ({
    hashids: null,
    configuration: null,
  }),
  created() {
    // eslint-disable-next-line new-cap
    this.hashids = new Hashids.default(
      process.env.VUE_APP_HASHID_SALT, Number(process.env.VUE_APP_HASHID_PAD),
    );

    // eslint-disable-next-line prefer-destructuring
    const companyId = this.hashids.decode(this.$route.query.id)[0];

    this.get(companyId);
  },
  methods: {
    get(id) {
      this.$store
        .dispatch('contratanteConfiguration/one', id)
        .then((configuration) => {
          this.configuration = configuration;
        });
    },
  },
};
</script>
