import { pg, filter } from '../utils/query';
import HTTP from './axios';

const WithdrawService = {
  allByContratante: (parameters) => HTTP.get(`/companyEco/${parameters.companyId}/withdraw?${pg(parameters)}${filter(parameters)}`)
    .then((response) => response.data),
  create: (companyId) => HTTP.post(`/companyEco/${companyId}/withdraw`)
    .then((response) => response.data),
};

export default WithdrawService;
