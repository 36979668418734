<template>
  <v-container>
    <v-progress-linear indeterminate :active="loading" />
    <v-data-table
      :headers="headers"
      :items="contratantes"
      :options.sync="options"
      :server-items-length="total"
      item-key="id"
      sort-by="id"
      class="elevation-1"
    >

      <template v-slot:top>
        <v-container fluid class="pa-0 ma-0 py-4 pb-6">
          <v-row class="pa-0 ma-0 ml-5">
            <v-col
              cols="12" class="pa-0 ma-0 text--disabled"
            >
              Pedentes de?
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col
              xl="5" lg="5" md="5" sm="5"
              cols="12" class="pa-0 ma-0 pl-4"
            >
              <v-radio-group
                v-model="filter.type" row
                @change="changeFilter"
              >
                <v-radio
                  label="Contrato"
                  value="contract"
                ></v-radio>
                <v-radio
                  label="Verificação"
                  value="verification"
                ></v-radio>
                <v-radio
                  label="Pagamento"
                  value="payment"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:[`item.document`]="{ item }">
        {{ item.document | document }}
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <div class="mb-upper">{{ item.type }}</div>
      </template>

      <template v-slot:[`item.mobile`]="{ item }">
        {{ item.mobile | phone }}
      </template>

      <template v-slot:[`item.phone`]="{ item }">
        {{ item.phone | phone }}
      </template>

      <template v-slot:[`item.verified`]="{ item }">
        <v-tooltip left v-if="item.type == 'pj'">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>{{ $options.filters.verifiedIcon(item.verified) }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $options.filters.verified(item.verified) }}</span>
        </v-tooltip>
        <v-tooltip left v-else>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </template>
          <span>Não se aplica</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.contractFile`]="{ item }">
        <v-tooltip left v-if="item.type == 'pj'">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">{{ $options.filters.contractIcon(item.contractFile) }}</v-icon>
          </template>
          <span>{{ $options.filters.contract(item.contractFile) }}</span>
        </v-tooltip>
        <v-tooltip left v-else>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </template>
          <span>Não se aplica</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.paymentStatus`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>{{ $options.filters.paymentStatusIcon(item.paymentStatus) }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $options.filters.paymentStatus(item.paymentStatus) }}</span>
        </v-tooltip>
      </template>

      <template
        v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon @click="handleEdit(item)">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon @click="handleConfig(item)">mdi-cog</v-icon>
            </v-btn>
          </template>
          <span>Configurações</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import bus from '@/plugins/eventBus';

export default {
  components: {
  },
  data: () => ({
    Hashids: null,
    total: 0,
    edit: false,
    headers: [
      {
        text: 'Nome', align: 'start', sortable: false, value: 'name',
      },
      // { text: 'Nome de Divulgação', value: 'slug' },
      { text: 'Documento', value: 'document' },
      { text: 'Tipo', value: 'type' },
      { text: 'Contato', value: 'contact' },
      { text: 'Email', value: 'email' },
      { text: 'Celular', value: 'mobile' },
      { text: 'Telefone', value: 'phone' },
      // { text: 'Status', value: 'paymentAccountStatus' },
      { text: 'Verificado', value: 'verified' },
      { text: 'Contrato', value: 'contractFile' },
      { text: 'Status Finan.', value: 'paymentStatus' },
      { text: 'Ações', value: 'actions' },
    ],
    options: {
      itemsPerPage: 10,
    },
    filter: {
      type: 'verification',
    },
    verifieds: [
      { txt: 'Todos', vl: 'all' },
      { txt: 'Verificados', vl: '1' },
      { txt: 'Não verificados', vl: '0' },
    ],
    contracts: [
      { txt: 'Todos', vl: 'all' },
      { txt: 'Com contratos', vl: '1' },
      { txt: 'Sem contratos', vl: '0' },
    ],
    paymentsStatus: [
      { txt: 'Todos', vl: 'all' },
      { txt: 'Pagamentos Ok', vl: 'ok' },
      { txt: 'Com pendência', vl: 'pending' },
    ],
    contratantes: [],
    loading: false,
  }),
  watch: {
    options: {
      handler() {
        if (this.options.page != null && this.options.itemsPerPage != null) {
          this.getPendents();
        }
      },
      deep: true,
    },
  },
  methods: {
    getPendents() {
      this.loading = true;

      const params = {};
      params.pagination = { page: this.options.page, size: this.options.itemsPerPage };
      params.filter = this.filter;

      this.$store.dispatch('contratante/pjPendents', params).then((resp) => {
        if (resp) {
          this.total = resp.meta.total;
          this.contratantes = resp.data;
        }
      }).catch((err) => {
        console.log(err);
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    changeFilter() {
      this.getPendents();
    },
    handleEdit(item) {
      const subestipulanteId = (this.subestipulante) ? this.subestipulante.id : null;
      this.$router.push({
        name: 'contratante-cad',
        params: {
          id: this.Hashids.encode(item.id),
          estipulante: item,
        },
        query: {
          eId: this.Hashids.encode(this.estipulante.id),
          sbId: this.Hashids.encode(subestipulanteId),
        },
      });
    },
    handleConfig(item) {
      this.$router.push({
        name: 'contratante-configuration',
        params: {
          id: this.Hashids.encode(item.id),
        },
        query: {
          id: this.Hashids.encode(item.id),
        },
      });
    },
  },
};
</script>

<style scoped>
  .mb-upper {
    text-transform: uppercase
  }
</style>
