<template>
  <v-container>
    <v-progress-linear indeterminate :active="loading" />
    <v-data-table
      :headers="headers"
      :items="leads"
      item-key="id"
      sort-by="id"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:top>
        <v-container fluid class="pa-0 ma-0 py-4 pb-6">
          <v-row class="pa-0 ma-0">
            <v-col
              sm="3" md="3" lg="2" xl="2"
              cols="12" class="pa-0 ma-0 pl-4 pt-2">
              <mb-date
                v-model="filter.dateStart"
                name="lde_fltr_date_start"
                label="Data Inicial"
                :max="filter.dateEnd"
                @input="changeFilter"
              />
            </v-col>
            <v-col
              sm="3" md="3" lg="2" xl="2"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-date
                v-model="filter.dateEnd"
                name="lde_fltr_date_end"
                label="Data Final"
                rules="dateAfter:@invfltr_fltr_date_end"
                :min="filter.dateStart"
                @input="changeFilter"
              />
            </v-col>
            <v-col
              sm="3" md="3" lg="2" xl="2"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-select
                v-model="filter.type" :items="types"
                item-text="txt" item-value="vl" name="lde_fltr_payment_types" label="PF/PJ"
                @input="changeFilter"
              />
            </v-col>
            <v-col
              sm="3" md="2" lg="2" xl="2"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <mb-select
                v-model="filter.status" :items="statusList"
                item-text="txt" item-value="vl" name="lde_fltr_payment_status" label="Status"
                @input="changeFilter"
              />
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col
              sm="2" md="2" lg="2" xl="2"
              cols="12" class="pa-0 ma-0 pl-4 pt-2"
            >
              <v-btn
                color="primary" dark class="mb-2" :loading="exportLoading"
                @click="exportToExcel" :disabled="!leads || leads.length == 0"
              >
                Exportar
                <v-icon right dark>
                  mdi-microsoft-excel
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:[`item.mobile`]="{ item }">
        {{ item.mobile | phone }}
      </template>

      <template v-slot:[`item.type`]="{ item }">
        {{ $options.filters.personType(item.type) }}
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAt | dateTime }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="text-capitalize">{{ item.status }}</div>
      </template>

    </v-data-table>

  </v-container>
</template>

<script>
import bus from '@/plugins/eventBus';
import MbDate from '@/components/general/inputs/MbDate';
import MbSelect from '@/components/general/inputs/MbSelect';

export default {
  components: {
    MbDate,
    MbSelect,
  },
  data: () => ({
    headers: [
      {
        text: 'id', align: 'center', value: 'id',
      },
      {
        text: 'Nome', align: 'start', value: 'name',
      },
      { text: 'Email', value: 'email' },
      { text: 'Celular', value: 'mobile' },
      { text: 'PF/PJ', align: 'center', value: 'type' },
      { text: 'Status', align: 'center', value: 'status' },
      { text: 'Data', value: 'createdAt' },
    ],
    types: [
      { vl: 'all', txt: 'Todos' },
      { vl: 'pf', txt: 'PF' },
      { vl: 'pj', txt: 'PJ' },
    ],
    statusList: [
      { vl: 'novo', txt: 'Novos' },
      { vl: 'exportado', txt: 'Exportado' },
    ],
    filter: {
      dateStart: null,
      dateEnd: null,
      type: 'pj',
      status: 'novo',
    },
    leads: [],
    loading: false,
    exportLoading: false,
  }),
  computed: {
  },
  watch: {
  },
  created() {
    this.getAll();
  },
  methods: {
    changeFilter() {
      this.getAll();
    },
    getAll() {
      this.loading = true;
      this.$store.dispatch('lead/all', {
        pagination: { page: 1, size: -1 },
        filter: this.filter,
      }).then((resp) => {
        this.leads = resp.data;
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    exportToExcel() {
      this.exportLoading = true;
      this.$store.dispatch('lead/exportExcel', {
        filter: this.filter,
      }).then((resp) => {
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${resp}`;
        const downloadLink = document.createElement('a');
        const fileName = `leads-${this.$moment().format('DDMMYY_HH-mm-ss')}.xlsx`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        this.getAll();
      }).catch((err) => {
        bus.$emit('message', {
          message: err.response.data.message,
          type: 'error',
        });
      }).finally(() => {
        this.exportLoading = false;
      });
    },
  },
};
</script>
