<template>
  <v-card class="elevation-1">
    <v-card-title>
      <span class="headline mb-0">Importação de Beneficiário</span>
    </v-card-title>

    <v-card-text>
      <v-row dense class="pa-0 ma-0">
        <v-col
          xl="2" lg="2" md="" sm=""
          cols="12" class="pa-0 ma-0 pt-2"
        >
          <v-btn
            color="primary" class="mb-2" link
            :href="modelPath"
            :disable="loading"
          >
            Download do Modelo
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense class="pa-0 ma-0 mt-4 mb-1 py-2">
        Escolha o arquivo para importar:
      </v-row>
      <v-row dense class="pa-0 ma-0">
        <v-col
          xl="5" lg="5" md="5" sm="5"
          cols="12" class="pa-0 ma-0 mr-4">
          <v-file-input
            v-model="file"
            outlined dense prepend-icon="mdi-microsoft-excel"
            truncate-length="50"
            accept=".xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            :disabled="loading">
          </v-file-input>
        </v-col>
        <v-col
          xl="2" lg="2" md="" sm=""
          cols="12" class="pa-0 ma-0"
        >
          <v-btn
            color="primary" class="mb-2"
            @click="doImport"
            :loading="loading"
            :disabled="!file || loading"
          >
            Importar
            <v-icon right dark >
              mdi-cog
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <!-- Result -->
      <v-row dense class="pa-0 ma-0 mt-2" v-if="result">
        <v-divider></v-divider>
        <v-col cols="12" class="pa-0 ma-0 mr-4 title py-5">
          <v-icon large color="green darken-2" v-if="result.recordsError.length <= 0">
            mdi-check-outline
          </v-icon>
          <v-icon large color="orange darken-2" v-else>
            mdi-alert-outline
          </v-icon>
          <span class="ml-5">
            {{result.message}}
          </span>
        </v-col>
      </v-row>
      <!-- Errors -->
      <v-row dense class="pa-0 ma-0 mt-2" v-if="result && result.recordsError.length > 0">
        <v-col
          cols="12" class="pa-0 ma-0 mr-4"
          v-for="recordError in result.recordsError" :key="recordError.line">
          Linha {{ recordError.line }}
          {{ (recordError.cpf) ? ` - (cpf: ${recordError.cpf})`: '' }}
          : {{ recordError.errors.join() }}
        </v-col>
      </v-row>
    </v-card-text>
</v-card>
</template>

<script>
import bus from '@/plugins/eventBus';

export default {
  components: {
  },
  props: {
  },
  computed: {
    modelPath() {
      return `${process.env.VUE_APP_API_PATH}/econocard_beneficiary.xlsx`;
    },
  },
  data: () => ({
    loading: false,
    file: null,
    result: null,
  }),
  created() {
  },
  methods: {
    doImport() {
      this.loading = true;
      const formData = new FormData();
      formData.append('file', this.file);

      this.$store
        .dispatch('beneficiary/import', {
          file: formData,
        })
        .then((res) => {
          // console.log(res);
          this.result = res;
        })
        .catch((err) => {
          console.log(err);
          bus.$emit('message', {
            message: err.response.data.message,
            type: 'error',
          });
        })
        .finally(async () => {
          this.loading = false;
        });
    },
  },
};
</script>
