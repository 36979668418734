<template>
  <v-container class="pa-0 ma-0">
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-row justify="center">
        <v-col
          xl="4" lg="4" md="4" sm="5"
          cols="12"
        >
          <v-row class="pa-0 ma-0">
            <v-col
              v-for="i in 6" :key="i" xl="2" lg="2"
              md="2" sm="2" cols="2"
              class="pa-0 ma-0 pa-1"
            >
              <mb-input-token
                :ref="`cd${i}`" v-model="code[i]" :name="`code${i}`" label=""
                rules="required" @input="(vl) => { inputCode(i, vl) }"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <mb-input-password
                ref="chpass_password_inpt" v-model="password"
                name="chpass_password" label="Senha" rules="required"
              />
            </v-col>
            <v-col cols="12">
              <mb-input-password
                v-model="passwordConfirm"
                name="chpass_passwordConfirm" label="Confirmação da Senha"
                rules="required|password:chpass_password"
              />
            </v-col>
            <!-- Button -->
            <v-col cols="12">
              <mb-text-button
                name="chpass_change" label="Alterar senha"
                :disabled="invalid" :loading="changeBtnLoading" @click="change"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import bus from '@/plugins/eventBus';
import MbInputPassword from '@/components/general/inputs/MbInputPassword';
import MbInputToken from '@/components/general/inputs/MbInputToken';
import MbTextButton from '@/components/general/buttons/MbTextButton';

export default {
  components: {
    ValidationObserver,
    MbInputPassword,
    MbTextButton,
    MbInputToken,
  },
  props: {
    phoneOrEmailOrDoc: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    code: [],
    password: null,
    passwordConfirm: null,
    changeBtnLoading: false,
  }),
  methods: {
    async change() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.changeBtnLoading = true;
        const data = { code: this.code.join(''), phoneOrEmailOrDoc: this.phoneOrEmailOrDoc, password: this.password };

        this.$store.dispatch('user/resetPassword', data)
          .then(() => {
            bus.$emit('message', {
              message: 'Senha atualizada com sucesso!',
              type: 'success',
            });
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(() => {
            this.changeBtnLoading = false;
          });
      }
    },
    inputCode(i, vl) {
      if (vl && vl.length > 0) {
        if (i < 6) {
          this.$refs[`cd${i + 1}`][0].$refs[`code${i + 1}`].focus();
        } else {
          this.$refs.chpass_password_inpt.$children[0].$children[0].focus();
        }
      }
    },
  },
};
</script>
