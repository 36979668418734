<template>
  <v-card elevation="0">
   <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-card-text>
        <v-row class="pa-0 ma-0">
          <form autocomplete="off">
            <!-- Description/Lifes Number/Value/With Adhesion -->
            <v-row>
              <!-- Description -->
              <v-col
                xl="6" lg="6" md="6" sm="6"
                cols="12" >
                <mb-input-text
                  v-model="plan.description"
                  name="pln_description"
                  label="Descrição"
                  hideDetails="auto"
                  rules="required|max:150"
                />
              </v-col>
              <!-- Lifes Number -->
              <v-col
                xl="3" lg="3" md="3" sm="3"
                cols="12" >
                <mb-input-number
                  v-model="plan.maxLifes"
                  name="pln_max_lifes"
                  label="Número de vidas"
                  hideDetails="auto"
                  rules="required|min_value:1|max_value:999999999"
                  :disabled="plan && plan.id != null"
                />
              </v-col>
              <!-- Value -->
              {{val}}
              <v-col
                xl="3" lg="3" md="3" sm="3"
                cols="12" >
                <mb-input-money
                  v-model="plan.value"
                  name="cnf_value"
                  label="Valor mensal"
                  hideDetails="auto"
                  rules="required"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                xl="3" lg="3" md="3" sm="3"
                cols="12" >
                <v-switch
                  v-model="plan.withoutBound"
                  label="Sem vínculo"
                  :disabled="plan && plan.id != null"
                ></v-switch>
              </v-col>
              <!-- With Adhesion -->
              <v-col
                xl="3" lg="3" md="3" sm="3"
                cols="12" >
                <v-switch
                  v-model="plan.withAdhesion"
                  label="Com adesão?"
                  :disabled="plan && (type == 'pj')"
                ></v-switch>
                <!-- :disabled="plan && (plan.id != null || type == 'pj')" -->
              </v-col>

              <v-col
                xl="3" lg="3" md="3" sm="3"
                cols="12" >
                <v-switch
                  v-model="plan.special"
                  label="Especial?"
                ></v-switch>
              </v-col>

              <v-col
                xl="3" lg="3" md="3" sm="3"
                cols="12" >
                <v-switch
                  v-model="plan.active"
                  label="Ativo?"
                ></v-switch>
              </v-col>
            </v-row>
          </form>

        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col
          xl="3" lg="3" md="3" sm="3"
          cols="12" >
          <mb-text-button
            name="cnf_btn_save" label="Salvar"
            :disabled="invalid"
            :loading="saveBtnLoading"
            class="ml-2 mb-2"
            @click="save"
          />
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          xl="3" lg="3" md="3" sm="3"
          cols="12" >
          <mb-text-button
            name="prd_btn_cancel" label="Fechar" color="accent"
            class="mb-2" @click="close"
          />
        </v-col>
      </v-card-actions>
    </ValidationObserver>
  </v-card>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import bus from '@/plugins/eventBus';
import MbInputText from '@/components/general/inputs/MbInputText';
import MbInputMoney from '@/components/general/inputs/MbInputMoney';
import MbInputNumber from '@/components/general/inputs/MbInputNumber';
import MbTextButton from '@/components/general/buttons/MbTextButton';

export default {
  components: {
    ValidationObserver,
    MbInputMoney,
    MbInputText,
    MbInputNumber,
    MbTextButton,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    estipulante: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      saveBtnLoading: false,
      plan: null,
      defaultPlan: {
        description: null,
        active: true,
        maxLifes: null,
        value: 0,
        withAdhesion: true,
        withoutBound: false,
        special: false,
      },
      val: null,
    };
  },
  computed: {
  },
  watch: {
    value(vl) {
      this.plan = vl || JSON.parse(JSON.stringify(this.defaultPlan));
      this.plan.type = this.type;
      this.$refs.observer.reset();
    },
  },
  created() {
    this.defaultPlan.type = this.type;

    this.plan = this.value || JSON.parse(JSON.stringify(this.defaultPlan));
    this.plan.type = this.type;
  },
  methods: {
    save() {
      if (this.plan.id) {
        this.update();
      } else {
        this.create();
      }
    },
    async create() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.plan.estipulanteId = this.estipulante.id;
        this.saveBtnLoading = true;
        this.$store
          .dispatch('plan/create', this.plan)
          .then(() => {
            bus.$emit('message', {
              message: 'Plano salvo com sucesso!',
              type: 'success',
            });
            this.$emit('updated');
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
    async update() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.saveBtnLoading = true;
        this.$store
          .dispatch('plan/update', this.plan)
          .then(() => {
            bus.$emit('message', {
              message: 'Plano atualizado com sucesso!',
              type: 'success',
            });
            this.$emit('updated');
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
    close() {
      this.plan = JSON.parse(JSON.stringify(this.defaultPlan));
      this.$refs.observer.reset();
      this.$emit('closed');
    },
  },
};
</script>
