import HTTP from './axios';

const BankAccountService = {
  one: (id) => HTTP.get(`/companyEco/${id}/bankAccount`).then((response) => response.data),
  create: (p) => HTTP.post(`/companyEco/${p.companyId}/bankAccount`, p.bankAccount)
    .then((response) => response.data),
  update: (bankAccount) => HTTP.put(`/bankAccount/${bankAccount.id}`, bankAccount)
    .then((response) => response.data),
};

export default BankAccountService;
