import store from '../store';

const menuMedibook = [
  {
    path: '/estipulante/list',
    title: 'Estipulantes',
    icon: 'mdi-handshake',
  },
  {
    path: '/subestipulante/list',
    title: 'Subestipulantes',
    icon: 'mdi-account-group',
  },
  {
    path: '/broker/list',
    title: 'Corretoras',
    icon: 'mdi-account-tie-voice',
  },
  {
    path: '/contratante/list',
    title: 'Contratantes',
    icon: 'mdi-office-building',
  },
  {
    path: '/beneficiary/list',
    title: 'Alteração Beneficiário',
    icon: 'mdi-account',
  },
  {
    path: '/invoices',
    title: 'Faturas',
    icon: 'mdi-receipt',
  },
  {
    path: '/commissions',
    title: 'Regras Comissão',
    icon: 'mdi-file-percent',
  },
  {
    path: '/configurations',
    title: 'Configurações',
    icon: 'mdi-cogs',
  },
  {
    title: 'Relatórios',
    icon: 'mdi-file-chart',
    items: [
      {
        path: '/report/adhesion',
        title: 'Adesão',
        icon: 'mdi-finance',
      },
      {
        path: '/report/cards',
        title: 'Carteirinhas',
        icon: 'mdi-card-account-details',
      },
      {
        path: '/report/commission',
        title: 'Comissão',
        icon: 'mdi-bank-transfer-out',
      },
      {
        path: '/report/contratantesPjPendentes',
        title: 'PJ Pendentes',
        icon: 'mdi-text-box-check-outline',
      },
    ],
  },
];

const menuContratantePj = [
  {
    path: '/contratante/cadastre',
    title: 'Cadastro',
    icon: 'mdi-note-text',
  },
  {
    path: '/configuration',
    title: 'Configurações',
    icon: 'mdi-cogs',
  },
  {
    path: '/beneficiary/list',
    title: 'Beneficiários',
    icon: 'mdi-account',
  },
  {
    path: '/beneficiary/import',
    title: 'Beneficiário Import.',
    icon: 'mdi-table-arrow-up',
  },
  {
    path: '/invoice/list',
    title: 'Faturas',
    icon: 'mdi-receipt',
  },
];

const menuContratantePf = [
  {
    path: '/contratante/cadastre',
    title: 'Cadastro',
    icon: 'mdi-note-text',
  },
  {
    path: '/dependent/list',
    title: 'Dependentes',
    icon: 'mdi-account-multiple',
  },
  {
    path: '/subscription',
    title: 'Assinatura',
    icon: 'mdi-webpack',
  },
  {
    path: '/invoice/list',
    title: 'Faturas',
    icon: 'mdi-receipt',
  },
];

const menuEstipulante = [
  {
    path: '/estipulante/cadastre',
    title: 'Cadastro',
    icon: 'mdi-note-text',
  },
  {
    path: '/estipulante/banckAccount',
    title: 'Conta Bancária',
    icon: 'mdi-account-cash',
  },
  // {
  //   path: '/withdraw/list',
  //   title: 'Saques',
  //   icon: 'mdi-wallet',
  // },
  {
    path: '/invoices',
    title: 'Faturas',
    icon: 'mdi-receipt',
  },
  {
    path: '/links',
    title: 'Links',
    icon: 'mdi-link-variant',
  },
];

const menuSubestipulante = [
  {
    path: '/subestipulante/cadastre',
    title: 'Cadastro',
    icon: 'mdi-note-text',
  },
  {
    path: '/subestipulante/banckAccount',
    title: 'Conta Bancária',
    icon: 'mdi-account-cash',
  },
  {
    path: '/contratante/list',
    title: 'Contratantes',
    icon: 'mdi-office-building',
  },
  // {
  //   path: '/withdraw/list',
  //   title: 'Saques',
  //   icon: 'mdi-wallet',
  // },
  {
    path: '/invoices',
    title: 'Faturas',
    icon: 'mdi-receipt',
  },
];

const menuBroker = [
  {
    path: '/broker/cadastre',
    title: 'Cadastro',
    icon: 'mdi-note-text',
  },
  {
    path: '/broker/banckAccount',
    title: 'Conta Bancária',
    icon: 'mdi-account-cash',
  },
  {
    path: '/brokers/list',
    title: 'Corretores',
    icon: 'mdi-account-tie-voice',
  },
  {
    path: '/contratante/list',
    title: 'Contratantes',
    icon: 'mdi-office-building',
  },
  // {
  //   path: '/withdraw/list',
  //   title: 'Saques',
  //   icon: 'mdi-wallet',
  // },
  {
    path: '/invoices',
    title: 'Faturas',
    icon: 'mdi-receipt',
  },
  {
    path: '/links',
    title: 'Links',
    icon: 'mdi-link-variant',
  },
  {
    path: '/leads',
    title: 'Leads',
    icon: 'mdi-account-cog',
  },
];

const menuBrokers = [
  {
    path: '/contratante/list',
    title: 'Contratantes',
    icon: 'mdi-office-building',
  },
  {
    path: '/beneficiary/list',
    title: 'Beneficiários',
    icon: 'mdi-account',
  },
  {
    path: '/beneficiary/import',
    title: 'Beneficiário Import.',
    icon: 'mdi-table-arrow-up',
  },
];

const routesCompany = [
  {
    path: '/beneficiaries',
    title: 'Beneficiários',
    icon: 'mdi-account',
  },
];

const getMenu = () => {
  const user = store.getters['user/getUser'];
  if (user.type === 'medibook') {
    return menuMedibook;
  } if (user.loginType === 'estipulante') {
    return menuEstipulante;
  } if (user.loginType === 'subestipulante') {
    return menuSubestipulante;
  } if (user.loginType === 'contratantepj') {
    return menuContratantePj;
  } if (user.loginType === 'contratantepf') {
    return menuContratantePf;
  } if (user.loginType === 'broker') {
    return menuBroker;
  } if (user.loginType === 'brokers') {
    return menuBrokers;
  } if (user.loginType === 'company') {
    return routesCompany;
  }

  return [];
};

export { getMenu as default };
