<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="name"
    :name="label"
    :mode="validationMode"
    :rules="`cpf|${rules}`"
  >
    <v-text-field
      :id="name"
      v-model="internalValue"
      v-mask="'###.###.###-##'"
      autocomplete="off"
      :label="label"
      :hint="hint"
      :persistent-hint="persistentHint"
      :placeholder="placeholder"
      :append-icon="appendicon"
      :prefix="prefix"
      :suffix="suffix"
      type="text"
      :loading="loading"
      :disabled="disabled"
      outlined dense
      :error="errors.length > 0"
      :error-messages="errors[0]"
       @change="change"
      @input="input"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mask } from 'vue-the-mask';

export default {
  directives: { mask },
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    validationMode: {
      type: String,
      default: 'lazy',
    },
    hint: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    appendicon: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: null,
    },
  },
  data: () => ({
    internalValue: null,
  }),
  watch: {
    value(vl) {
      this.internalValue = vl;
    },
  },
  created() {
    this.internalValue = this.value || null;
  },
  methods: {
    input(vl) {
      this.$emit('input', this.unmask(vl));
    },
    change(vl) {
      this.$emit('change', this.unmask(vl));
    },
    unmask(vl) {
      return vl.replace(/\D+/g, '');
    },
  },
};
</script>
