import HTTP from './axios';

const AuthService = {

  one: (id) => HTTP.get(`/user/${id}`).then((response) => response.data),

  oneByCountryRegister: (countryRegister) => HTTP.get(`/user/countryRegister/${countryRegister}`).then((response) => response.data),

  // TODO - REMOVE
  oneByCnpj: (cnpj) => HTTP.get(`/user/cnpj/${cnpj}`).then((response) => response.data),

  login: ({ credentials, type }) => HTTP.post(`/user/auth?loginType=${type}`, credentials)
    .then((response) => response.data.data),

  changePassword: ({ userId, password, newPassword }) => HTTP.put(`/user/${userId}/password`, { password, newPassword })
    .then((response) => response.data.data),

  recoverPassword: (phoneOrEmailOrDoc) => HTTP.post('/user/recoverPassword', { phoneOrEmailOrDoc })
    .then((response) => response.data),

  resetPassword: (code, phoneOrEmailOrDoc, password) => HTTP.put('/user/resetPassword', { code, phoneOrEmailOrDoc, password })
    .then((response) => response.data.data),

  renew: (renewToken) => HTTP.post('/user/auth/renew', { renewToken })
    .then((response) => response.data.data),

  verifyCNPJ: (p) => HTTP.post('/user/verifyCNPJ', p).then((response) => response.data),
};

export default AuthService;
