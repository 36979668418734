<template>
  <v-card class="elevation-1">
    <v-card-title>
      <span class="headline mb-0" v-if="status == 'not_registered'">
        Cadastrar Dados Bancários
      </span>
      <span class="headline mb-0" v-else-if="status !== 'not_registered'">
        Editar Dados Bancários
      </span>
      <span class="headline mb-0" v-else>Dados Bancários</span>
    </v-card-title>

    <ValidationObserver ref="bankObserver" v-slot="{ invalid }">
      <v-card-text>

        <div v-if="company">
          <!-- <v-alert dense type="warning" v-if="status == 'not_registered'">
            Conta bancária não cadastrada.
          </v-alert> -->

          <v-alert dense type="warning" v-if="status == 'error_on_verifying'">
            Conta bancária não verificada.
            {{
              (company && company.paymentAccountFeedback) ?
              `Erro: ${company.paymentAccountFeedback}` : ''
            }}
          </v-alert>

          <v-alert dense type="warning" v-else-if="status == 'verifying'">
            Conta bancária sendo verificada.
          </v-alert>

          <v-alert dense type="success" v-else-if="status == 'verified'">
            Conta bancária verificada.
          </v-alert>
        </div>

        <form autocomplete="off">
          <!-- Bank/AccountType -->
          <v-row>
            <!-- Bank -->
            <v-col
              xl="5" lg="5" md="5" sm="5"
              cols="12"
            >
              <mb-select
                v-model="bankAccount.bank" :items="banks" item-text="txt"
                item-value="bank" name="bkadrs_bnk_acc_bank" label="Banco"
                rules="required" :hideDetails="false" :returnObject="true"
              />
            </v-col>
            <!-- AccountType -->
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12"
            >
              <mb-select
                v-model="bankAccount.accountType" :items="accountTypes"
                item-text="txt" item-value="type" name="bkadrs_bnk_acc_type" label="Tipo"
                rules="required" :hideDetails="false"
              />
            </v-col>
          </v-row>
          <!-- Agency/AgencyDigit -->
          <v-row>
            <!-- Agency -->
            <v-col
              xl="5" lg="5" md="5" sm="5"
              cols="12"
            >
              <mb-input-text
                v-model="bankAccount.agency"
                name="bkadrs_bnk_acc_agency"
                label="Agência"
                :rules="getRules('agency')"
                :hint="(bankAccount.bank) ? bankAccount.bank.agHint : null"
                :persistentHint="true"
              />
            </v-col>
            <!-- AgencyDigit -->
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12"
            >
              <mb-input-text
                v-model="bankAccount.agencyDigit"
                name="bkadrs_bnk_acc_agency_digit"
                label="Dígito da Agência"
                :rules="getRules('agencyDigit')"
                :disabled="(bankAccount.bank) ? !bankAccount.bank.agDigit : false"
                :hint="(bankAccount.bank) ? bankAccount.bank.digAgHint : null"
                :persistentHint="true"
              />
            </v-col>
          </v-row>
          <!-- AccountNumber/AccountNumberDigit -->
          <v-row>
            <!-- AccountNumber -->
            <v-col
              xl="5" lg="5" md="5" sm="5"
              cols="12"
            >
              <mb-input-text
                v-model="bankAccount.accountNumber"
                name="bkadrs_bnk_acc_account_number"
                label="Número da Conta"
                :rules="getRules('account')"
                :hint="(bankAccount.bank) ? bankAccount.bank.accHint : null"
                :persistentHint="true"
              />
            </v-col>
            <!-- AccountNumberDigit -->
            <v-col
              xl="3" lg="3" md="3" sm="3"
              cols="12"
            >
              <mb-input-text
                v-model="bankAccount.accountNumberDigit"
                name="bkadrs_bnk_acc_account_number_digit"
                label="Dígito da Conta"
                :rules="getRules('accountDigit')"
                :disabled="(bankAccount.bank) ? !bankAccount.bank.accDigit : false"
                :hint="(bankAccount.bank) ? bankAccount.bank.digAccHint : null"
                :persistentHint="true"
              />
            </v-col>
          </v-row>
        </form>
      </v-card-text>

      <v-card-actions>
        <mb-text-button
          name="bkadrs_bnk_acc_save"
          label="Salvar"
          :disabled="invalid || status === 'verifying'"
          :loading="saveBtnLoading"
          @click="save"
        />
      </v-card-actions>
    </ValidationObserver>

  </v-card>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import bus from '@/plugins/eventBus';
import MbInputText from '@/components/general/inputs/MbInputText';
import MbSelect from '@/components/general/inputs/MbSelect';
import MbTextButton from '@/components/general/buttons/MbTextButton';

export default {
  components: {
    ValidationObserver,
    MbInputText,
    MbSelect,
    MbTextButton,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    company: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      banks: [
        {
          bank: 'agibank',
          txt: '121-Agibank',
          agDigit: false,
          accDigit: false,
          agHint: '9999',
          accHint: '9999999999',
          digAgHint: null,
          digAccHint: null,
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{10,10}$',
        },
        {
          bank: 'banese',
          txt: '047-Banco Banese',
          agDigit: false,
          accDigit: true,
          agHint: '999',
          accHint: '99999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{3,3}$',
          accRegex: '^[0-9]{8,8}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'btg',
          txt: '208-Banco BTG Pactual',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '99999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{5,5}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'c6',
          txt: '336-Banco C6',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '9999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{7,7}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'banco_da_amazonia',
          txt: '003-Banco da Amazonia',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{6,6}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'daycoval',
          txt: '707-Banco Daycoval',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{6,6}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'banco_do_brasil',
          txt: '001-Banco do Brasil',
          agDigit: true,
          accDigit: true,
          agHint: '9999',
          accHint: '99999999',
          digAgHint: '9',
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{8,8}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'banco_do_nordeste',
          txt: '004-Banco do Nordeste',
          agDigit: false,
          accDigit: true,
          agHint: '999',
          accHint: '999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{3,3}$',
          accRegex: '^[0-9]{6,6}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'omni',
          txt: '613-Banco Omni',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{6,6}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'original',
          txt: '212-Banco Original',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '9999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{7,7}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'topazio',
          txt: '082-Banco Topazio',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '99999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{5,5}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'banestes',
          txt: '021-Banestes',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '99999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{8,8}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'banpara',
          txt: '037-Banpará',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '999999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{9,9}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'banrisul',
          txt: '041-Banrisul',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '999999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{9,9}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'bnp',
          txt: '752-BNP Paribas Brasil',
          agDigit: false,
          accDigit: true,
          agHint: '999',
          accHint: '999999',
          digAgHint: null,
          digAccHint: '999',
          agRegex: '^[0-9]{3,3}$',
          accRegex: '^[0-9]{6,6}$',
          digAccRegex: '^[0-9]{3,3}$',
        },
        {
          bank: 'bradesco',
          txt: '273-Bradesco',
          agDigit: true,
          accDigit: true,
          agHint: '9999',
          accHint: '9999999',
          digAgHint: '9',
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{7,7}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'brb',
          txt: '070-BRB',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '999999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{9,9}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'brl_trust',
          txt: '173-BRL Trust DTVM',
          agDigit: false,
          accDigit: true,
          agHint: '999',
          accHint: '999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{3,3}$',
          accRegex: '^[0-9]{6,6}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'bs2',
          txt: '218-BS2',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{6,6}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'caixa_economica',
          txt: '104-Caixa Econômica',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: 'XXX99999999 ou XXXX999999999 (X: Operação)',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{11,13}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'citibank',
          txt: '745-Citibank',
          agDigit: false,
          accDigit: false,
          agHint: '9999',
          accHint: '99999999',
          digAgHint: null,
          digAccHint: null,
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{8,8}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'ccc_noroeste_br',
          txt: '097-Cooperativa Central de Credito Noroeste Brasileiro',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '9999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{7,7}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'cora',
          txt: '403-Cora',
          agDigit: null,
          accDigit: true,
          agHint: '9999',
          accHint: '9999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{7,7}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'cresol',
          txt: '133-Cresol',
          agDigit: true,
          accDigit: true,
          agHint: '9999',
          accHint: '99999',
          digAgHint: '9',
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{5,5}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'gerencianet',
          txt: '364-Gerencianet Pagamentos do Brasil',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '99999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{8,8}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'global_scm',
          txt: '384-Global SCM',
          agDigit: false,
          accDigit: false,
          agHint: '9999',
          accHint: '99999999999',
          digAgHint: null,
          digAccHint: null,
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{11,11}$',
        },
        {
          bank: 'inter',
          txt: '077-Inter',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '999999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{9,9}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'itau',
          txt: '341-Itaú',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '99999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{5,5}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'jp',
          txt: '376-JP Morgan',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '99999999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{11,11}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'juno',
          txt: '383-Juno',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '9999999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{10,10}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'mercado_pago',
          txt: '323-Mercado Pago',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '9999999999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{13,13}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'mercantil',
          txt: '389-Mercantil do Brasil',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '99999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{8,8}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'modal',
          txt: '746-Modal',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '999999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{9,9}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'money_plus',
          txt: '274-Money Plus',
          agDigit: false,
          accDigit: true,
          agHint: '9',
          accHint: '99999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{1,1}$',
          accRegex: '^[0-9]{8,8}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'neon',
          txt: '735-Neon',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '999999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{9,9}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'next',
          txt: '237-Next',
          agDigit: true,
          accDigit: true,
          agHint: '9999',
          accHint: '9999999',
          digAgHint: '9',
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{7,7}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'nubank',
          txt: '260-Nubank',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '9999999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{10,10}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'pagseguro',
          txt: '290-Pagseguro',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '99999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{8,8}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'pjbank',
          txt: '301-PJBank',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '9999999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{10,10}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'rendimento',
          txt: '633-Rendimento',
          agDigit: true,
          accDigit: false,
          agHint: '9999',
          accHint: '9999999999',
          digAgHint: '9',
          digAccHint: null,
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{10,10}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'safra',
          txt: '422-Safra',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '99999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{8,8}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'santander',
          txt: '033-Santander',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '99999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{8,8}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'sicoob',
          txt: '756-Sicoob (Bancoob)',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '999999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{9,9}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'sicredi',
          txt: '748-Sicredi',
          agDigit: false,
          accDigit: false,
          agHint: '9999',
          accHint: '999999D',
          digAgHint: null,
          digAccHint: null,
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{7,7}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'stone',
          txt: '197-Stone',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '9999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{7,7}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'unicred',
          txt: '136-Unicred',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '99999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{8,8}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'uniprime',
          txt: '099-Uniprime',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '99999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{5,5}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'uniprime_norte_pr',
          txt: '084-Uniprime Norte do Paraná',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{6,6}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'via_credi',
          txt: '085-Via Credi',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '99999999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{11,11}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
        {
          bank: 'votorantim',
          txt: '655-Votorantim',
          agDigit: false,
          accDigit: true,
          agHint: '9999',
          accHint: '999999999',
          digAgHint: null,
          digAccHint: '9',
          agRegex: '^[0-9]{4,4}$',
          accRegex: '^[0-9]{9,9}$',
          digAgRegex: '^[0-9]{1,1}$',
          digAccRegex: '^[0-9]{1,1}$',
        },
      ],
      accountTypes: [
        { txt: 'Conta Corrente', type: 'checking' },
        { txt: 'Conta Poupança', type: 'savings' },
      ],
      defaultBankAccount: {
        agency: null,
        agencyDigit: null,
        accountType: null,
        accountNumber: null,
        accountNumberDigit: null,
        bank: null,
      },
      bankAccount: null,
      saveBtnLoading: false,
      status: 'verifying',
    };
  },
  watch: {
    value(vl) {
      this.bankAccount = vl || JSON.parse(JSON.stringify(this.defaultBankAccount));
      // eslint-disable-next-line prefer-destructuring
      this.bankAccount.bank = this.banks.filter((b) => b.bank === this.bankAccount.bank)[0];
    },
    'bankAccount.bank': {
      handler() {
        if (this.bankAccount.id == null) {
          this.bankAccount.agency = null;
          this.bankAccount.agencyDigit = null;
          this.bankAccount.accountNumber = null;
          this.bankAccount.accountNumberDigit = null;
        }
      },
    },
    company: {
      handler() {
        this.status = (this.company != null) ? this.company.paymentAccountStatus : 'verifying';
      },
      deep: true,
    },
  },
  created() {
    this.status = (this.company != null) ? this.company.paymentAccountStatus : 'verifying';
    this.bankAccount = this.value || JSON.parse(JSON.stringify(this.defaultBankAccount));
  },
  methods: {
    save() {
      if (this.bankAccount.id) {
        this.update();
      } else {
        this.create();
      }
    },
    async create() {
      const isValid = await this.$refs.bankObserver.validate();

      if (isValid) {
        const account = JSON.parse(JSON.stringify(this.bankAccount));
        account.bank = this.bankAccount.bank.bank;

        this.saveBtnLoading = true;
        this.$store
          .dispatch('bankAccount/create', {
            companyId: this.company.id,
            bankAccount: account,
          })
          .then((data) => {
            this.bankAccount = data;
            bus.$emit('message', {
              message: 'Conta bancária salva com sucesso!',
              type: 'success',
            });
            this.$emit('updated');
            this.$emit('input', this.bankAccount);
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
    async update() {
      const isValid = await this.$refs.bankObserver.validate();

      if (isValid) {
        const account = JSON.parse(JSON.stringify(this.bankAccount));
        account.bank = this.bankAccount.bank.bank;

        this.saveBtnLoading = true;
        this.$store
          .dispatch('bankAccount/update', account)
          .then(() => {
            bus.$emit('message', {
              message: 'Conta Bancária atualizada com sucesso!',
              type: 'success',
            });
            this.$emit('updated');
            this.$emit('input', this.bankAccount);
          })
          .catch((err) => {
            bus.$emit('message', {
              message: err.response.data.message,
              type: 'error',
            });
          })
          .finally(async () => {
            this.saveBtnLoading = false;
          });
      }
    },
    getRules(type) {
      if (this.bankAccount && this.bankAccount.bank && type === 'agency') {
        return { required: true, regex: this.bankAccount.bank.agRegex };
      }
      if (this.bankAccount && this.bankAccount.bank && type === 'agencyDigit' && this.bankAccount.bank.agDigit) {
        return { required: true, regex: this.bankAccount.bank.digAccRegex };
      }
      if (this.bankAccount && this.bankAccount.bank && type === 'account') {
        return { required: true, regex: this.bankAccount.bank.accRegex };
      }
      if (this.bankAccount && this.bankAccount.bank && type === 'accountDigit' && this.bankAccount.bank.accDigit) {
        return { required: true, regex: this.bankAccount.bank.digAccRegex };
      }
      return null;
    },
  },
};
</script>
